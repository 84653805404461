module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/property-filter-value.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (filter, filterId, isEditable) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/property-filter-value.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/property-filter-value.jade" ));
buf.push("\n<textarea" + (jade.attr("data-filter-id", filterId, true, true)) + " placeholder=\"Enter values separated by commas or line breaks\"" + (jade.attr("data-type", filter.type, true, true)) + " rows=\"10\"" + (jade.attr("disabled", !isEditable, true, true)) + " class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = filter.valuesList) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</textarea>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/property-filter-value.jade" ));
buf.push("\n<div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/property-filter-value.jade" ));
buf.push("\n  <div class=\"col-md-6\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/property-filter-value.jade" ));
buf.push("\n    <div class=\"checkbox\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/property-filter-value.jade" ));
buf.push("\n      <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/property-filter-value.jade" ));
buf.push("\n        <input type=\"checkbox\"" + (jade.attr("checked", filter.isSensitive, true, true)) + (jade.attr("disabled", !isEditable, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/property-filter-value.jade" ));
buf.push("Sensitive information");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/property-filter-value.jade" ));
buf.push("\n    <div class=\"small help-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("You may want to use this for personal information, such as email addresses or phone numbers.");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"filter" in locals_for_with?locals_for_with.filter:typeof filter!=="undefined"?filter:undefined,"filterId" in locals_for_with?locals_for_with.filterId:typeof filterId!=="undefined"?filterId:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "textarea.form-control(data-filter-id=filterId placeholder='Enter values separated by commas or line breaks' data-type=filter.type rows=10 disabled=!isEditable) #{filter.valuesList}\n.row\n    .col-md-6\n        .checkbox\n            label\n                input(type=\"checkbox\" checked=filter.isSensitive disabled=!isEditable)\n                | Sensitive information\n        .small.help-block You may want to use this for personal information, such as email addresses or phone numbers.\n");
}
};