module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/ctas/demo-cta.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (btnLink, btnText, text) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n<div class=\"panel panel-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n  <div class=\"panel-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("\n    <div class=\"profile-info\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("John Sherer");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("<img src=\"https://www.gravatar.com/avatar/769907f940bd746153fbfbe99b0d34c2?s=96\" width=\"64\" height=\"64\" class=\"img-circle\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("Account Manager");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n    <div class=\"message-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("\n      <p>" + (jade.escape(null == (jade_interp = text) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n    <div class=\"message-actions\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("<a" + (jade.attr("href", btnLink, true, true)) + " target=\"_blank\" class=\"btn btn-primary\">" + (jade.escape(null == (jade_interp = btnText) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("&nbsp;or&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/ctas/demo-cta.jade" ));
buf.push("<a class=\"dismiss\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("maybe later");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n    <div class=\"powered-by text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("<small>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("Powered by Appcues");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"btnLink" in locals_for_with?locals_for_with.btnLink:typeof btnLink!=="undefined"?btnLink:undefined,"btnText" in locals_for_with?locals_for_with.btnText:typeof btnText!=="undefined"?btnText:undefined,"text" in locals_for_with?locals_for_with.text:typeof text!=="undefined"?text:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends floating-cta\n\nblock profile\n    .profile-info\n        span John Sherer\n        img.img-circle(src='https://www.gravatar.com/avatar/769907f940bd746153fbfbe99b0d34c2?s=96', width='64', height='64')\n        span Account Manager\n\nblock body\n    p= text\n\nblock actions\n    p\n        a.btn.btn-primary(href=btnLink, target='_blank')= btnText\n        | &nbsp;or&nbsp;\n        a.dismiss maybe later\n");
}
};