module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/form-fields/field-type-dropdown.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/form-fields/field-type-dropdown.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/form-fields/field-type-dropdown.jade" ));
buf.push("\n<div class=\"select-field-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/form-fields/field-type-dropdown.jade" ));
buf.push("\n  <select class=\"field-type-dropdown\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</select>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields/field-type-dropdown.jade" ));
buf.push("\n  <div class=\"remove\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/form-fields/field-type-dropdown.jade" ));
buf.push("<a data-action=\"removeField\" title=\"Remove\" class=\"btn btn-link control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/form-fields/field-type-dropdown.jade" ));
buf.push("<i class=\"fa fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".select-field-type\n    select.field-type-dropdown\n    .remove\n        a.btn.btn-link.control-link(data-action='removeField' title='Remove')\n            i.fa.fa-trash");
}
};