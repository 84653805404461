module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/integrations.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (integrationUrl, integrations, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/one-column.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/mixins/integration.jade" ));
jade_mixins["integration"] = jade_interp = function(data){
var block = (this && this.block), attributes = (this && this.attributes) || {};
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/mixins/integration.jade" ));
buf.push("\n");
buf.push.apply(buf, jade_indent);
buf.push("<div" + (jade.attr("data-id", data.integrationId, true, true)) + (jade.attr("data-provider", data.id, true, true)) + (jade.cls(['integration',{
            enabled: data.isEnabled,
            pending: data.status == 'pending',
            configuring: data.settings !== undefined && data.status == 'configuring' }], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/mixins/integration.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<div class=\"text-center integration-logo\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/mixins/integration.jade" ));
buf.push("<img" + (jade.attr("src", data.imageUrl, true, true)) + (jade.attr("title", data.name, true, true)) + " width=\"100%\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/mixins/integration.jade" ));
if ( data.isEnabled && data.status != 'pending')
{
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/mixins/integration.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<p class=\"text-success text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/mixins/integration.jade" ));
buf.push("<i class=\"fa fa-check\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/mixins/integration.jade" ));
buf.push("&nbsp;" + (jade.escape((jade_interp = data.name) == null ? '' : jade_interp)) + " integration active");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/mixins/integration.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<div class=\"integration-action\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/mixins/integration.jade" ));
if ( data.status == 'pending')
{
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/mixins/integration.jade" ));
buf.push("\n    ");
buf.push.apply(buf, jade_indent);
buf.push("<div class=\"btn btn-block btn-success active\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/mixins/integration.jade" ));
buf.push("<i class=\"fa fa-spinner fa-pulse\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/mixins/integration.jade" ));
buf.push("&nbsp;Setting up " + (jade.escape((jade_interp = data.name) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
else if ( data.isEnabled)
{
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/mixins/integration.jade" ));
buf.push("<a class=\"btn btn-block btn-danger remove-integration\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, jade_debug[0].filename ));
buf.push("Deactivate");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
else if ( data.settings && data.status == 'configuring')
{
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/mixins/integration.jade" ));
buf.push("\n    ");
buf.push.apply(buf, jade_indent);
buf.push("<form" + (jade.attr("action", '' + (integrationUrl) + '/auth/' + (data.id) + '', true, true)) + " class=\"integration-form\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/mixins/integration.jade" ));
// iterate data.settings
;(function(){
  var $$obj = data.settings;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var field = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/mixins/integration.jade" ));
buf.push("\n      ");
buf.push.apply(buf, jade_indent);
buf.push("<div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/mixins/integration.jade" ));
buf.push("\n        ");
buf.push.apply(buf, jade_indent);
buf.push("<label>" + (jade.escape(null == (jade_interp = field.label) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/mixins/integration.jade" ));
buf.push("\n        ");
buf.push.apply(buf, jade_indent);
buf.push("<input" + (jade.attr("name", field.name, true, true)) + (jade.attr("type", field.type, true, true)) + (jade.attr("value", data[field.name], true, true)) + (jade.attr("placeholder", field.placeholder, true, true)) + " required class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      ");
buf.push.apply(buf, jade_indent);
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var field = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/mixins/integration.jade" ));
buf.push("\n      ");
buf.push.apply(buf, jade_indent);
buf.push("<div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/mixins/integration.jade" ));
buf.push("\n        ");
buf.push.apply(buf, jade_indent);
buf.push("<label>" + (jade.escape(null == (jade_interp = field.label) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/mixins/integration.jade" ));
buf.push("\n        ");
buf.push.apply(buf, jade_indent);
buf.push("<input" + (jade.attr("name", field.name, true, true)) + (jade.attr("type", field.type, true, true)) + (jade.attr("value", data[field.name], true, true)) + (jade.attr("placeholder", field.placeholder, true, true)) + " required class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      ");
buf.push.apply(buf, jade_indent);
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/mixins/integration.jade" ));
buf.push("\n      ");
buf.push.apply(buf, jade_indent);
buf.push("<button type=\"submit\" class=\"btn btn-block btn-success\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push("Finish activation");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    ");
buf.push.apply(buf, jade_indent);
buf.push("</form>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/mixins/integration.jade" ));
buf.push("<a class=\"cancel-configuration\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, jade_debug[0].filename ));
buf.push("Cancel");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
else if ( !data.placeholder)
{
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/mixins/integration.jade" ));
buf.push("<a class=\"btn btn-block btn-success activate-integration\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 34, jade_debug[0].filename ));
buf.push("Activate " + (jade.escape((jade_interp = data.name) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n");
buf.push.apply(buf, jade_indent);
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
};
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/one-column.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/one-column.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/one-column.jade" ));
buf.push("\n    <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/one-column.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/integrations.jade" ));
buf.push("\n      <h2>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Integrations");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/one-column.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/one-column.jade" ));
buf.push("\n    <section class=\"main-content col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/one-column.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/integrations.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Track how your users interact with your flows using the tools you already have installed.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/integrations.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/integrations.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, jade_debug[0].filename ));
buf.push("How does this work?&nbsp;");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/integrations.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("Just activate, and we'll use the integration already installed on your site to send the data automatically.&nbsp;");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/integrations.jade" ));
buf.push("<a href=\"http://docs.appcues.com/article/137-integrations-overview\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Learn more.");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/integrations.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/integrations.jade" ));
buf.push("\n      <div class=\"integration-list\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/integrations.jade" ));
// iterate integrations
;(function(){
  var $$obj = integrations;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var integration = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/integrations.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/integrations.jade" ));
buf.push("\n        <div" + (jade.cls(['integration-panel','panel','panel-default',{ enabled: integration.isEnabled && integration.status !== 'pending' }], [null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/integrations.jade" ));
buf.push("\n          <div class=\"integration-panel-body panel-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/integrations.jade" ));
jade_indent.push('            ');
jade_mixins["integration"](integration);
jade_indent.pop();
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var integration = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/integrations.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/integrations.jade" ));
buf.push("\n        <div" + (jade.cls(['integration-panel','panel','panel-default',{ enabled: integration.isEnabled && integration.status !== 'pending' }], [null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/integrations.jade" ));
buf.push("\n          <div class=\"integration-panel-body panel-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/integrations.jade" ));
jade_indent.push('            ');
jade_mixins["integration"](integration);
jade_indent.pop();
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/integrations.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/integrations.jade" ));
buf.push("\n      <div class=\"alert alert-info text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/integrations.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, jade_debug[0].filename ));
buf.push("Don't see the tool you're looking for? Shoot us an email at ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/integrations.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/integrations.jade" ));
buf.push("<a href=\"mailto:support@appcues.com\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("support@appcues.com");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, jade_debug[0].filename ));
buf.push(" to suggest it.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </section>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"integrationUrl" in locals_for_with?locals_for_with.integrationUrl:typeof integrationUrl!=="undefined"?integrationUrl:undefined,"integrations" in locals_for_with?locals_for_with.integrations:typeof integrations!=="undefined"?integrations:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends one-column\ninclude mixins/integration\n\nblock title\n    h2 Integrations\n\nblock content\n\n    p Track how your users interact with your flows using the tools you already have installed.\n\n    p\n        strong How does this work?&nbsp;\n        span Just activate, and we'll use the integration already installed on your site to send the data automatically.&nbsp;\n        a(href=\"http://docs.appcues.com/article/137-integrations-overview\", target=\"_blank\") Learn more.\n\n    br\n\n    .integration-list\n        for integration in integrations\n            .integration-panel.panel.panel-default(class={ enabled: integration.isEnabled && integration.status !== 'pending' })\n                .integration-panel-body.panel-body\n                    +integration(integration)\n\n    br\n\n    .alert.alert-info.text-center\n        p Don't see the tool you're looking for? Shoot us an email at #[a(href=\"mailto:support@appcues.com\") support@appcues.com] to suggest it.\n\n");
}
};