module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/navigation.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (standalone) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/navigation.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/navigation.jade" ));
if ( standalone)
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/navigation.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/navigation.jade" ));
buf.push("\n<div id=\"build-navigation\" class=\"secondary-row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/navigation.jade" ));
buf.push("\n  <div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/navigation.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/navigation.jade" ));
buf.push("\n      <div class=\"col-sm-4\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/navigation.jade" ));
if ( standalone)
{
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/navigation.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/navigation.jade" ));
buf.push("\n        <h4>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("Target & Publish Settings");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"standalone" in locals_for_with?locals_for_with.standalone:typeof standalone!=="undefined"?standalone:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "if standalone\n    #build-navigation.secondary-row\n        .container\n            .row\n                .col-sm-4\n                    if standalone\n                        h4 Target & Publish Settings\n");
}
};