module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/install-instructions.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-instructions.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-instructions.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/install-instructions.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/install-instructions.jade" ));
buf.push("\n    <section class=\"col-md-8 col-md-offset-2\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/install-instructions.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/install-instructions.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/install-instructions.jade" ));
buf.push("\n      <div class=\"panel panel-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/install-instructions.jade" ));
buf.push("\n        <div class=\"panel-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/install-instructions.jade" ));
buf.push("\n          <div class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/install-instructions.jade" ));
buf.push("\n            <h1>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Installation Instructions");
jade_debug.shift();
jade_debug.shift();
buf.push("</h1>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/install-instructions.jade" ));
buf.push("\n            <p style=\"font-size: 120%;\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("Installing Appcues in your app is quick and commitment-free.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/install-instructions.jade" ));
buf.push("\n          <div>");
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("<script charset=\"ISO-8859-1\" src=\"//fast.wistia.com/assets/external/E-v1.js\" async></script><div class=\"wistia_responsive_padding\" style=\"padding:56.25% 0 0 0;position:relative;\"><div class=\"wistia_responsive_wrapper\" style=\"height:100%;left:0;position:absolute;top:0;width:100%;\"><div class=\"wistia_embed wistia_async_a1qx1q9i48 videoFoam=true\" style=\"height:100%;width:100%\">&nbsp;</div></div></div>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/install-instructions.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/install-instructions.jade" ));
buf.push("\n          <div class=\"code-snippet\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/install-instructions.jade" ));
buf.push("\n          <p style=\"font-size: 120%;\" class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("We are here to help! ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-instructions.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-instructions.jade" ));
buf.push("<a href=\"mailto:support@appcues.com?subject=I'd like help with installation\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Send us an email");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push(" with any questions.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/install-instructions.jade" ));
buf.push("\n      <p class=\"text-center text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("Let's make great user experiences together.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </section>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".container\n    .row\n        section.col-md-8.col-md-offset-2\n            br\n            br\n            .panel.panel-default\n                .panel-body\n                    .text-center\n                        h1 Installation Instructions\n\n                        p(style='font-size: 120%;') Installing Appcues in your app is quick and commitment-free.\n\n                    div.\n                        <script charset=\"ISO-8859-1\" src=\"//fast.wistia.com/assets/external/E-v1.js\" async></script><div class=\"wistia_responsive_padding\" style=\"padding:56.25% 0 0 0;position:relative;\"><div class=\"wistia_responsive_wrapper\" style=\"height:100%;left:0;position:absolute;top:0;width:100%;\"><div class=\"wistia_embed wistia_async_a1qx1q9i48 videoFoam=true\" style=\"height:100%;width:100%\">&nbsp;</div></div></div>\n                    br\n                    .code-snippet\n\n                    p.text-center(style='font-size: 120%;') We are here to help! #[a(href='mailto:support@appcues.com?subject=I\\'d like help with installation') Send us an email] with any questions.\n\n            p.text-center.text-muted Let's make great user experiences together.\n");
}
};