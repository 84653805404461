module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/content-list/group.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (cannotPublish, group, isCollapsed, isEmpty, isFiltered, total, totalHidden) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/content-list/group.jade" ));
if (!( isEmpty && isFiltered))
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/content-list/group.jade" ));
if ( group)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/content-list/group.jade" ));
var chevronDir = isCollapsed ? 'right' : 'down'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/content-list/group.jade" ));
buf.push("\n<div" + (jade.cls(['group-header','toggle-collapse',(isCollapsed ? 'text-muted' : '')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/content-list/group.jade" ));
buf.push("\n  <h4>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/content-list/group.jade" ));
buf.push("<span" + (jade.cls(['fa','fa-fw','fa-chevron-' + (chevronDir) + ''], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/content-list/group.jade" ));
buf.push(jade.escape(null == (jade_interp = group.name) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/content-list/group.jade" ));
buf.push("\n  <!-- DISABLE BATCH ACTION MENU FOR NOW - Tristan 12/16-->");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/content-list/group.jade" ));
if ( !isEmpty)
{
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/content-list/group.jade" ));
buf.push("<small class=\"pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/content-list/group.jade" ));
buf.push("<a data-toggle=\"dropdown\" class=\"fa fa-ellipsis-h batch-actions\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/content-list/group.jade" ));
buf.push("\n    <ul class=\"dropdown-menu\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/content-list/group.jade" ));
buf.push("\n      <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Group actions");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/content-list/group.jade" ));
buf.push("\n      <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/content-list/group.jade" ));
if ( cannotPublish)
{
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/content-list/group.jade" ));
buf.push("<a>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("<span class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("Publish all");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/content-list/group.jade" ));
buf.push("<a data-action=\"batch-publish\" data-property=\"group\"" + (jade.attr("data-value", group.name, true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("Publish all");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/content-list/group.jade" ));
buf.push("\n      <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/content-list/group.jade" ));
buf.push("<a data-action=\"batch-unpublish\" data-property=\"group\"" + (jade.attr("data-value", group.name, true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, jade_debug[0].filename ));
buf.push("Unpublish all");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </ul>");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/content-list/group.jade" ));
if ( group.totals)
{
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/content-list/group.jade" ));
buf.push("<small class=\"pull-right hidden-sm\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/content-list/group.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/content-list/group.jade" ));
if ( isFiltered && (totalHidden || group.totals.active == 0))
{
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/content-list/group.jade" ));
buf.push("" + (jade.escape((jade_interp = total) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = s.pluralize(total, 'flow')) == null ? '' : jade_interp)) + " (" + (jade.escape((jade_interp = totalHidden) == null ? '' : jade_interp)) + " hidden by filters)");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/content-list/group.jade" ));
buf.push("" + (jade.escape((jade_interp = group.totals.active) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = s.pluralize(group.totals.active, 'flow')) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/content-list/group.jade" ));
if ( group.totals.archived)
{
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/content-list/group.jade" ));
buf.push("&nbsp;(" + (jade.escape((jade_interp = group.totals.archived) == null ? '' : jade_interp)) + " archived)");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/content-list/group.jade" ));
if (!( isCollapsed))
{
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/content-list/group.jade" ));
if ( isEmpty)
{
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/content-list/group.jade" ));
buf.push("\n<p class=\"text-muted text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 36, jade_debug[0].filename ));
buf.push("Nothing here. Drag and drop content to reorganize.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 37, "app/views/templates/content-list/group.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/content-list/group.jade" ));
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/content-list/group.jade" ));
buf.push("\n<table class=\"content-table table-layout\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/content-list/group.jade" ));
buf.push("\n  <tbody>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</tbody>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</table>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"cannotPublish" in locals_for_with?locals_for_with.cannotPublish:typeof cannotPublish!=="undefined"?cannotPublish:undefined,"group" in locals_for_with?locals_for_with.group:typeof group!=="undefined"?group:undefined,"isCollapsed" in locals_for_with?locals_for_with.isCollapsed:typeof isCollapsed!=="undefined"?isCollapsed:undefined,"isEmpty" in locals_for_with?locals_for_with.isEmpty:typeof isEmpty!=="undefined"?isEmpty:undefined,"isFiltered" in locals_for_with?locals_for_with.isFiltered:typeof isFiltered!=="undefined"?isFiltered:undefined,"total" in locals_for_with?locals_for_with.total:typeof total!=="undefined"?total:undefined,"totalHidden" in locals_for_with?locals_for_with.totalHidden:typeof totalHidden!=="undefined"?totalHidden:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "unless isEmpty && isFiltered\n    if group\n        - var chevronDir = isCollapsed ? 'right' : 'down'\n        div.group-header.toggle-collapse(class=(isCollapsed ? 'text-muted' : ''))\n            h4\n                span.fa.fa-fw(class='fa-chevron-#{chevronDir}')\n                = group.name\n\n            // DISABLE BATCH ACTION MENU FOR NOW - Tristan 12/16\n            if !isEmpty\n                small.pull-right\n                    a.fa.fa-ellipsis-h.batch-actions(data-toggle='dropdown')\n                    ul.dropdown-menu\n                        li.dropdown-header Group actions\n\n                        li\n                            if cannotPublish\n                                a: span.text-muted Publish all\n                            else\n                                a(data-action='batch-publish' data-property='group' data-value=group.name) Publish all\n                        li\n                            a(data-action='batch-unpublish' data-property='group' data-value=group.name) Unpublish all\n\n            if group.totals\n                small.pull-right.hidden-sm\n                    span\n                        if isFiltered && (totalHidden || group.totals.active == 0)\n                            | #{total} #{s.pluralize(total, 'flow')} (#{totalHidden} hidden by filters)\n                        else\n                            | #{group.totals.active} #{s.pluralize(group.totals.active, 'flow')}\n                            if group.totals.archived\n                                | &nbsp;(#{group.totals.archived} archived)\n\n    unless isCollapsed\n        if isEmpty\n            p.text-muted.text-center Nothing here. Drag and drop content to reorganize.\n            br\n        else\n            table.content-table.table-layout\n                tbody\n\n");
}
};