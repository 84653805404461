module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/analytics/flow.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/analytics/flow.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/analytics/flow.jade" ));
buf.push("\n<div class=\"secondary-row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/analytics/flow.jade" ));
buf.push("<a" + (jade.attr("href", '/reports/' + (model.id) + '/csv', true, true)) + " class=\"btn btn-danger pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, jade_debug[0].filename ));
buf.push("Export a CSV");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/analytics/flow.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/analytics/flow.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/analytics/flow.jade" ));
buf.push("\n    <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/analytics/flow.jade" ));
buf.push("\n      <div class=\"main-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".secondary-row\n    a.btn.btn-danger.pull-right(href='/reports/#{model.id}/csv') Export a CSV\n\n.container\n    .row\n        .col-md-12\n            .main-content\n");
}
};