module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (collection, isEditable, undefined, useEager) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
if (!( useEager))
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("\n<div class=\"build-bar-setting pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<a data-action=\"initExtensionBuilder\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, jade_debug[0].filename ));
buf.push("Build in a new window");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("\n<ul class=\"thumbnails-list thumbnails-list-sequence list-group list-inline\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
if ( collection)
{
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
var i = 0
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
// iterate collection
;(function(){
  var $$obj = collection;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var item = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
if ( item.id)
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
i += 1
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("\n  <li" + (jade.attr("data-id", item.id, true, true)) + " data-action=\"selectItem\"" + (jade.cls(['thumbnail-item','list-group-item','draggable-list-group-item',(item.expanded ? ' selected ' : '') + (isEditable ? ' editable ' : '') + (item.nodeMissing ? ' nodeMissing ' : '')], [null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<span" + (jade.attr("data-hint", (item.nodeMissing ? 'Could not find selected element' : ''), true, true)) + (jade.cls(['thumb',(item.nodeMissing ? 'hint--top' : '')], [null,true])) + ">" + (jade.escape(null == (jade_interp = i) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("\n    <div class=\"thumbnail-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<a data-action=\"deleteItem\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var item = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
if ( item.id)
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
i += 1
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("\n  <li" + (jade.attr("data-id", item.id, true, true)) + " data-action=\"selectItem\"" + (jade.cls(['thumbnail-item','list-group-item','draggable-list-group-item',(item.expanded ? ' selected ' : '') + (isEditable ? ' editable ' : '') + (item.nodeMissing ? ' nodeMissing ' : '')], [null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<span" + (jade.attr("data-hint", (item.nodeMissing ? 'Could not find selected element' : ''), true, true)) + (jade.cls(['thumb',(item.nodeMissing ? 'hint--top' : '')], [null,true])) + ">" + (jade.escape(null == (jade_interp = i) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("\n    <div class=\"thumbnail-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<a data-action=\"deleteItem\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("\n  <li class=\"thumbnail-item list-group-item placeCoachmark\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/design/coachmark-group/coachmarks-summary.jade" ));
buf.push("<span data-action=\"placeCoachmark\" class=\"thumb\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-plus\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</ul>");
jade_debug.shift();
jade_debug.shift();}.call(this,"collection" in locals_for_with?locals_for_with.collection:typeof collection!=="undefined"?collection:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined,"useEager" in locals_for_with?locals_for_with.useEager:typeof useEager!=="undefined"?useEager:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "unless useEager\n    .build-bar-setting.pull-right\n        a(data-action='initExtensionBuilder') Build in a new window\n\nul.thumbnails-list.thumbnails-list-sequence.list-group.list-inline\n    if collection\n        - var i = 0\n        each item in collection\n            if item.id\n                - i += 1\n                li.thumbnail-item.list-group-item.draggable-list-group-item(class=(item.expanded ? ' selected ' : '') + (isEditable ? ' editable ' : '') + (item.nodeMissing ? ' nodeMissing ' : '') data-id=item.id data-action='selectItem' )\n                    span.thumb(class=(item.nodeMissing ? 'hint--top' : '') data-hint=(item.nodeMissing ? 'Could not find selected element' : ''))= i\n\n                    .thumbnail-controls\n                        a(data-action='deleteItem')\n                            i.fa.fa-fw.fa-trash\n\n\n    li.thumbnail-item.list-group-item.placeCoachmark\n        span.thumb(data-action='placeCoachmark'): i.fa.fa-plus\n\n\n");
}
};