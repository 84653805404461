module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/modal-content-list.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (contentList, title, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/modal-content-list.jade" ));
jade_mixins["contentRow"] = jade_interp = function(content){
var block = (this && this.block), attributes = (this && this.attributes) || {};
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/modal-content-list.jade" ));
if ( content.patternType === "shorty")
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/modal-content-list.jade" ));
var imgUrl = "/images/content-list/slide-out.png"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/modal-content-list.jade" ));
var designUrl = "/flows/" + content.id + "/analytics"
jade_debug.shift();
jade_debug.shift();
}
else if ( content.patternType == "modal")
{
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/modal-content-list.jade" ));
var imgUrl = "/images/content-list/flow-single.png"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/modal-content-list.jade" ));
var designUrl = "/flows/" + content.id + "/analytics"
jade_debug.shift();
jade_debug.shift();
}
else if ( content.sequential)
{
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/modal-content-list.jade" ));
var imgUrl = "/images/content-list/tooltips-single.png"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/modal-content-list.jade" ));
var designUrl = "/tooltips/" + content.id + "/analytics"
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/modal-content-list.jade" ));
var imgUrl = "/images/content-list/hotspots-single.png"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/modal-content-list.jade" ));
var designUrl = "/hotspots/" + content.id + "/analytics"
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/modal-content-list.jade" ));
buf.push("\n");
buf.push.apply(buf, jade_indent);
buf.push("<tr>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/modal-content-list.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<td style=\"width:50px\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/modal-content-list.jade" ));
buf.push("<img" + (jade.attr("src", imgUrl, true, true)) + " width=\"32\" height=\"32\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/modal-content-list.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<td style=\"line-height:32px\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/modal-content-list.jade" ));
buf.push("" + (jade.escape((jade_interp = content.name) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/modal-content-list.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<td style=\"line-height:32px\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/modal-content-list.jade" ));
if ( content.published)
{
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/modal-content-list.jade" ));
buf.push("Live");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/modal-content-list.jade" ));
buf.push("Draft");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("</td>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/modal-content-list.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<td style=\"line-height:32px\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/modal-content-list.jade" ));
buf.push("<a" + (jade.attr("href", designUrl, true, true)) + " target=\"_blank\" class=\"pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, jade_debug[0].filename ));
buf.push("View");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n");
buf.push.apply(buf, jade_indent);
buf.push("</tr>");
jade_debug.shift();
jade_debug.shift();
};
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/modal-content-list.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/modal-content-list.jade" ));
buf.push("\n  <div class=\"modal-content content-list-modal\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/modal-content-list.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/modal-content-list.jade" ));
buf.push("\n      <button type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\" class=\"close\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/modal-content-list.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 34, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/modal-content-list.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 37, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/modal-content-list.jade" ));
buf.push("\n      <table class=\"table\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/modal-content-list.jade" ));
buf.push("\n        <tbody>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/modal-content-list.jade" ));
// iterate contentList
;(function(){
  var $$obj = contentList;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var flow = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/modal-content-list.jade" ));
jade_indent.push('          ');
jade_mixins["contentRow"](flow);
jade_indent.pop();
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var flow = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/modal-content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/modal-content-list.jade" ));
jade_indent.push('          ');
jade_mixins["contentRow"](flow);
jade_indent.pop();
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n        </tbody>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </table>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"contentList" in locals_for_with?locals_for_with.contentList:typeof contentList!=="undefined"?contentList:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "mixin contentRow(content)\n    if content.patternType === \"shorty\"\n        - var imgUrl = \"/images/content-list/slide-out.png\"\n        - var designUrl = \"/flows/\" + content.id + \"/analytics\"\n    else if content.patternType == \"modal\"\n        - var imgUrl = \"/images/content-list/flow-single.png\"\n        - var designUrl = \"/flows/\" + content.id + \"/analytics\"\n    else if content.sequential\n        - var imgUrl = \"/images/content-list/tooltips-single.png\"\n        - var designUrl = \"/tooltips/\" + content.id + \"/analytics\"\n    else\n        - var imgUrl = \"/images/content-list/hotspots-single.png\"\n        - var designUrl = \"/hotspots/\" + content.id + \"/analytics\"\n\n    tr\n        td(style=\"width:50px\")\n            img(src=imgUrl, width=\"32\", height=\"32\")\n        td(style=\"line-height:32px\")\n            | #{content.name}\n        td(style=\"line-height:32px\")\n            if content.published\n                | Live\n            else\n                | Draft\n        td(style=\"line-height:32px\")\n            a.pull-right(href=designUrl target=\"_blank\") View\n\n\n.modal-dialog\n    .modal-content.content-list-modal\n        .modal-header\n            button.close(type='button', data-dismiss='modal', aria-hidden='true') &times;\n            block header\n                h4.modal-title #{title}\n\n        .modal-body\n            block body\n            table.table\n                tbody\n                    each flow in contentList\n                        +contentRow(flow)\n");
}
};