module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/prompt-install.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (accountId, niceContentName, singular) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/prompt-install.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/prompt-install.jade" ));
var hasHave = singular ? 'has' : 'have'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/prompt-install.jade" ));
buf.push("<img src=\"/images/close.gif\" width=\"300\" height=\"168\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/prompt-install.jade" ));
buf.push("\n<h4>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("Almost there...");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/prompt-install.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, jade_debug[0].filename ));
buf.push("This " + (jade.escape((jade_interp = niceContentName) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = hasHave) == null ? '' : jade_interp)) + " been staged for publishing until you've installed the Appcues SDK. Once the SDK is installed, your users can instantly see your published content.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/prompt-install.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/prompt-install.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/prompt-install.jade" ));
buf.push("<a" + (jade.attr("href", "/install/a/" + (accountId) + "", true, true)) + " target=\"_blank\" class=\"btn btn-primary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Install the Appcues SDK");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/prompt-install.jade" ));
buf.push("\n  <div class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("(takes less than 15 minutes)");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</p>");
jade_debug.shift();
jade_debug.shift();}.call(this,"accountId" in locals_for_with?locals_for_with.accountId:typeof accountId!=="undefined"?accountId:undefined,"niceContentName" in locals_for_with?locals_for_with.niceContentName:typeof niceContentName!=="undefined"?niceContentName:undefined,"singular" in locals_for_with?locals_for_with.singular:typeof singular!=="undefined"?singular:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "- var hasHave = singular ? 'has' : 'have'\n\nimg(src=\"/images/close.gif\", width=\"300\", height=\"168\")\nh4 Almost there...\n\np This #{niceContentName} #{hasHave} been staged for publishing until you've installed the Appcues SDK. Once the SDK is installed, your users can instantly see your published content.\nbr\np\n    a.btn.btn-primary(href=\"/install/a/#{accountId}\" target=\"_blank\") Install the Appcues SDK\n    .text-muted (takes less than 15 minutes)\n");
}
};