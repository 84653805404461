module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/segments.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (loaded) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/segments.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/segments.jade" ));
buf.push("\n<div id=\"apc-react-root\" class=\"main-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/segments.jade" ));
if (!( loaded))
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/segments.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/segments.jade" ));
buf.push("\n  <div class=\"spinner\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"loaded" in locals_for_with?locals_for_with.loaded:typeof loaded!=="undefined"?loaded:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".main-content#apc-react-root\n    unless loaded\n        .spinner\n");
}
};