module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/teasers/experiments.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (feedback, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n<div class=\"main-row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n  <div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n    <div class=\"main-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n      <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n        <div class=\"col-sm-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n          <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n            <div class=\"col-sm-6\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n              <div class=\"panel panel-primary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"panel-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/teasers/experiments.jade" ));
buf.push("<img src=\"/images/teasers/flow-analytics.png\" width=\"100%\">");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n              </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n            <div class=\"col-sm-6\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/teasers/experiments.jade" ));
buf.push("\n              <h3>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Appcues for Experiments");
jade_debug.shift();
jade_debug.shift();
buf.push("</h3>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/teasers/experiments.jade" ));
buf.push("\n              <hr>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/teasers/example-teaser.jade" ));
var adminStyle = 'border: 2px solid #4baad3'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n              <div class=\"media\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"media-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("<img src=\"https://www.gravatar.com/avatar/2146b557530c4c38c84c93113ff210c3\" width=\"24\" height=\"24\"" + (jade.attr("style", adminStyle, true, true)) + " class=\"img-circle\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"media-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/teasers/experiments.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("Jonathan Kim, CEO @ Appcues");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/teasers/experiments.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("&#x1f44b; Hi! We're building a new suite of features to help you improve your user activation rate. Is this something you're already doing?");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/teasers/experiments.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push("We currently have ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/teasers/experiments.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/teasers/experiments.jade" ));
buf.push("<b>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("goal tracking");
jade_debug.shift();
jade_debug.shift();
buf.push("</b>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push(" and ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/teasers/experiments.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/teasers/experiments.jade" ));
buf.push("<b>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("A/B testing");
jade_debug.shift();
jade_debug.shift();
buf.push("</b>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push(" on our minds.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/teasers/experiments.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("What are we missing?");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n                </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n              </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/teasers/example-teaser.jade" ));
// iterate feedback
;(function(){
  var $$obj = feedback;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var response = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n              <div class=\"media\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"media-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("<img" + (jade.attr("src", response.createdByAvatar, true, true)) + " width=\"24\" height=\"24\"" + (jade.attr("style", (response.createdByAdmin ? adminStyle : ''), true, true)) + " class=\"img-circle\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 42, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"media-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 43, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 44, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                    <div class=\"col-xs-6\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 45, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 45, jade_debug[0].filename ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = response.createdByDisplay) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 46, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                    <div class=\"col-xs-6 text-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 47, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                      <p class=\"small text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 47, jade_debug[0].filename ));
buf.push("Sent &bull; " + (jade.escape((jade_interp = response.updatedAtDisplay) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 48, "app/views/templates/teasers/example-teaser.jade" ));
// iterate response.messages
;(function(){
  var $$obj = response.messages;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var message = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 48, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 49, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 49, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = message) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var message = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 48, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 49, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 49, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = message) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n                </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n              </div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var response = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n              <div class=\"media\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"media-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("<img" + (jade.attr("src", response.createdByAvatar, true, true)) + " width=\"24\" height=\"24\"" + (jade.attr("style", (response.createdByAdmin ? adminStyle : ''), true, true)) + " class=\"img-circle\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 42, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"media-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 43, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 44, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                    <div class=\"col-xs-6\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 45, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 45, jade_debug[0].filename ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = response.createdByDisplay) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 46, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                    <div class=\"col-xs-6 text-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 47, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                      <p class=\"small text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 47, jade_debug[0].filename ));
buf.push("Sent &bull; " + (jade.escape((jade_interp = response.updatedAtDisplay) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 48, "app/views/templates/teasers/example-teaser.jade" ));
// iterate response.messages
;(function(){
  var $$obj = response.messages;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var message = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 48, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 49, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 49, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = message) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var message = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 48, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 49, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 49, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = message) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n                </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n              </div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 51, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n              <hr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 53, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n              <form class=\"form\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 54, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 55, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <textarea type=\"text\" name=\"body\" rows=\"3\" placeholder=\"I'd like to see it do this...\" class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</textarea>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 56, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                <ul class=\"list-inline\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 57, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 57, jade_debug[0].filename ));
buf.push("\n                    <button type=\"submit\" class=\"btn btn-success\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 57, jade_debug[0].filename ));
buf.push("Share your feedback");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                  </li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 58, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("\n                  <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 58, jade_debug[0].filename ));
buf.push("or ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("<a href=\"https://calendly.com/appcues/feedback\" target=\"_blank\" class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("schedule a call");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 58, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                </ul>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n              </form>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 62, "app/views/templates/teasers/example-teaser.jade" ));
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 62, "app/views/templates/teasers/example-teaser.jade" ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"feedback" in locals_for_with?locals_for_with.feedback:typeof feedback!=="undefined"?feedback:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends example-teaser\n\nblock header\n\nblock screenshot\n    img(src='/images/teasers/flow-analytics.png', width=\"100%\")\n\nblock description\n    h3 Appcues for Experiments\n    hr\n\nblock thread-placeholder\n    p: strong Jonathan Kim, CEO @ Appcues\n    p &#x1f44b; Hi! We're building a new suite of features to help you improve your user activation rate. Is this something you're already doing?\n    p We currently have #[b goal tracking] and #[b A/B testing] on our minds.\n    p What are we missing?");
}
};