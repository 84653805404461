module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/design/flow.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/design/flow.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/design/flow.jade" ));
buf.push("\n<div id=\"build-flow-settings\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/flow.jade" ));
buf.push("\n<div id=\"build-preview-frame\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "#build-flow-settings\n\n#build-preview-frame\n");
}
};