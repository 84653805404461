module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/step-actions/default.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/step-actions/step-action.jade" ));



































































































































































































































































































































































































jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 45, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n<section class=\"step-action-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-actions/default.jade" ));
buf.push("\n  <header>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/step-actions/default.jade" ));
buf.push("\n    <h2>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("What should your customer do in this step?");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </header>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/step-actions/default.jade" ));
buf.push("\n  <section>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/step-actions/default.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/step-actions/default.jade" ));
buf.push("\n      <div class=\"text-center col-sm-4\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/step-actions/default.jade" ));
buf.push("\n        <div data-action-type=\"image\" class=\"well show-step-action\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/step-actions/default.jade" ));
buf.push("\n          <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-picture-o fa-4x\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/step-actions/default.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, jade_debug[0].filename ));
buf.push("View a screenshot");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/step-actions/default.jade" ));
buf.push("\n      <div class=\"text-center col-sm-4\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/step-actions/default.jade" ));
buf.push("\n        <div data-action-type=\"video\" class=\"well show-step-action\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/step-actions/default.jade" ));
buf.push("\n          <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-play-circle fa-4x\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/step-actions/default.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("Watch a short video");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/step-actions/default.jade" ));
buf.push("\n      <div class=\"text-center col-sm-4\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/step-actions/default.jade" ));
buf.push("\n        <div data-action-type=\"social\" class=\"well show-step-action\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/step-actions/default.jade" ));
buf.push("\n          <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-twitter fa-4x\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/step-actions/default.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("Share on social media");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/step-actions/default.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/step-actions/default.jade" ));
buf.push("\n      <div class=\"text-center col-sm-4\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/step-actions/default.jade" ));
buf.push("\n        <div data-action-type=\"form\" class=\"well show-step-action\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/step-actions/default.jade" ));
buf.push("\n          <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-edit fa-4x\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/step-actions/default.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push("Fill out a form");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/step-actions/default.jade" ));
buf.push("\n    <div class=\"help-block text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/step-actions/default.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, jade_debug[0].filename ));
buf.push("Don't see the step you need? ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/step-actions/default.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/step-actions/default.jade" ));
buf.push("<a href=\"http://ideas.appcues.com\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Let us know");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, jade_debug[0].filename ));
buf.push(" to add it.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </section>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n</section>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 51, "app/views/templates/step-actions/default.jade" ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends step-action\n\nblock content\n    header\n        h2 What should your customer do in this step?\n\n    section\n        .row\n            .text-center(class='col-sm-4')\n                .well.show-step-action(data-action-type='image')\n                    div: i.fa.fa-picture-o.fa-4x\n                    span View a screenshot\n            .text-center(class='col-sm-4')\n                .well.show-step-action(data-action-type='video')\n                    div: i.fa.fa-play-circle.fa-4x\n                    span Watch a short video\n            .text-center(class='col-sm-4')\n                .well.show-step-action(data-action-type='social')\n                    div: i.fa.fa-twitter.fa-4x\n                    span Share on social media\n        .row\n            .text-center(class='col-sm-4')\n                .well.show-step-action(data-action-type='form')\n                    div: i.fa.fa-edit.fa-4x\n                    span Fill out a form\n\n        .help-block.text-center\n            p Don't see the step you need? #[a(href=\"http://ideas.appcues.com\" target=\"_blank\") Let us know] to add it.\n\nblock buttons\n");
}
};