module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/share-modal.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (flowId, previewUrl) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/share-modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/share-modal.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/share-modal.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/share-modal.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/share-modal.jade" ));
buf.push("<a data-dismiss=\"modal\" class=\"close\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/share-modal.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Sharing options");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/share-modal.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/share-modal.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Shareable permalink");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/share-modal.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("You can trigger this flow to appear by appending ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/share-modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/share-modal.jade" ));
buf.push("<code>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("?appcue=" + (jade.escape((jade_interp = flowId) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</code>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push(" to the end of any URL in your app. Give it a try:");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/share-modal.jade" ));
buf.push("\n      <pre style=\"display:flex; flex-direction:row; align-items:flex-start\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/share-modal.jade" ));
buf.push("<a" + (jade.attr("href", previewUrl, true, true)) + " target=\"_blank\" style=\"flex-grow:1; white-space:normal; align-self:center\">" + (jade.escape(null == (jade_interp = previewUrl) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/share-modal.jade" ));
buf.push("\n        <button class=\"btn btn-primary btn-sm copy\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Copy");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("</pre>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/share-modal.jade" ));
buf.push("\n      <p class=\"help-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("You can use this in emails, help docs or anywhere your users can open URL links.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/share-modal.jade" ));
buf.push("\n      <hr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/share-modal.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("Embeddable button");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/share-modal.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, jade_debug[0].filename ));
buf.push("Add a button that your users can click to bring up this flow. Here's an example:");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/share-modal.jade" ));
buf.push("\n      <div class=\"well text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/share-modal.jade" ));
buf.push("\n        <button class=\"btn btn-primary btn-sm\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push("Show hints &#x27a4;");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/share-modal.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, jade_debug[0].filename ));
buf.push("Copy the code below to use it:");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/share-modal.jade" ));
buf.push("\n      <pre style=\"display:flex; flex-direction:row; align-items:flex-start\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/share-modal.jade" ));
buf.push("<code style=\"flex-grow:1; white-space:normal\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/share-modal.jade" ));
buf.push("&lt;a class=\"btn btn-primary btn-sm\" href=\"javascript:Appcues.show('" + (jade.escape((jade_interp = flowId) == null ? '' : jade_interp)) + "')\"&gt;Show hints &amp;#x27a4;&lt;/a&gt;");
jade_debug.shift();
jade_debug.shift();
buf.push("</code>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/share-modal.jade" ));
buf.push("\n        <button class=\"btn btn-primary btn-sm copy\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, jade_debug[0].filename ));
buf.push("Copy");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("</pre>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/share-modal.jade" ));
buf.push("\n      <hr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/share-modal.jade" ));
buf.push("\n      <p class=\"help-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, "app/views/templates/share-modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 37, jade_debug[0].filename ));
buf.push("This flow's ID is ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/share-modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/share-modal.jade" ));
buf.push("<code>" + (jade.escape(null == (jade_interp = flowId) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</code>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 37, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/share-modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/share-modal.jade" ));
buf.push("\n        <button class=\"btn btn-primary btn-sm copy\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Copy");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 37, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/share-modal.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/share-modal.jade" ));
buf.push("<a data-dismiss=\"modal\" class=\"btn btn-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 40, jade_debug[0].filename ));
buf.push("Close");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"flowId" in locals_for_with?locals_for_with.flowId:typeof flowId!=="undefined"?flowId:undefined,"previewUrl" in locals_for_with?locals_for_with.previewUrl:typeof previewUrl!=="undefined"?previewUrl:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".modal-dialog\n    .modal-content\n        .modal-header\n            a.close(data-dismiss=\"modal\") &times;\n            h4.modal-title Sharing options\n\n        .modal-body\n            p: strong Shareable permalink\n\n            p You can trigger this flow to appear by appending #[code ?appcue=#{flowId}] to the end of any URL in your app. Give it a try:\n\n            pre(style='display:flex; flex-direction:row; align-items:flex-start')\n                a(href=previewUrl, target='_blank', style='flex-grow:1; white-space:normal; align-self:center')= previewUrl\n                button.btn.btn-primary.btn-sm.copy Copy\n\n            p.help-block You can use this in emails, help docs or anywhere your users can open URL links.\n\n            hr\n\n            p: strong Embeddable button\n\n            p Add a button that your users can click to bring up this flow. Here's an example:\n\n            .well.text-center\n                button.btn.btn-primary.btn-sm Show hints &#x27a4;\n\n            p Copy the code below to use it:\n\n            pre(style='display:flex; flex-direction:row; align-items:flex-start')\n                code(style=\"flex-grow:1; white-space:normal\")\n                    | &lt;a class=\"btn btn-primary btn-sm\" href=\"javascript:Appcues.show('#{flowId}')\"&gt;Show hints &amp;#x27a4;&lt;/a&gt;\n                button.btn.btn-primary.btn-sm.copy Copy\n\n            hr\n\n            p.help-block\n                | This flow's ID is #[code= flowId]#[button.btn.btn-primary.btn-sm.copy Copy]\n\n        .modal-footer\n            a.btn.btn-link(data-dismiss='modal') Close\n");
}
};