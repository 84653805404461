module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/auth.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (account, accountId, auth, hideInviteUser, isNPSProductOnlyUser, isNPSProductTrial, isNPSProductTrialExpired, page, shouldSeeMobileLink, stats, status, user, window) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/auth.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/auth.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/auth.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/auth.jade" ));
buf.push("\n    <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/auth.jade" ));
if ( auth.provider == 'password' || auth.provider == 'google.com')
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/auth.jade" ));
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/auth.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/navbar.jade" ));
buf.push("\n      <nav role=\"navigation\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/navbar.jade" ));
var shouldShowFlowsProduct = !isNPSProductTrial && !isNPSProductOnlyUser
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/navbar.jade" ));
buf.push("\n        <div class=\"navbar-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/navbar.jade" ));
buf.push("\n          <div class=\"navbar-brand\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/navbar.jade" ));
var className = 'home hint--bottom'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/navbar.jade" ));
var logoUrl = '/images/mark.png'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/navbar.jade" ));
var imageSize = '20'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/navbar.jade" ));
var showTip = window.location.pathname != '/'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/navbar.jade" ));
var logoTip = showTip ? 'Back to your dashboard' : ''
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"/\"" + (jade.attr("data-hint", logoTip, true, true)) + (jade.cls([className], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/navbar.jade" ));
buf.push("<img" + (jade.attr("src", logoUrl, true, true)) + (jade.attr("height", imageSize, true, true)) + " class=\"logo\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/navbar.jade" ));
buf.push("\n        <ul class=\"nav navbar-nav navbar-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls([page.category == 'flows' && 'active'], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push("<a href=\"/\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push("Flows");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls([page.category == 'checklists' && 'active'], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, jade_debug[0].filename ));
buf.push("<a href=\"/checklists\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, jade_debug[0].filename ));
buf.push("Checklists");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls([page.category == 'satisfactionSurveys' && 'active'], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("<a href=\"/nps\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("NPS");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/navbar.jade" ));
if ( shouldSeeMobileLink)
{
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls([page.category == 'mobile' && 'active'], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"https://www.appcues.com/mobile/ios\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, jade_debug[0].filename ));
buf.push("Mobile ");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/navbar.jade" ));
buf.push("\n            <div class=\"new-tooltip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, jade_debug[0].filename ));
buf.push("NEW");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/navbar.jade" ));
if ( shouldShowFlowsProduct)
{
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/navbar.jade" ));
buf.push("\n          <li class=\"separator\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/navbar.jade" ));
if ( shouldShowFlowsProduct)
{
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls([page.category == 'styles' && 'active'], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push("<a href=\"/styling\" class=\"secondary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push("Themes");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/navbar.jade" ));
if ( shouldShowFlowsProduct)
{
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls([page.category == 'segments' && 'active'], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, jade_debug[0].filename ));
buf.push("<a href=\"/segments\" class=\"secondary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, jade_debug[0].filename ));
buf.push("Segments");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/navbar.jade" ));
if ( shouldShowFlowsProduct)
{
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls([page.category == 'goals' && 'active'], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 35, jade_debug[0].filename ));
buf.push("<a href=\"/goals\" class=\"secondary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 35, jade_debug[0].filename ));
buf.push("Goals");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </ul>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/navbar.jade" ));
buf.push("\n        <ul style=\"margin-right:0px\" class=\"nav navbar-nav navbar-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/navbar.jade" ));
if ( user.isAdmin)
{
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/navbar.jade" ));
buf.push("\n          <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 40, jade_debug[0].filename ));
buf.push("\n            <p class=\"navbar-text\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/navbar.jade" ));
if ( auth.spoofing)
{
jade_debug.unshift(new jade.DebugItem( 42, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 42, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"label label-danger\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 42, jade_debug[0].filename ));
buf.push("<a href=\"javascript: void(0);\" class=\"stop-spoofing\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 42, jade_debug[0].filename ));
buf.push("Spoofing #" + (jade.escape((jade_interp = accountId) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 44, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 44, "app/views/templates/navbar.jade" ));
buf.push("\n              <form style=\"padding-right:4px; margin:0; line-height:0;\" class=\"spoof navbar-form navbar-input-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 45, "app/views/templates/navbar.jade" ));
buf.push("\n                <div style=\"top: 5px;\" class=\"input-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 46, "app/views/templates/navbar.jade" ));
buf.push("\n                  <input type=\"text\"" + (jade.attr("placeholder", accountId, true, true)) + " name=\"accountId\" style=\"width:70px;\" class=\"input-sm form-control\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 47, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"input-group-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 47, jade_debug[0].filename ));
buf.push("\n                    <button class=\"btn btn-danger btn-sm\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 47, jade_debug[0].filename ));
buf.push("Spoof!");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n                </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n              </form>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 49, "app/views/templates/navbar.jade" ));
buf.push("\n          <li class=\"hidden-xs\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 50, "app/views/templates/navbar.jade" ));
if ( isNPSProductTrial || isNPSProductOnlyUser)
{
jade_debug.unshift(new jade.DebugItem( 51, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 51, "app/views/templates/navbar.jade" ));
buf.push("\n            <p class=\"navbar-text\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 52, "app/views/templates/navbar.jade" ));
if ( isNPSProductTrialExpired)
{
jade_debug.unshift(new jade.DebugItem( 53, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 53, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"/subscription\" class=\"label label-danger\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 53, jade_debug[0].filename ));
buf.push("Upgrade");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 55, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 55, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"/subscription\" class=\"label label-info\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 55, jade_debug[0].filename ));
buf.push("Upgrade");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </p>");
jade_debug.shift();
jade_debug.shift();
}
else if ( account.isTrial && !isNPSProductTrial)
{
jade_debug.unshift(new jade.DebugItem( 57, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 57, "app/views/templates/navbar.jade" ));
buf.push("\n            <p class=\"navbar-text\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 58, "app/views/templates/navbar.jade" ));
var flowsShown = stats.allTimeFlowsShown || 0
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 59, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"navbar-flows-shown-module\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 60, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"http://docs.appcues.com/article/194-how-the-appcues-free-trial-works\" target=\"_blank\" title=\"Learn more about how this is calculated\" class=\"navbar-flows-shown\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 61, "app/views/templates/navbar.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 61, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp =  flowsShown ) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 62, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"divide\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 62, jade_debug[0].filename ));
buf.push("/");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 63, "app/views/templates/navbar.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 63, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp =  account.flowsShownLimit ) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 64, "app/views/templates/navbar.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 64, jade_debug[0].filename ));
buf.push("&nbsp;flows shown");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 66, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"navbar-flows-shown navbar-flows-shown-beacon\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 67, "app/views/templates/navbar.jade" ));
buf.push("<i aria-hidden=\"true\" class=\"fa fa-question-circle\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 68, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"navbar-flows-shown-tooltip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 69, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"flows-shown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 69, jade_debug[0].filename ));
buf.push("How is this calculated?");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 70, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"flows-shown-section\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 70, jade_debug[0].filename ));
buf.push("Your trial is based on total times an Appcues flow has appeared.");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 71, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"flows-shown-section\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 71, jade_debug[0].filename ));
buf.push("Since the numbers on the dashboard show unique users, these numbers might differ.");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 73, "app/views/templates/navbar.jade" ));
if ( account.isTrialExpired)
{
jade_debug.unshift(new jade.DebugItem( 74, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 74, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"/subscription\" class=\"label label-danger\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 74, jade_debug[0].filename ));
buf.push("Upgrade");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 76, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 76, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"/subscription\" class=\"label label-info\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 76, jade_debug[0].filename ));
buf.push("Upgrade");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </p>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 78, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 78, "app/views/templates/navbar.jade" ));
buf.push("\n            <p class=\"navbar-text\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 78, jade_debug[0].filename ));
buf.push("<a href=\"javascript:Appcues.show('-KpWA8E-Ls0abpDddq6n')\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 79, "app/views/templates/navbar.jade" ));
buf.push("<small>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 79, jade_debug[0].filename ));
buf.push("Want to test new features?");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 81, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls(['dropdown',page.category == 'learn' && 'active'], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 82, "app/views/templates/navbar.jade" ));
buf.push("<a data-toggle=\"dropdown\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 83, "app/views/templates/navbar.jade" ));
buf.push("<i class=\"fa fa-question-circle\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 84, "app/views/templates/navbar.jade" ));
buf.push("\n            <ul id=\"resources-menu\" role=\"menu\" class=\"dropdown-menu\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 85, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 85, jade_debug[0].filename ));
buf.push("Product Adoption Resources");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 86, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 86, jade_debug[0].filename ));
buf.push("<a href=\"https://www.appcues.com/blog/appcues-ideas-and-inspirations?utm_source=internal&amp;utm_medium=header_learn&amp;utm_campaign=appcues-ideas-and-inspirations\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 86, jade_debug[0].filename ));
buf.push("Flow Ideas &amp; Inspirations&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 87, "app/views/templates/navbar.jade" ));
buf.push("<strong style=\"color:#FFAA39; font-size: 13px\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 87, jade_debug[0].filename ));
buf.push("NEW");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 88, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 88, jade_debug[0].filename ));
buf.push("<a href=\"https://www.appcues.com/webinars/on-demand-demo?utm_source=internal&amp;utm_medium=header_learn&amp;utm_campaign=on-demand-demo\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 88, jade_debug[0].filename ));
buf.push("Appcues On-Demand Demo");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 89, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 89, jade_debug[0].filename ));
buf.push("<a href=\"http://www.appcues.com/user-onboarding-academy?utm_source=internal&amp;utm_medium=learn&amp;utm_campaign=/user-onboarding-academy/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 89, jade_debug[0].filename ));
buf.push("User Onboarding Academy");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 91, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 91, jade_debug[0].filename ));
buf.push("Blog Posts");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 92, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 92, jade_debug[0].filename ));
buf.push("<a href=\"https://www.appcues.com/blog/announcing-appcues-2-0?utm_source=internal&amp;utm_medium=header_learn&amp;utm_campaign=announcing-appcues-2-0\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 92, jade_debug[0].filename ));
buf.push("Announcing Appcues 2.0&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 93, "app/views/templates/navbar.jade" ));
buf.push("<strong style=\"color:#FFAA39; font-size: 13px\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 93, jade_debug[0].filename ));
buf.push("NEW");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 94, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 94, jade_debug[0].filename ));
buf.push("<a href=\"https://www.appcues.com/blog/product-tours-when-to-use-which-ui-pattern/?utm_source=internal&amp;utm_medium=learn&amp;utm_campaign=/product-tours-when-to-use-which-ui-pattern/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 94, jade_debug[0].filename ));
buf.push("When to Use What Pattern");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 95, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 95, jade_debug[0].filename ));
buf.push("<a href=\"http://www.appcues.com/blog/?utm_source=internal&amp;utm_medium=learn&amp;utm_campaign=/blog/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 95, jade_debug[0].filename ));
buf.push("Blog Home");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 97, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 97, jade_debug[0].filename ));
buf.push("Support and Documentation");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 98, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 98, jade_debug[0].filename ));
buf.push("<a href=\"http://docs.appcues.com/?utm_source=internal&amp;utm_medium=learn&amp;utm_campaign=docs\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 98, jade_debug[0].filename ));
buf.push("Help Center");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 99, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 99, jade_debug[0].filename ));
buf.push("<a href=\"http://docs.appcues.com/category/45-getting-started?utm_source=internal&amp;utm_medium=learn&amp;utm_campaign=getting-started\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 99, jade_debug[0].filename ));
buf.push("Getting Started");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 100, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 100, jade_debug[0].filename ));
buf.push("<a href=\"http://docs.appcues.com/collection/76-developer-docs?utm_source=internal&amp;utm_medium=learn&amp;utm_campaign=developer-docs\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 100, jade_debug[0].filename ));
buf.push("Developer Docs");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </ul>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 102, "app/views/templates/navbar.jade" ));
buf.push("\n          <li class=\"hidden-xs\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 102, jade_debug[0].filename ));
buf.push("<a id=\"taco\" href=\"javascript:void(0)\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 102, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-inbox appcues-widget-icon\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 104, "app/views/templates/navbar.jade" ));
buf.push("\n          <li" + (jade.cls(['dropdown',page.category == 'settings' && 'active'], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 105, "app/views/templates/navbar.jade" ));
buf.push("<a href=\"#\" data-toggle=\"dropdown\" class=\"with-img dropdown-toggle\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 106, "app/views/templates/navbar.jade" ));
buf.push("<i class=\"fa fa-gear\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 107, "app/views/templates/navbar.jade" ));
buf.push("<img" + (jade.attr("src", user.avatarUrl, true, true)) + " width=\"30\" class=\"avatar img-rounded\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 108, "app/views/templates/navbar.jade" ));
buf.push("\n            <ul id=\"user-menu\" role=\"menu\" class=\"dropdown-menu\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 109, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 109, jade_debug[0].filename ));
buf.push("Manage");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 111, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 111, jade_debug[0].filename ));
buf.push("<a href=\"/account\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 111, jade_debug[0].filename ));
buf.push("Account");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 112, "app/views/templates/navbar.jade" ));
if ( !hideInviteUser)
{
jade_debug.unshift(new jade.DebugItem( 113, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 113, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 113, jade_debug[0].filename ));
buf.push("<a href=\"/team\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 113, jade_debug[0].filename ));
buf.push("Team");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 114, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 114, jade_debug[0].filename ));
buf.push("<a href=\"/subscription\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 114, jade_debug[0].filename ));
buf.push("Subscription");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 116, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 116, jade_debug[0].filename ));
buf.push("Configure");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 118, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 118, jade_debug[0].filename ));
buf.push("<a" + (jade.attr("href", '/install/' + (accountId) + '', true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 118, jade_debug[0].filename ));
buf.push("Installation");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 119, "app/views/templates/navbar.jade" ));
if ( !isNPSProductTrial)
{
jade_debug.unshift(new jade.DebugItem( 120, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 120, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 120, jade_debug[0].filename ));
buf.push("<a href=\"/integrations\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 120, jade_debug[0].filename ));
buf.push("Integrations");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 121, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 121, jade_debug[0].filename ));
buf.push("<a href=\"/events-attributes\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 121, jade_debug[0].filename ));
buf.push("Events and Attributes");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 123, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 123, jade_debug[0].filename ));
buf.push("Reference");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 125, "app/views/templates/navbar.jade" ));
if ( user.isAdmin)
{
jade_debug.unshift(new jade.DebugItem( 126, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 126, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 126, jade_debug[0].filename ));
buf.push("<a" + (jade.attr("href", '/install/status/' + (accountId) + '', true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 126, jade_debug[0].filename ));
buf.push("Status");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 127, "app/views/templates/navbar.jade" ));
if ( shouldShowFlowsProduct)
{
jade_debug.unshift(new jade.DebugItem( 128, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 128, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 128, jade_debug[0].filename ));
buf.push("<a href=\"/diagnostics\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 128, jade_debug[0].filename ));
buf.push("Flow Diagnostics");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 129, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 129, jade_debug[0].filename ));
buf.push("<a href=\"http://docs.appcues.com/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 129, jade_debug[0].filename ));
buf.push("Help Center");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 131, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"dropdown-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 131, jade_debug[0].filename ));
buf.push("Status");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 133, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"application-status\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 133, jade_debug[0].filename ));
buf.push("<a href=\"http://status.appcues.com/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 134, "app/views/templates/navbar.jade" ));
if ( status.indicator)
{
jade_debug.unshift(new jade.DebugItem( 135, "app/views/templates/navbar.jade" ));
jade_debug.unshift(new jade.DebugItem( 135, "app/views/templates/navbar.jade" ));
buf.push("<span" + (jade.cls(['status-dot','status-' + (status.indicator) + ''], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 136, "app/views/templates/navbar.jade" ));
buf.push("<span class=\"status-description\">" + (jade.escape(null == (jade_interp = status.description || 'Application Status') ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 138, "app/views/templates/navbar.jade" ));
buf.push("\n              <li class=\"divider\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 139, "app/views/templates/navbar.jade" ));
buf.push("\n              <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 139, jade_debug[0].filename ));
buf.push("<a href=\"/logout\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 139, jade_debug[0].filename ));
buf.push("Log out");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </ul>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </ul>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </nav>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"account" in locals_for_with?locals_for_with.account:typeof account!=="undefined"?account:undefined,"accountId" in locals_for_with?locals_for_with.accountId:typeof accountId!=="undefined"?accountId:undefined,"auth" in locals_for_with?locals_for_with.auth:typeof auth!=="undefined"?auth:undefined,"hideInviteUser" in locals_for_with?locals_for_with.hideInviteUser:typeof hideInviteUser!=="undefined"?hideInviteUser:undefined,"isNPSProductOnlyUser" in locals_for_with?locals_for_with.isNPSProductOnlyUser:typeof isNPSProductOnlyUser!=="undefined"?isNPSProductOnlyUser:undefined,"isNPSProductTrial" in locals_for_with?locals_for_with.isNPSProductTrial:typeof isNPSProductTrial!=="undefined"?isNPSProductTrial:undefined,"isNPSProductTrialExpired" in locals_for_with?locals_for_with.isNPSProductTrialExpired:typeof isNPSProductTrialExpired!=="undefined"?isNPSProductTrialExpired:undefined,"page" in locals_for_with?locals_for_with.page:typeof page!=="undefined"?page:undefined,"shouldSeeMobileLink" in locals_for_with?locals_for_with.shouldSeeMobileLink:typeof shouldSeeMobileLink!=="undefined"?shouldSeeMobileLink:undefined,"stats" in locals_for_with?locals_for_with.stats:typeof stats!=="undefined"?stats:undefined,"status" in locals_for_with?locals_for_with.status:typeof status!=="undefined"?status:undefined,"user" in locals_for_with?locals_for_with.user:typeof user!=="undefined"?user:undefined,"window" in locals_for_with?locals_for_with.window:typeof window!=="undefined"?window:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".container\n    .row\n        .col-md-12\n            if auth.provider == 'password' || auth.provider == 'google.com'\n                include navbar\n");
}
};