module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/segment-step.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (undefined, window) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/segment-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/mixins/snippets.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/mixins/snippets.jade" ));




































jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/mixins/snippets.jade" ));












































































jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/mixins/snippets.jade" ));






































































jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/mixins/snippets.jade" ));



























































































jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/segment-step.jade" ));
buf.push("\n<script src=\"//static.segment.com/enable-button/v1/index.js\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</script>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/segment-step.jade" ));
buf.push("\n<div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/segment-step.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, jade_debug[0].filename ));
buf.push("\n    <div class=\"col-md-8 col-md-offset-2\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/segment-step.jade" ));
buf.push("\n      <p style=\"margin-bottom: 15px\" class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("If you've already got ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/segment-step.jade" ));
buf.push("<a href=\"https://segment.com/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Segment");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/segment-step.jade" ));
buf.push(" enabled on your site, you can set up Appcues instantly:");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/segment-step.jade" ));
buf.push("\n  <div style=\"margin-bottom: 35px\" class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/segment-step.jade" ));
buf.push("\n    <div class=\"btn-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/segment-step.jade" ));
buf.push("\n      <div data-integration=\"appcues\"" + (jade.attr("data-settings", { appcuesId: this.meta.get("accountId"), apiKey: this.meta.get("apiKey")}, true, true)) + (jade.attr("data-redirect-url", "" + (window.__env.SITE_ORIGIN) + "/segment-enabled?appcue=-JzVdegbbClYa_9OVe6W", true, true)) + " style=\"display: inline-block;\" class=\"segment-enable-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined,"window" in locals_for_with?locals_for_with.window:typeof window!=="undefined"?window:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "include mixins/snippets\n\nscript(src=\"//static.segment.com/enable-button/v1/index.js\")\n\ndiv\n    .row: .col-md-8.col-md-offset-2\n        p.text-center(style=\"margin-bottom: 15px\") If you've already got \n          a(href=\"https://segment.com/\", target=\"_blank\") Segment\n          |  enabled on your site, you can set up Appcues instantly:\n    .text-center(style=\"margin-bottom: 35px\")\n        .btn-group\n            div.segment-enable-button(data-integration=\"appcues\" data-settings={ appcuesId: this.meta.get(\"accountId\"), apiKey: this.meta.get(\"apiKey\")} data-redirect-url=\"#{window.__env.SITE_ORIGIN}/segment-enabled?appcue=-JzVdegbbClYa_9OVe6W\" style=\"display: inline-block;\")\n");
}
};