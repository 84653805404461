module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/preview-frame-launch-extension.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/preview-frame-launch-extension.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n<div class=\"appcues-preview-frame-message-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n  <div class=\"appcues-preview-frame-message container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n      <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n        <h2>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Launch the Appcues Chrome Extension");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n      <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Chrome is preventing us from properly loading your app in our preview window.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("Use the button below to preview and build your content in a new window using our Chrome extension.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n        <p class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n          <button data-action=\"initExtensionBuilder\" class=\"btn btn-primary btn-lg\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("Launch in a new window");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/preview-frame-launch-extension.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push("If you notice any issues, please contact <a href=\"mailto:support@appcues.com\">support@appcues.com</a>.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".appcues-preview-frame-message-container\n    .appcues-preview-frame-message.container\n        .row\n            .col-md-12\n                h2 Launch the Appcues Chrome Extension\n        .row\n            .col-md-12\n                p Chrome is preventing us from properly loading your app in our preview window.\n\n                p Use the button below to preview and build your content in a new window using our Chrome extension.\n\n                p.text-center\n                    button.btn.btn-primary.btn-lg(data-action='initExtensionBuilder') Launch in a new window\n\n                p If you notice any issues, please contact <a href=\"mailto:support@appcues.com\">support@appcues.com</a>.\n\n");
}
};