module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-domains.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (accountHasDomains, domains, isPublished, ruleHasDomains, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/rule-domains.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n<div" + (jade.cls(['radio',(ruleHasDomains ? '' : 'active')], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n  <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n    <input type=\"radio\" name=\"toggleDomains\" value=\"all\"" + (jade.attr("checked", (!ruleHasDomains), true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("Any domains where my Appcues embed script is installed");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n<div" + (jade.cls(['radio',(ruleHasDomains ? 'active' : '')], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n  <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n    <input type=\"radio\" name=\"toggleDomains\" value=\"select\"" + (jade.attr("checked", ruleHasDomains, true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("Only the domains I choose");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n  <div class=\"content select-domains\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/settings/rule-domains.jade" ));
if ( accountHasDomains)
{
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/settings/rule-domains.jade" ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/settings/rule-domains.jade" ));
// iterate domains
;(function(){
  var $$obj = domains;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var domain = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/settings/rule-domains.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n      <div class=\"col-sm-6 col-md-4 col-lg-3\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n        <div class=\"checkbox\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n          <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n            <input type=\"checkbox\" name=\"domains\"" + (jade.attr("value", domain.name, true, true)) + (jade.attr("checked", domain.selected, true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push(jade.escape(null == (jade_interp = domain.name) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var domain = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/settings/rule-domains.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n      <div class=\"col-sm-6 col-md-4 col-lg-3\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n        <div class=\"checkbox\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n          <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n            <input type=\"checkbox\" name=\"domains\"" + (jade.attr("value", domain.name, true, true)) + (jade.attr("checked", domain.selected, true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push(jade.escape(null == (jade_interp = domain.name) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("<a href=\"/account\" target=\"_blank\" class=\"btn btn-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, jade_debug[0].filename ));
buf.push("Add domains");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/build-steps/settings/rule-domains.jade" ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("\n    <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("You haven't added any domains to your account yet. Once you do, you can target this flow to show only on specific domains.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/build-steps/settings/rule-domains.jade" ));
buf.push("<a href=\"/account\" target=\"_blank\" class=\"btn btn-default btn-sm\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, jade_debug[0].filename ));
buf.push("Add domains");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"accountHasDomains" in locals_for_with?locals_for_with.accountHasDomains:typeof accountHasDomains!=="undefined"?accountHasDomains:undefined,"domains" in locals_for_with?locals_for_with.domains:typeof domains!=="undefined"?domains:undefined,"isPublished" in locals_for_with?locals_for_with.isPublished:typeof isPublished!=="undefined"?isPublished:undefined,"ruleHasDomains" in locals_for_with?locals_for_with.ruleHasDomains:typeof ruleHasDomains!=="undefined"?ruleHasDomains:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".radio(class=(ruleHasDomains ? '' : 'active'))\n    label\n        input(type=\"radio\" name=\"toggleDomains\" value=\"all\" checked=(!ruleHasDomains) disabled=isPublished)\n        span\n        | Any domains where my Appcues embed script is installed\n.radio(class=(ruleHasDomains ? 'active' : ''))\n    label\n        input(type=\"radio\" name=\"toggleDomains\" value=\"select\" checked=ruleHasDomains disabled=isPublished)\n        span\n        | Only the domains I choose\n\n    .content.select-domains\n        if accountHasDomains\n            .row\n                for domain in domains\n                    .col-sm-6.col-md-4.col-lg-3\n                        .checkbox\n                            label\n                                input(type='checkbox' name='domains' value=domain.name checked=domain.selected disabled=isPublished)\n                                span\n                                = domain.name\n            a.btn.btn-link(href=\"/account\" target=\"_blank\") Add domains\n        else\n            p\n                | You haven't added any domains to your account yet. Once you do, you can target this flow to show only on specific domains.\n            a.btn.btn-default.btn-sm(href=\"/account\" target=\"_blank\") Add domains\n\n");
}
};