module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/video-modal.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (content) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/video-modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/video-modal.jade" ));
buf.push("\n<textarea placeholder=\"Add your own video embed code.\" rows=\"4\" class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/video-modal.jade" ));
buf.push(null == (jade_interp = content) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
buf.push("\n</textarea>");
jade_debug.shift();
jade_debug.shift();}.call(this,"content" in locals_for_with?locals_for_with.content:typeof content!=="undefined"?content:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "textarea.form-control(placeholder='Add your own video embed code.', rows='4')\n    != content\n");
}
};