module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-timeframe.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (date, timeframeType) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n<div class=\"timeframe\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n  <div role=\"alert\" class=\"alert alert-danger hidden\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
if ( timeframeType == 'start')
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n  <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n    <label for=\"date\" class=\"control-label\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, jade_debug[0].filename ));
buf.push("Start:");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n    <input name=\"date\" Label=\"Date\"" + (jade.attr("value", date, true, true)) + " placeholder=\"e.g. 2am march 14\" class=\"form-control timeframe-field\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
if ( timeframeType == 'end')
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n  <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n    <label for=\"date\" class=\"control-label\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("End:");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n    <input name=\"date\" Label=\"Date\"" + (jade.attr("value", date, true, true)) + " placeholder=\"e.g. next friday at noon\" class=\"form-control timeframe-field\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/settings/rule-timeframe.jade" ));
buf.push("\n  <div class=\"small help-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("These rules are specific to your timezone, not your users'.");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"date" in locals_for_with?locals_for_with.date:typeof date!=="undefined"?date:undefined,"timeframeType" in locals_for_with?locals_for_with.timeframeType:typeof timeframeType!=="undefined"?timeframeType:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "div.timeframe\n    .alert.alert-danger.hidden(role='alert')\n\n    if timeframeType == 'start'\n        .form-group\n            label.control-label(for='date') Start:\n            input.form-control.timeframe-field(name='date' Label='Date' value=date placeholder=\"e.g. 2am march 14\")\n\n    if timeframeType == 'end'\n        .form-group\n            label.control-label(for='date') End:\n            input.form-control.timeframe-field(name='date' Label='Date' value=date placeholder=\"e.g. next friday at noon\")\n\n    .small.help-block These rules are specific to your timezone, not your users'.");
}
};