module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/ctas/user-testing-cta.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n<div class=\"panel panel-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n  <div class=\"panel-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n    <div class=\"message-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("\n      <div class=\"media\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("\n        <div class=\"media-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("<img src=\"/images/trophy.png\" width=\"64\" height=\"64\" class=\"media-object\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("\n        <div class=\"media-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("\n          <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("We're looking for customers to user test some new interfaces for us.");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push(" It's your chance to help build a great product!");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("\n          <!-- Incentive variation-->");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("\n          <!--p We're looking for customers to user test some new interfaces for us. In exchange for 45 minutes of your time, you'll get a #[strong $25 Amazon gift card].-->");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n    <div class=\"message-actions\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/ctas/floating-cta.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("<a href=\"https://calendly.com/appcues-ux/appcues-user-test\" target=\"_blank\" class=\"btn btn-primary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("Schedule a user test");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("&nbsp;or&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/ctas/user-testing-cta.jade" ));
buf.push("<a class=\"dismiss\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("no thanks");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/ctas/floating-cta.jade" ));
buf.push("\n    <div class=\"powered-by text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("<small>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("Powered by Appcues");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends floating-cta\n\nblock body\n    .media\n        .media-left\n            img.media-object(src='/images/trophy.png', width='64', height='64')\n        .media-body\n            p We're looking for customers to user test some new interfaces for us.\n                strong  It's your chance to help build a great product!\n\n            // Incentive variation\n            //p We're looking for customers to user test some new interfaces for us. In exchange for 45 minutes of your time, you'll get a #[strong $25 Amazon gift card].\n\nblock actions\n    p\n        a.btn.btn-primary(href='https://calendly.com/appcues-ux/appcues-user-test', target='_blank') Schedule a user test\n        | &nbsp;or&nbsp;\n        a.dismiss no thanks\n");
}
};