module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/preview-frame-url-form.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (designStepUrl, previewUrl) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/preview-frame-url-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n<div class=\"appcues-preview-frame-message-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n  <div class=\"appcues-preview-frame-message container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n      <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n        <h2>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Set your target URL");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n      <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Chrome is preventing us from properly loading your app in our preview window.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("Enter the URL of the page in your app where you want to build content, then press &ldquo;Continue&rdquo; to get started.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n          <div class=\"input-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n            <input type=\"text\" name=\"previewUrl\"" + (jade.attr("value", previewUrl, true, true)) + " class=\"form-control\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("<span class=\"input-group-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("<a" + (jade.attr("href", designStepUrl, true, true)) + " class=\"btn btn-primary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("Continue");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/preview-frame-url-form.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("If you notice any issues, please contact <a href=\"mailto:support@appcues.com\">support@appcues.com</a>.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"designStepUrl" in locals_for_with?locals_for_with.designStepUrl:typeof designStepUrl!=="undefined"?designStepUrl:undefined,"previewUrl" in locals_for_with?locals_for_with.previewUrl:typeof previewUrl!=="undefined"?previewUrl:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".appcues-preview-frame-message-container\n    .appcues-preview-frame-message.container\n        .row\n            .col-md-12\n                h2 Set your target URL\n        .row\n            .col-md-12\n                p Chrome is preventing us from properly loading your app in our preview window.\n\n                p Enter the URL of the page in your app where you want to build content, then press &ldquo;Continue&rdquo; to get started.\n\n                p\n                    .input-group\n                        input.form-control(type='text' name='previewUrl' value=previewUrl)\n                        span.input-group-btn\n                            a.btn.btn-primary(href=designStepUrl) Continue\n\n                p If you notice any issues, please contact <a href=\"mailto:support@appcues.com\">support@appcues.com</a>.\n\n");
}
};