module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/form-fields.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (isEditable) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/form-fields.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/form-fields.jade" ));
buf.push("\n<div class=\"fields\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/form-fields.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields.jade" ));
buf.push("<a data-action=\"add-field\" class=\"btn btn-muted btn-block add-field\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/form-fields.jade" ));
buf.push("<i class=\"fa fa-plus\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/form-fields.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("&nbsp;Add a field");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "div.fields\nif isEditable\n    a.btn.btn-muted.btn-block.add-field(data-action=\"add-field\")\n        i.fa.fa-plus\n        span &nbsp;Add a field\n");
}
};