module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/mixins/integration.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/mixins/integration.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/mixins/integration.jade" ));



















































































































































































































jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "mixin integration(data)\n    .integration(\n        data-id=data.integrationId,\n        data-provider=data.id,\n        class={\n            enabled: data.isEnabled,\n            pending: data.status == 'pending',\n            configuring: data.settings !== undefined && data.status == 'configuring' })\n\n        .text-center.integration-logo\n            img(src=data.imageUrl, title=data.name, width='100%')\n\n        if data.isEnabled && data.status != 'pending'\n            p.text-success.text-center: strong\n                i.fa.fa-check\n                | &nbsp;#{data.name} integration active\n\n        .integration-action\n            if data.status == 'pending'\n                .btn.btn-block.btn-success.active\n                    i.fa.fa-spinner.fa-pulse\n                    | &nbsp;Setting up #{data.name}\n            else if data.isEnabled\n                a.btn.btn-block.btn-danger.remove-integration Deactivate\n            else if data.settings && data.status == 'configuring'\n                form.integration-form(action='#{integrationUrl}/auth/#{data.id}')\n                    for field in data.settings\n                        .form-group\n                            label= field.label\n                            input.form-control(name=field.name, type=field.type, value=data[field.name], placeholder=field.placeholder, required)\n                    button.btn.btn-block.btn-success(type='submit') Finish activation\n                a.cancel-configuration Cancel\n            else if !data.placeholder\n                a.btn.btn-block.btn-success.activate-integration Activate #{data.name}\n");
}
};