module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/settings/goal-where.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (goal, hasGoal, rule) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/goal-where.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Adding a conversion goal will let you track how many users make it to a certain URL after completing this flow.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/settings/goal-where.jade" ));
if ( hasGoal)
{
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/settings/goal-where.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n<div class=\"display-table\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n  <div style=\"width: 170px\" class=\"table-cell\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n    <select name=\"isRegex\"" + (jade.attr("disabled", rule.published, true, true)) + " class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n      <option value=\"false\"" + (jade.attr("selected", !goal.isRegex, true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("Match exactly");
jade_debug.shift();
jade_debug.shift();
buf.push("</option>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n      <option value=\"true\"" + (jade.attr("selected", goal.isRegex, true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Regular expression");
jade_debug.shift();
jade_debug.shift();
buf.push("</option>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </select>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n  <div class=\"table-cell cell-space\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n    <input name=\"where\"" + (jade.attr("value", goal.where, true, true)) + (jade.attr("disabled", rule.published, true, true)) + " placeholder=\"e.g. /checkout/complete/\" class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("\n  <div style=\"width: 16px\" class=\"table-cell text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("<a data-action=\"clearGoal\" data-hint=\"Remove conversion goal\" class=\"btn btn-link hint--left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("<i class=\"fa fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/settings/goal-where.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/settings/goal-where.jade" ));
if ( rule.published)
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/settings/goal-where.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("<a data-action=\"unpublishContent\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("Unpublish");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("&nbsp;to add a conversion goal.");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/settings/goal-where.jade" ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/settings/goal-where.jade" ));
buf.push("<a data-action=\"addGoal\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Add a conversion goal");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"goal" in locals_for_with?locals_for_with.goal:typeof goal!=="undefined"?goal:undefined,"hasGoal" in locals_for_with?locals_for_with.hasGoal:typeof hasGoal!=="undefined"?hasGoal:undefined,"rule" in locals_for_with?locals_for_with.rule:typeof rule!=="undefined"?rule:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "p Adding a conversion goal will let you track how many users make it to a certain URL after completing this flow.\n\nif hasGoal\n    .display-table\n        .table-cell(style=\"width: 170px\")\n            select.form-control(name='isRegex' disabled=rule.published)\n                option(value='false' selected=!goal.isRegex) Match exactly\n                option(value='true' selected=goal.isRegex) Regular expression\n        .table-cell.cell-space\n            input.form-control(name='where' value=goal.where disabled=rule.published placeholder=\"e.g. /checkout/complete/\")\n        .table-cell.text-center(style=\"width: 16px\")\n            a.btn.btn-link.hint--left(data-action='clearGoal' data-hint='Remove conversion goal')\n                i.fa.fa-trash\nelse\n    if rule.published\n        a(data-action='unpublishContent') Unpublish\n        | &nbsp;to add a conversion goal.\n    else\n        a(data-action='addGoal') Add a conversion goal\n\n");
}
};