module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (hotspots, isEditable, isSequential, selectedId, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("\n<ul class=\"thumbnails-list thumbnails-list-sequence list-group list-inline\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( hotspots)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
// iterate hotspots
;(function(){
  var $$obj = hotspots;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var hotspot = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( hotspot.id)
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
var isSelected = hotspot.id == selectedId
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("\n  <li" + (jade.attr("data-id", hotspot.id, true, true)) + (jade.cls(['thumbnail-item','list-group-item','draggable-list-group-item','text-center',(isSelected ? ' selected ' : '') + (isSequential ? 'sequential ' : '')], [null,null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( hotspot.nodeMissing)
{
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<img src=\"/images/icons/hotspot-missing.png\" width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<img src=\"/images/icons/hotspot.png\" width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("\n    <div class=\"thumbnail-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<a class=\"edit-hotspot\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-pencil\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-eye\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<a class=\"delete-hotspot\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("\n  <li class=\"thumbnail-item list-group-item dashed text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<img src=\"/images/icons/hotspot-add.png\" width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var hotspot = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( hotspot.id)
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
var isSelected = hotspot.id == selectedId
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("\n  <li" + (jade.attr("data-id", hotspot.id, true, true)) + (jade.cls(['thumbnail-item','list-group-item','draggable-list-group-item','text-center',(isSelected ? ' selected ' : '') + (isSequential ? 'sequential ' : '')], [null,null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( hotspot.nodeMissing)
{
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<img src=\"/images/icons/hotspot-missing.png\" width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<img src=\"/images/icons/hotspot.png\" width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("\n    <div class=\"thumbnail-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<a class=\"edit-hotspot\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-pencil\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-eye\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<a class=\"delete-hotspot\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("\n  <li class=\"thumbnail-item list-group-item dashed text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/build-steps/design/hotspot-group/hotspots-summary.jade" ));
buf.push("<img src=\"/images/icons/hotspot-add.png\" width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</ul>");
jade_debug.shift();
jade_debug.shift();}.call(this,"hotspots" in locals_for_with?locals_for_with.hotspots:typeof hotspots!=="undefined"?hotspots:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"isSequential" in locals_for_with?locals_for_with.isSequential:typeof isSequential!=="undefined"?isSequential:undefined,"selectedId" in locals_for_with?locals_for_with.selectedId:typeof selectedId!=="undefined"?selectedId:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "ul.thumbnails-list.thumbnails-list-sequence.list-group.list-inline\n    if hotspots\n        each hotspot in hotspots\n            if hotspot.id\n                - var isSelected = hotspot.id == selectedId\n                li.thumbnail-item.list-group-item.draggable-list-group-item.text-center(class=(isSelected ? ' selected ' : '') + (isSequential ? 'sequential ' : '') data-id=hotspot.id)\n                    if hotspot.nodeMissing\n                        img(src=\"/images/icons/hotspot-missing.png\", width='70', height='70')\n                    else\n                        img(src=\"/images/icons/hotspot.png\", width='70', height='70')\n\n                    .thumbnail-controls\n                        a.edit-hotspot\n                            if isEditable\n                                i.fa.fa-fw.fa-pencil\n                            else\n                                i.fa.fa-fw.fa-eye\n                        if isEditable\n                            a.delete-hotspot\n                                i.fa.fa-fw.fa-trash\n            else\n                if isEditable\n                    li.thumbnail-item.list-group-item.dashed.text-center\n                        img(src=\"/images/icons/hotspot-add.png\", width='70', height='70')\n");
}
};