module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/inspiration.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (examples, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/inspiration.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/inspiration.jade" ));
buf.push("\n<div class=\"ideas-inspiration\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/inspiration.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/inspiration.jade" ));
buf.push("\n    <div class=\"col-md-6 col-md-offset-3\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/inspiration.jade" ));
buf.push("\n      <div class=\"quickstart-page text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/inspiration.jade" ));
buf.push("<a href=\"http://www.appcues.com\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/inspiration.jade" ));
buf.push("<img src=\"/images/logo-white.png\" height=\"40\" class=\"logo\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/inspiration.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/inspiration.jade" ));
buf.push("\n      <div class=\"col-md-12 text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/inspiration.jade" ));
buf.push("\n        <div class=\"sub-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push(" ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/inspiration.jade" ));
buf.push("\n          <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("Discover the different use cases for in-app messaging and how you can achieve your goals using Appcues.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/inspiration.jade" ));
buf.push("\n  <div class=\"ideas-inspiration-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/inspiration.jade" ));
// iterate examples
;(function(){
  var $$obj = examples;
  if ('number' == typeof $$obj.length) {

    for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
      var val = $$obj[index];

jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/inspiration.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/inspiration.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/inspiration.jade" ));
buf.push("\n      <div class=\"article-content text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/inspiration.jade" ));
buf.push("\n        <h3>" + (jade.escape(null == (jade_interp = val.title) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</h3>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/inspiration.jade" ));
buf.push("\n        <p>" + (jade.escape(null == (jade_interp = val.blurb) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/inspiration.jade" ));
buf.push("<a" + (jade.attr("href", val.link, true, true)) + (jade.attr("data-article", index, true, true)) + " target=\"_blank\" class=\"blog-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Read more...");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/inspiration.jade" ));
buf.push("\n        <div class=\"slideshow\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div data-button=\"left\"" + (jade.attr("data-article", index, true, true)) + " class=\"button-container left-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/inspiration.jade" ));
buf.push("\n            <button>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/inspiration.jade" ));
buf.push("<i class=\"fa fa-angle-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div class=\"img-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/inspiration.jade" ));
buf.push("<img" + (jade.attr("data-total", val.pics.length, true, true)) + " data-index='0'" + (jade.attr("data-imgArticle", index, true, true)) + (jade.attr("src", val.pics[0], true, true)) + " class=\"slideshow-item\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div data-button=\"right\"" + (jade.attr("data-article", index, true, true)) + " class=\"button-container right-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/inspiration.jade" ));
buf.push("\n            <button>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/inspiration.jade" ));
buf.push("<i class=\"fa fa-angle-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/inspiration.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("Ready to create your own?");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div class=\"cta-button-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/inspiration.jade" ));
buf.push("\n            <button" + (jade.attr("data-article", index, true, true)) + " class=\"apc-button quickstart-menu\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push("Yes, let's go!");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/inspiration.jade" ));
buf.push("\n        <hr>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;      var val = $$obj[index];

jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/inspiration.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/inspiration.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/inspiration.jade" ));
buf.push("\n      <div class=\"article-content text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/inspiration.jade" ));
buf.push("\n        <h3>" + (jade.escape(null == (jade_interp = val.title) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</h3>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/inspiration.jade" ));
buf.push("\n        <p>" + (jade.escape(null == (jade_interp = val.blurb) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/inspiration.jade" ));
buf.push("<a" + (jade.attr("href", val.link, true, true)) + (jade.attr("data-article", index, true, true)) + " target=\"_blank\" class=\"blog-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Read more...");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/inspiration.jade" ));
buf.push("\n        <div class=\"slideshow\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div data-button=\"left\"" + (jade.attr("data-article", index, true, true)) + " class=\"button-container left-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/inspiration.jade" ));
buf.push("\n            <button>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/inspiration.jade" ));
buf.push("<i class=\"fa fa-angle-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div class=\"img-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/inspiration.jade" ));
buf.push("<img" + (jade.attr("data-total", val.pics.length, true, true)) + " data-index='0'" + (jade.attr("data-imgArticle", index, true, true)) + (jade.attr("src", val.pics[0], true, true)) + " class=\"slideshow-item\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div data-button=\"right\"" + (jade.attr("data-article", index, true, true)) + " class=\"button-container right-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/inspiration.jade" ));
buf.push("\n            <button>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/inspiration.jade" ));
buf.push("<i class=\"fa fa-angle-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/inspiration.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("Ready to create your own?");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/inspiration.jade" ));
buf.push("\n          <div class=\"cta-button-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/inspiration.jade" ));
buf.push("\n            <button" + (jade.attr("data-article", index, true, true)) + " class=\"apc-button quickstart-menu\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push("Yes, let's go!");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/inspiration.jade" ));
buf.push("\n        <hr>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"examples" in locals_for_with?locals_for_with.examples:typeof examples!=="undefined"?examples:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".ideas-inspiration\n    .row\n        .col-md-6.col-md-offset-3\n            .quickstart-page.text-center\n                a(href='http://www.appcues.com')\n                    img.logo(src='/images/logo-white.png', height='40')\n        .row\n            .col-md-12.text-center\n                .sub-header \n                    p Discover the different use cases for in-app messaging and how you can achieve your goals using Appcues.\n\n    .ideas-inspiration-content\n\n        each val, index in examples\n            .row\n                .article-content.text-center\n                    h3= val.title\n                    p= val.blurb\n                    a.blog-link(href=val.link, data-article=index, target=\"_blank\") Read more...\n                    .slideshow\n                        .button-container.left-button(data-button='left', data-article=index)\n                            button\n                                i.fa.fa-angle-left\n                        .img-container\n                            img.slideshow-item(data-total=val.pics.length, data-index=0, data-imgArticle=index, src=val.pics[0])\n                        .button-container.right-button(data-button='right', data-article=index)\n                            button\n                                i.fa.fa-angle-right\n                    p Ready to create your own?\n                        .cta-button-container\n                            button.apc-button.quickstart-menu(data-article=index) Yes, let's go!\n                    hr\n");
}
};