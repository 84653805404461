module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/create-content-with-domain.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (installed) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/create-content-with-domain.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/create-content-with-domain.jade" ));
buf.push("<a data-action=\"close\" data-hint=\"Close\" class=\"close hint--top\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/create-content-with-domain.jade" ));
if ( installed)
{
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/create-content-with-domain.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("You successfully installed the Appcues Chrome Extension!");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Our Chrome Extension makes building flows directly on your website easy.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Enter your website URL, then click Next.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/create-content-with-domain.jade" ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("Where do you want to create your Appcues flows?");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n      <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, jade_debug[0].filename ));
buf.push("Select or enter a website URL to start building.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n      <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n        <select name=\"chooseDomain\" placeholder=\"www.example.com\" class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</select>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/create-content-with-domain.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/create-content-with-domain.jade" ));
buf.push("<a data-action=\"done\" class=\"btn btn-success pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Next");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"installed" in locals_for_with?locals_for_with.installed:typeof installed!=="undefined"?installed:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".modal-dialog\n    .modal-content\n        .modal-header\n            a.close.hint--top(data-action=\"close\" data-hint=\"Close\") &times;\n\n            if installed\n                h4.modal-title You successfully installed the Appcues Chrome Extension!\n                p Our Chrome Extension makes building flows directly on your website easy.\n                p Enter your website URL, then click Next.\n            else\n                h4.modal-title Where do you want to create your Appcues flows?\n                p Select or enter a website URL to start building.\n\n        .modal-body\n            .form-group\n                select.form-control(name='chooseDomain' placeholder=\"www.example.com\")\n\n        .modal-footer\n            a.btn.btn-success.pull-right(data-action=\"done\") Next\n");
}
};