module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-events.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (eventNames, isPublished, negativeMatch, positiveMatch, ruleTargetEventName, selected, showTargetEvents, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/rule-events.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("<a href=\"http://docs.appcues.com/article/156-event-targeting\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("Learn more about event targeting.");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n<div" + (jade.cls(['radio',(showTargetEvents ? '' : 'active')], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n  <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n    <input type=\"radio\" name=\"toggleEvents\" value=\"all\"" + (jade.attr("checked", (showTargetEvents ? false : true), true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("Any event history");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n<div" + (jade.cls(['radio',(positiveMatch ? 'active' : '')], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n  <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n    <input type=\"radio\" name=\"toggleEvents\" value=\"positiveMatch\"" + (jade.attr("checked", (positiveMatch ? true : false), true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("Users who have experienced an event");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n<div" + (jade.cls(['radio',(negativeMatch ? 'active' : '')], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n  <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n    <input type=\"radio\" name=\"toggleEvents\" value=\"negativeMatch\"" + (jade.attr("checked", (negativeMatch ? true : false), true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("Users who have not experienced an event");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n<div" + (jade.cls(['content',(showTargetEvents ? 'active' : '')], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/build-steps/settings/rule-events.jade" ));
if ( eventNames.length > 0)
{
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/build-steps/settings/rule-events.jade" ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n  <select id=\"targetEventName\" name=\"targetEventName\"" + (jade.attr("disabled", isPublished, true, true)) + " class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n    <option value=\"\"" + (jade.attr("selected", (!ruleTargetEventName ? 'selected' : false), true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</option>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/build-steps/settings/rule-events.jade" ));
// iterate eventNames
;(function(){
  var $$obj = eventNames;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var eventName = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/build-steps/settings/rule-events.jade" ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/build-steps/settings/rule-events.jade" ));
selected = (ruleTargetEventName && (ruleTargetEventName === eventName))
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n    <option" + (jade.attr("value", eventName, true, true)) + (jade.attr("data-data", {name: eventName}, true, true)) + (jade.attr("selected", (selected ? 'selected' : false), true, true)) + ">" + (jade.escape(null == (jade_interp = eventName) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</option>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var eventName = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/build-steps/settings/rule-events.jade" ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/build-steps/settings/rule-events.jade" ));
selected = (ruleTargetEventName && (ruleTargetEventName === eventName))
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n    <option" + (jade.attr("value", eventName, true, true)) + (jade.attr("data-data", {name: eventName}, true, true)) + (jade.attr("selected", (selected ? 'selected' : false), true, true)) + ">" + (jade.escape(null == (jade_interp = eventName) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</option>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n  </select>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/build-steps/settings/rule-events.jade" ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/build-steps/settings/rule-events.jade" ));
buf.push("\n  <div class=\"help-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push("Whoops! Looks like you haven't sent us any third-party events yet.");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"eventNames" in locals_for_with?locals_for_with.eventNames:typeof eventNames!=="undefined"?eventNames:undefined,"isPublished" in locals_for_with?locals_for_with.isPublished:typeof isPublished!=="undefined"?isPublished:undefined,"negativeMatch" in locals_for_with?locals_for_with.negativeMatch:typeof negativeMatch!=="undefined"?negativeMatch:undefined,"positiveMatch" in locals_for_with?locals_for_with.positiveMatch:typeof positiveMatch!=="undefined"?positiveMatch:undefined,"ruleTargetEventName" in locals_for_with?locals_for_with.ruleTargetEventName:typeof ruleTargetEventName!=="undefined"?ruleTargetEventName:undefined,"selected" in locals_for_with?locals_for_with.selected:typeof selected!=="undefined"?selected:undefined,"showTargetEvents" in locals_for_with?locals_for_with.showTargetEvents:typeof showTargetEvents!=="undefined"?showTargetEvents:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "p\n  a(href='http://docs.appcues.com/article/156-event-targeting' target='_blank')\n    | Learn more about event targeting.\n\n.radio(class=(showTargetEvents ? '' : 'active'))\n    label\n        input(type=\"radio\" name=\"toggleEvents\" value=\"all\" checked=(showTargetEvents ? false : true) disabled=isPublished)\n        span\n        | Any event history\n\n.radio(class=(positiveMatch ? 'active' : ''))\n    label\n        input(type=\"radio\" name=\"toggleEvents\" value=\"positiveMatch\" checked=(positiveMatch ? true : false) disabled=isPublished)\n        span\n        | Users who have experienced an event\n\n.radio(class=(negativeMatch ? 'active' : ''))\n    label\n        input(type=\"radio\" name=\"toggleEvents\" value=\"negativeMatch\" checked=(negativeMatch ? true : false) disabled=isPublished)\n        span\n        | Users who have not experienced an event\n\n.content(class=(showTargetEvents ? 'active' : ''))\n    if eventNames.length > 0\n        select#targetEventName.form-control(name='targetEventName' disabled=isPublished)\n            option(value=\"\" selected=(!ruleTargetEventName ? 'selected' : false))\n            each eventName in eventNames\n                - selected = (ruleTargetEventName && (ruleTargetEventName === eventName))\n                option(value=eventName data-data={name: eventName} selected=(selected ? 'selected' : false))= eventName\n    else\n        .help-block Whoops! Looks like you haven't sent us any third-party events yet.\n\n\n");
}
};