module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/step-actions/social-buttons.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (buttons, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/step-actions/social-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/step-actions/social-buttons.jade" ));
if ( buttons)
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/step-actions/social-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/step-actions/social-buttons.jade" ));
buf.push("\n<div class=\"appcues-social-buttons\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/social-buttons.jade" ));
// iterate buttons
;(function(){
  var $$obj = buttons;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var button = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/social-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-actions/social-buttons.jade" ));
buf.push("<a" + (jade.attr("href", button.link, true, true)) + " target=\"_blank\"" + (jade.cls(['appcues-social-button',button.className], [null,true])) + ">" + (jade.escape(null == (jade_interp = button.text) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var button = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/social-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-actions/social-buttons.jade" ));
buf.push("<a" + (jade.attr("href", button.link, true, true)) + " target=\"_blank\"" + (jade.cls(['appcues-social-button',button.className], [null,true])) + ">" + (jade.escape(null == (jade_interp = button.text) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"buttons" in locals_for_with?locals_for_with.buttons:typeof buttons!=="undefined"?buttons:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "if buttons\n    .appcues-social-buttons\n        for button in buttons\n            a.appcues-social-button(class=button.className, href=button.link, target='_blank')= button.text\n");
}
};