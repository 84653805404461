module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/alerts/confirm.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (cancelButtonText, confirmButtonText, hideFooter, text, title) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/alerts/confirm.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/alerts/confirm.jade" ));
buf.push("\n<div class=\"modal-dialog modal-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/alerts/confirm.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/alerts/confirm.jade" ));
buf.push("\n    <div class=\"modal-body text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/alerts/confirm.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/alerts/confirm.jade" ));
buf.push("\n      <h4>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/alerts/confirm.jade" ));
buf.push("\n      <p>" + (jade.escape(null == (jade_interp = text) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/alerts/confirm.jade" ));
if (!( hideFooter))
{
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/alerts/confirm.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/alerts/confirm.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/alerts/confirm.jade" ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/alerts/confirm.jade" ));
var cancelText = cancelButtonText || "Cancel"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/alerts/confirm.jade" ));
var confirmText = confirmButtonText || "Continue"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/alerts/confirm.jade" ));
buf.push("<a data-event=\"cancel\" data-dismiss=\"modal\" class=\"btn btn-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = cancelText) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/alerts/confirm.jade" ));
buf.push("\n      <button data-event=\"confirm\" type=\"button\" class=\"btn btn-success pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = confirmText) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"cancelButtonText" in locals_for_with?locals_for_with.cancelButtonText:typeof cancelButtonText!=="undefined"?cancelButtonText:undefined,"confirmButtonText" in locals_for_with?locals_for_with.confirmButtonText:typeof confirmButtonText!=="undefined"?confirmButtonText:undefined,"hideFooter" in locals_for_with?locals_for_with.hideFooter:typeof hideFooter!=="undefined"?hideFooter:undefined,"text" in locals_for_with?locals_for_with.text:typeof text!=="undefined"?text:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".modal-dialog.modal-center\n    .modal-content\n        .modal-body.text-center\n            block body\n                h4= title\n                p= text\n\n        unless hideFooter\n            .modal-footer\n                block footer\n                    - var cancelText = cancelButtonText || \"Cancel\"\n                    - var confirmText = confirmButtonText || \"Continue\"\n                    a.btn.btn-link(data-event='cancel', data-dismiss='modal') #{cancelText}\n                    button.btn.btn-success.pull-right(data-event='confirm', type='button') #{confirmText}\n");
}
};