module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/new-segment.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (attrs, operatorDisplay, propertyDisplay, undefined, valueDisplay) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/new-segment.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/new-segment.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/new-segment.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/new-segment.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/new-segment.jade" ));
buf.push("<a data-dismiss=\"modal\" class=\"close\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/new-segment.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Create a new segment");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/new-segment.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/new-segment.jade" ));
buf.push("\n      <p class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Save these property filters as a segment that can be reused for targeting other content.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/new-segment.jade" ));
buf.push("\n      <hr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/new-segment.jade" ));
buf.push("\n      <form class=\"form-horizontal\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/new-segment.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/new-segment.jade" ));
buf.push("\n          <label for=\"name\" class=\"control-label col-sm-2\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Name");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/new-segment.jade" ));
buf.push("\n          <div class=\"col-sm-10\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/new-segment.jade" ));
buf.push("\n            <input name=\"name\" placeholder=\"e.g. &quot;Trial users&quot; or &quot;Beta customers&quot;\" class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/new-segment.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/new-segment.jade" ));
buf.push("\n          <label class=\"control-label col-sm-2\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Filters");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/new-segment.jade" ));
buf.push("\n          <div class=\"col-sm-10\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/new-segment.jade" ));
buf.push("\n            <div class=\"form-control-static\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/new-segment.jade" ));
// iterate attrs.properties
;(function(){
  var $$obj = attrs.properties;
  if ('number' == typeof $$obj.length) {

    for (var name = 0, $$l = $$obj.length; name < $$l; name++) {
      var options = $$obj[name];

jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/new-segment.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/new-segment.jade" ));
buf.push("\n              <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/new-segment.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = propertyDisplay(name)) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/new-segment.jade" ));
buf.push("&nbsp;" + (jade.escape((jade_interp = operatorDisplay(name, options)) == null ? '' : jade_interp)) + "&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/new-segment.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = valueDisplay(name, options)) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var name in $$obj) {
      $$l++;      var options = $$obj[name];

jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/new-segment.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/new-segment.jade" ));
buf.push("\n              <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/new-segment.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = propertyDisplay(name)) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/new-segment.jade" ));
buf.push("&nbsp;" + (jade.escape((jade_interp = operatorDisplay(name, options)) == null ? '' : jade_interp)) + "&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/new-segment.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = valueDisplay(name, options)) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n            </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </form>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/new-segment.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/new-segment.jade" ));
buf.push("<a data-dismiss=\"modal\" class=\"btn btn-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("Cancel");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/new-segment.jade" ));
buf.push("<a data-action=\"save\" class=\"btn btn-primary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, jade_debug[0].filename ));
buf.push("Save");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"attrs" in locals_for_with?locals_for_with.attrs:typeof attrs!=="undefined"?attrs:undefined,"operatorDisplay" in locals_for_with?locals_for_with.operatorDisplay:typeof operatorDisplay!=="undefined"?operatorDisplay:undefined,"propertyDisplay" in locals_for_with?locals_for_with.propertyDisplay:typeof propertyDisplay!=="undefined"?propertyDisplay:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined,"valueDisplay" in locals_for_with?locals_for_with.valueDisplay:typeof valueDisplay!=="undefined"?valueDisplay:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".modal-dialog\n    .modal-content\n        .modal-header\n            a.close(data-dismiss=\"modal\") &times;\n            h4.modal-title Create a new segment\n\n        .modal-body\n            p.text-center Save these property filters as a segment that can be reused for targeting other content.\n\n            hr\n\n            form.form-horizontal\n                .form-group\n                    label.control-label.col-sm-2(for='name') Name\n                    .col-sm-10\n                        input.form-control(name='name' placeholder='e.g. \"Trial users\" or \"Beta customers\"')\n\n                .form-group\n                    label.control-label.col-sm-2 Filters\n                    .col-sm-10\n                        .form-control-static\n                            for options, name in attrs.properties\n                                div\n                                    strong= propertyDisplay(name)\n                                    | &nbsp;#{operatorDisplay(name, options)}&nbsp;\n                                    strong= valueDisplay(name, options)\n\n        .modal-footer\n            a.btn.btn-default(data-dismiss=\"modal\") Cancel\n            a.btn.btn-primary(data-action=\"save\") Save\n\n");
}
};