module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/settings/ab-testing.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (ABGroup, isABTested, isPublished) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/ab-testing.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("We'll automatically split your users into two groups. ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/ab-testing.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("<a href=\"http://docs.appcues.com/article/14-ab-testing-flows\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("See our documentation");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push(" on how this works and ways to see the results of your test.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n<form>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n  <div" + (jade.cls(['checkbox',isABTested ? 'active' : ''], [null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n    <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n      <input type=\"checkbox\" name=\"abtest\"" + (jade.attr("checked", isABTested, true, true)) + (jade.attr("disabled", isPublished, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("Enable A/B testing");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/settings/ab-testing.jade" ));
if ( isABTested)
{
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/settings/ab-testing.jade" ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n    <div class=\"content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n      <select name=\"abgroup\"" + (jade.attr("disabled", isPublished, true, true)) + " class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n        <option value=\"1\"" + (jade.attr("selected", (ABGroup === 1), true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("Show only to group A");
jade_debug.shift();
jade_debug.shift();
buf.push("</option>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n        <option value=\"2\"" + (jade.attr("selected", (ABGroup === 2), true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Show only to group B");
jade_debug.shift();
jade_debug.shift();
buf.push("</option>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </select>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("\n      <p class=\"help-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("Customize which group is the ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/ab-testing.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("experiment");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push(" and which is the ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/ab-testing.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("control.");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push(" Want even more power? ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/ab-testing.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("<a href=\"http://ideas.appcues.com/forums/235761-appcues-ideas/suggestions/14155629-ab-test-flows\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/ab-testing.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/ab-testing.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Share your feedback");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push(".");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</form>");
jade_debug.shift();
jade_debug.shift();}.call(this,"ABGroup" in locals_for_with?locals_for_with.ABGroup:typeof ABGroup!=="undefined"?ABGroup:undefined,"isABTested" in locals_for_with?locals_for_with.isABTested:typeof isABTested!=="undefined"?isABTested:undefined,"isPublished" in locals_for_with?locals_for_with.isPublished:typeof isPublished!=="undefined"?isPublished:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "p We'll automatically split your users into two groups. #[a(href='http://docs.appcues.com/article/14-ab-testing-flows', target='_blank') See our documentation] on how this works and ways to see the results of your test.\n\nform\n    .checkbox(class=isABTested ? 'active' : '')\n        label\n            input(type='checkbox', name='abtest', checked=isABTested, disabled=isPublished)\n            span\n            | Enable A/B testing\n\n        if isABTested\n            .content\n                select.form-control(name='abgroup', disabled=isPublished)\n                    option(value=\"1\", selected=(ABGroup === 1)) Show only to group A\n                    option(value=\"2\", selected=(ABGroup === 2)) Show only to group B\n\n                p.help-block Customize which group is the #[strong experiment] and which is the #[strong control.] Want even more power? #[a(href='http://ideas.appcues.com/forums/235761-appcues-ideas/suggestions/14155629-ab-test-flows', target='_blank') #[strong Share your feedback]].\n");
}
};