module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/password-reset.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (email, error, forgotPassword, isNewUser, password, showRedirectBackdrop, success, wasInvited) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/auth-form.jade" ));
buf.push("\n<div class=\"bigbody\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/auth-form.jade" ));
buf.push("\n<div class=\"registration-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/auth-form.jade" ));
buf.push("\n  <div class=\"auth-page text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/auth-form.jade" ));
buf.push("\n    <style>");
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("\n      ");
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("#hs-beacon {");
jade_debug.shift();
buf.push("\n      ");
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("    display: none!important;");
jade_debug.shift();
buf.push("\n      ");
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("}");
jade_debug.shift();
buf.push("\n      ");
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </style>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div class=\"logo-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/auth-form.jade" ));
buf.push("<a href=\"http://www.appcues.com\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/auth-form.jade" ));
buf.push("<img src=\"/images/appcues-logo.svg\" height=\"40\" class=\"logo\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/auth-form.jade" ));
if ( !wasInvited)
{
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/auth-form.jade" ));
buf.push("<a class=\"well well-sm text-center google-auth\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/auth-form.jade" ));
buf.push("\n      <div class=\"g-logo\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/auth-form.jade" ));
buf.push("<img src=\"/images/google-g-logo.png\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/auth-form.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("Continue with Google");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div class=\"divider\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/auth-form.jade" ));
buf.push("OR");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div class=\"panel panel-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/auth-form.jade" ));
buf.push("\n      <div class=\"panel-body auth-panel\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/auth-form.jade" ));
if ( showRedirectBackdrop)
{
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/auth-form.jade" ));
buf.push("\n        <div class=\"redirect-backdrop\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/auth-form.jade" ));
buf.push("\n        <div class=\"redirect\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/auth-form.jade" ));
buf.push("\n          <h2>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, jade_debug[0].filename ));
buf.push("You'll be redirected in just a moment.");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/auth-form.jade" ));
buf.push("\n          <p class=\"reset-login\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("(Oops, I changed my mind, let's go back.)");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/password-reset.jade" ));
if ( isNewUser)
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/password-reset.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/password-reset.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/password-reset.jade" ));
buf.push("You're one step away. Just");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/password-reset.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push(" choose your password");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/password-reset.jade" ));
buf.push(" to keep your account.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/password-reset.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/password-reset.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("Please choose a new password.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/password-reset.jade" ));
buf.push("\n        <form role=\"form\" class=\"reset-form\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/password-reset.jade" ));
buf.push("\n          <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/password-reset.jade" ));
if ( email)
{
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/password-reset.jade" ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/password-reset.jade" ));
buf.push("\n            <input type=\"email\"" + (jade.attr("value", email, true, true)) + " disabled class=\"form-control\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/password-reset.jade" ));
buf.push("\n            <input name=\"email\" type=\"hidden\"" + (jade.attr("value", email, true, true)) + ">");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/password-reset.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/password-reset.jade" ));
buf.push("\n            <input name=\"email\" type=\"email\" placeholder=\"Email\" class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/password-reset.jade" ));
buf.push("\n          <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/password-reset.jade" ));
buf.push("\n            <input name=\"password\" type=\"password\" placeholder=\"Password\" class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/password-reset.jade" ));
buf.push("\n          <input name=\"oldPassword\" type=\"hidden\"" + (jade.attr("value", password, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/password-reset.jade" ));
buf.push("\n          <input name=\"rememberMe\" type=\"checkbox\" checked=\"true\" class=\"hidden\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/password-reset.jade" ));
buf.push("\n          <button type=\"submit\" class=\"btn btn-block btn-primary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/password-reset.jade" ));
if ( isNewUser)
{
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/password-reset.jade" ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/password-reset.jade" ));
buf.push("Start onboarding customers");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/password-reset.jade" ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/password-reset.jade" ));
buf.push("Reset my password");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </button>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </form>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 52, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div class=\"validation-error hidden\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 53, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 54, "app/views/templates/auth-form.jade" ));
buf.push("\n      <div class=\"alert alert-warning\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 55, "app/views/templates/auth-form.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 55, jade_debug[0].filename ));
buf.push("Whoops!");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 56, "app/views/templates/auth-form.jade" ));
buf.push(" We had an error logging you in. Check that everything looks good and try again.");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 58, "app/views/templates/auth-form.jade" ));
if ( forgotPassword)
{
jade_debug.unshift(new jade.DebugItem( 59, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 59, "app/views/templates/auth-form.jade" ));
if ( error)
{
jade_debug.unshift(new jade.DebugItem( 60, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 60, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div class=\"alert alert-warning\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 60, jade_debug[0].filename ));
buf.push("We were unable to reset your password. Are you sure you signed up?");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
else if ( success)
{
jade_debug.unshift(new jade.DebugItem( 62, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 62, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div class=\"alert alert-success\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 62, jade_debug[0].filename ));
buf.push("Woot! Your new password is on it's way.");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 64, "app/views/templates/auth-form.jade" ));
buf.push("\n    <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 64, jade_debug[0].filename ));
buf.push("<a data-forgot=\"false\" class=\"toggle-form\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 64, jade_debug[0].filename ));
buf.push("Actually, I remember my password now.");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 66, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 66, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 67, "app/views/templates/auth-form.jade" ));
buf.push("<a data-forgot=\"true\" class=\"text-center login-text-cta toggle-form\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 68, "app/views/templates/auth-form.jade" ));
buf.push("Forgot your password?");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 69, "app/views/templates/auth-form.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 69, jade_debug[0].filename ));
buf.push(" Send yourself a new one.");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 71, "app/views/templates/auth-form.jade" ));
jade_debug.unshift(new jade.DebugItem( 72, "app/views/templates/auth-form.jade" ));
buf.push("\n    <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 73, "app/views/templates/auth-form.jade" ));
buf.push("<a href=\"/register\" class=\"text-center login-text-cta\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 74, "app/views/templates/auth-form.jade" ));
buf.push("Don't have an account?");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 75, "app/views/templates/auth-form.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 75, jade_debug[0].filename ));
buf.push(" Get yours now.");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"email" in locals_for_with?locals_for_with.email:typeof email!=="undefined"?email:undefined,"error" in locals_for_with?locals_for_with.error:typeof error!=="undefined"?error:undefined,"forgotPassword" in locals_for_with?locals_for_with.forgotPassword:typeof forgotPassword!=="undefined"?forgotPassword:undefined,"isNewUser" in locals_for_with?locals_for_with.isNewUser:typeof isNewUser!=="undefined"?isNewUser:undefined,"password" in locals_for_with?locals_for_with.password:typeof password!=="undefined"?password:undefined,"showRedirectBackdrop" in locals_for_with?locals_for_with.showRedirectBackdrop:typeof showRedirectBackdrop!=="undefined"?showRedirectBackdrop:undefined,"success" in locals_for_with?locals_for_with.success:typeof success!=="undefined"?success:undefined,"wasInvited" in locals_for_with?locals_for_with.wasInvited:typeof wasInvited!=="undefined"?wasInvited:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends auth-form\n\nblock content\n    if isNewUser\n        p\n            | You're one step away. Just\n            strong  choose your password\n            |  to keep your account.\n    else\n        p Please choose a new password.\n\n    form.reset-form(role='form')\n        .form-group\n            if email\n                input.form-control(type='email', value=email, disabled)\n                input(name='email', type='hidden', value=email)\n            else\n                input.form-control(name='email', type='email', placeholder='Email')\n\n        .form-group\n            input.form-control(name='password', type='password', placeholder='Password')\n\n        input(name='oldPassword', type='hidden', value=password)\n        input.hidden(name='rememberMe', type='checkbox', checked='true')\n\n        button.btn.btn-block.btn-primary(type='submit')\n            if isNewUser\n                | Start onboarding customers\n            else\n                | Reset my password\n");
}
};