module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/components/pacman.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (window) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/components/pacman.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/components/pacman.jade" ));
var pacmanURL = window.__env.SITE_ORIGIN + '/pacman';
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/components/pacman.jade" ));
buf.push("\n<div class=\"content-editor\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/components/pacman.jade" ));
buf.push("\n  <div style=\"text-align: center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/components/pacman.jade" ));
buf.push("\n    <iframe height=\"660px\" width=\"510px\"" + (jade.attr("src", pacmanURL, true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</iframe>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/components/pacman.jade" ));
buf.push("\n<div class=\"controls clearfix\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/components/pacman.jade" ));
buf.push("<a data-action=\"delete\" class=\"appcues-style btn btn-link btn-sm control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/components/pacman.jade" ));
buf.push("<a data-action=\"saveAndClose\" class=\"appcues-style btn btn-primary btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Save");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"window" in locals_for_with?locals_for_with.window:typeof window!=="undefined"?window:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "- var pacmanURL = window.__env.SITE_ORIGIN + '/pacman';\n\ndiv.content-editor\n    div(style='text-align: center')\n        iframe(height='660px' width='510px' src=pacmanURL)\n\ndiv.controls.clearfix\n    a.appcues-style.btn.btn-link.btn-sm.control-link(data-action='delete'): i.fa.fa-trash\n    a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save\n");
}
};