module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/sandboxed-step.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (backdrop, globalStyling, patternType, position, skippable, stepNumber, totalSteps) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/sandboxed-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n<div class=\"step-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n  <style type=\"text/css\" class=\"appcues-global-styling\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, jade_debug[0].filename ));
buf.push("" + (((jade_interp = globalStyling) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</style>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/sandboxed-step.jade" ));
if ( backdrop)
{
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/sandboxed-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n  <div" + (jade.attr("data-pattern-type", "" + (patternType) + "", true, true)) + " class=\"appcues-backdrop\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n  <appcues" + (jade.attr("data-pattern-type", "" + (patternType) + "", true, true)) + (jade.attr("data-position", '' + (position) + '', true, true)) + (jade.cls(["active cue-step-" + (stepNumber) + ""], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/sandboxed-step.jade" ));
var percent = ((stepNumber + 1) / totalSteps) * 100
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n    <!-- trick modal-container into thinking it is in fullscreen (backdrop=true) mode at all times.-->");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n    <!-- since the appcues-container isn't being resized programatically, it will occupy 100% of the page-->");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n    <!-- and `appcues` will take up 100% of it, making the user's app visible but not interactive-->");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/sandboxed-step.jade" ));
switch (patternType){
case "shorty":
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/sandboxed-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n    <modal-container class=\"fullscreen\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n      <div class=\"appcues-progress\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n        <div" + (jade.attr("style", "width: " + (percent) + "%", true, true)) + " class=\"appcues-progress-bar appcues-progress-bar-success\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/sandboxed-step.jade" ));
if ( skippable)
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/sandboxed-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n      <div class=\"appcues-skip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/sandboxed-step.jade" ));
buf.push("<a data-step=\"skip\" title=\"Skip\" class=\"skip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </modal-container>");
jade_debug.shift();
jade_debug.shift();
  break;
default:
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/sandboxed-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n    <div class=\"appcues-progress\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n      <div" + (jade.attr("style", "width: " + (percent) + "%", true, true)) + " class=\"appcues-progress-bar appcues-progress-bar-success\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/sandboxed-step.jade" ));
if ( skippable)
{
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/sandboxed-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/sandboxed-step.jade" ));
buf.push("\n    <div class=\"appcues-skip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/sandboxed-step.jade" ));
buf.push("<a data-step=\"skip\" title=\"Skip\" class=\"skip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
  break;
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </appcues>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"backdrop" in locals_for_with?locals_for_with.backdrop:typeof backdrop!=="undefined"?backdrop:undefined,"globalStyling" in locals_for_with?locals_for_with.globalStyling:typeof globalStyling!=="undefined"?globalStyling:undefined,"patternType" in locals_for_with?locals_for_with.patternType:typeof patternType!=="undefined"?patternType:undefined,"position" in locals_for_with?locals_for_with.position:typeof position!=="undefined"?position:undefined,"skippable" in locals_for_with?locals_for_with.skippable:typeof skippable!=="undefined"?skippable:undefined,"stepNumber" in locals_for_with?locals_for_with.stepNumber:typeof stepNumber!=="undefined"?stepNumber:undefined,"totalSteps" in locals_for_with?locals_for_with.totalSteps:typeof totalSteps!=="undefined"?totalSteps:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".step-container\n    style.appcues-global-styling(type=\"text/css\") !{globalStyling}\n    if backdrop\n        .appcues-backdrop(data-pattern-type=\"#{patternType}\")\n    appcues(data-pattern-type=\"#{patternType}\" data-position='#{position}' class=\"active cue-step-#{stepNumber}\")\n        -var percent = ((stepNumber + 1) / totalSteps) * 100\n        // trick modal-container into thinking it is in fullscreen (backdrop=true) mode at all times.\n        // since the appcues-container isn't being resized programatically, it will occupy 100% of the page\n        // and `appcues` will take up 100% of it, making the user's app visible but not interactive\n        case patternType\n            when \"shorty\"\n                modal-container(class=\"fullscreen\")\n                    .appcues-progress\n                        .appcues-progress-bar.appcues-progress-bar-success(style=\"width: #{percent}%\")\n                    if skippable\n                        .appcues-skip\n                            a.skip(data-step=\"skip\" title='Skip') &times;\n            default\n                .appcues-progress\n                    .appcues-progress-bar.appcues-progress-bar-success(style=\"width: #{percent}%\")\n                if skippable\n                    .appcues-skip\n                        a.skip(data-step=\"skip\" title='Skip') &times;");
}
};