module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/mixins/setting-value.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/mixins/setting-value.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/mixins/setting-value.jade" ));
jade_mixins["settingValue"] = jade_interp = function(settingClass, panelName, currentPanel, display, openable, withError){
var block = (this && this.block), attributes = (this && this.attributes) || {};
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/mixins/setting-value.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/mixins/setting-value.jade" ));
buf.push("<span" + (jade.attrs(jade.merge([{"data-panel-name": (typeof (jade_interp = panelName) == "string" ? jade.escape(jade_interp) : jade_interp),"class": (jade_interp = [true], jade.joinClasses(["" + (openable ? 'open-panel' : '') + " " + (settingClass) + " " + (currentPanel === panelName ? 'active' : '') + " " + (withError ? 'with-error' : '') + ""].map(jade.joinClasses).map(function (cls, i) {   return jade_interp[i] ? jade.escape(cls) : cls })))},attributes]), true)) + ">" + (jade.escape(null == (jade_interp = display) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
};
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/mixins/setting-value.jade" ));












jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/mixins/setting-value.jade" ));












jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "mixin settingValue(settingClass, panelName, currentPanel, display, openable, withError)\n    span(class=\"#{openable ? 'open-panel' : ''} #{settingClass} #{currentPanel === panelName ? 'active' : ''} #{withError ? 'with-error' : ''}\" data-panel-name=panelName)&attributes(attributes)= display\n\nmixin flowSettingValue(panelName, currentPanel, display, openable, withError)\n    +settingValue('flow-setting', panelName, currentPanel, display, openable, withError)&attributes(attributes)\n\nmixin hotspotsSettingValue(panelName, currentPanel, display, openable, withError)\n    +settingValue('hotspots-setting', panelName, currentPanel, display, openable, withError)&attributes(attributes)\n");
}
};