module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/components/content.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (componentType, content) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/components/content.jade" ));
buf.push("\n<div class=\"hover-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/components/content.jade" ));
if ( componentType && componentType != 'default' && componentType != 'pacman')
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/components/content.jade" ));
buf.push("<a data-action=\"edit\" title=\"Edit\" class=\"hint--top\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-fw fa-pencil\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/components/content.jade" ));
buf.push("&nbsp;Edit");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/components/content.jade" ));
buf.push("<a data-action=\"delete\" title=\"Remove\" class=\"hint--top\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/components/content.jade" ));
buf.push("&nbsp;Remove");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/components/content.jade" ));
buf.push("<a title=\"Reorder\" class=\"hint--top component-drag-handle\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-fw fa-arrows-v\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/components/content.jade" ));
buf.push("&nbsp;Reorder");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/components/content.jade" ));
if ( content)
{
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/components/content.jade" ));
buf.push(null == (jade_interp = content) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/components/content.jade" ));
buf.push("\n<div class=\"empty-component text-center appcues-style\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/components/content.jade" ));
if ( componentType === 'editor')
{
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-lg fa-paragraph\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/components/content.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push(" Click to add your text");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
else if ( componentType === 'image')
{
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-lg fa-image\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/components/content.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, jade_debug[0].filename ));
buf.push(" Click to add your image");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
else if ( componentType === 'video')
{
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-lg fa-film\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/components/content.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push(" Click to add your video");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
else if ( componentType === 'form')
{
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-lg fa-check-square-o\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/components/content.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, jade_debug[0].filename ));
buf.push(" Click to add your form");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
else if ( componentType === 'hero')
{
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/components/content.jade" ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/components/content.jade" ));
buf.push("<i class=\"fa fa-lg fa-image\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/components/content.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push(" Click to add your hero");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"componentType" in locals_for_with?locals_for_with.componentType:typeof componentType!=="undefined"?componentType:undefined,"content" in locals_for_with?locals_for_with.content:typeof content!=="undefined"?content:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".hover-controls\n    if componentType && componentType != 'default' && componentType != 'pacman'\n        a.hint--top(data-action='edit' title='Edit')\n            i.fa.fa-fw.fa-pencil\n            | &nbsp;Edit\n    a.hint--top(data-action='delete' title='Remove')\n        i.fa.fa-fw.fa-trash\n        | &nbsp;Remove\n    a.hint--top(title='Reorder').component-drag-handle\n        i.fa.fa-fw.fa-arrows-v\n        | &nbsp;Reorder\n\nif content\n    != content\nelse\n    .empty-component.text-center.appcues-style\n        if componentType === 'editor'\n            i.fa.fa-lg.fa-paragraph\n            span  Click to add your text\n        else if componentType === 'image'\n            i.fa.fa-lg.fa-image\n            span  Click to add your image\n        else if componentType === 'video'\n            i.fa.fa-lg.fa-film\n            span  Click to add your video\n        else if componentType === 'form'\n            i.fa.fa-lg.fa-check-square-o\n            span  Click to add your form\n        else if componentType === 'hero'\n            i.fa.fa-lg.fa-image\n            span  Click to add your hero\n");
}
};