module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/uninstall/uninstallCRX.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/uninstall/uninstallCRX.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/uninstall/uninstallCRX.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/uninstall/uninstallCRX.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/uninstall/uninstallCRX.jade" ));
buf.push("\n  <div class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/uninstall/uninstallCRX.jade" ));
buf.push("\n    <h1>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("We're sorry to see you go.");
jade_debug.shift();
jade_debug.shift();
buf.push("</h1>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/uninstall/uninstallCRX.jade" ));
buf.push("\n    <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("If there's anything we can do to help, email us at&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/uninstall/uninstallCRX.jade" ));
buf.push("<a href=\"mailto:support@appcues.com\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, jade_debug[0].filename ));
buf.push("support@appcues.com.");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".container\n    br\n    .text-center\n        h1 We're sorry to see you go.\n        p If there's anything we can do to help, email us at&nbsp;\n            a(href=\"mailto:support@appcues.com\") support@appcues.com.\n");
}
};