module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/analytics.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/analytics.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/analytics.jade" ));
buf.push("\n<div id=\"apc-react-root\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/analytics.jade" ));
buf.push("\n  <div class=\"spinner\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "div#apc-react-root\n    .spinner");
}
};