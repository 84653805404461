module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/configure-step.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/configure-step.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/configure-step.jade" ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "block content\n");
}
};