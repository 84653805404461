module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/preview-frame-content-warning.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/preview-frame-content-warning.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n<div class=\"appcues-preview-frame-message-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n  <div class=\"appcues-preview-frame-message container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n      <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n        <h2>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Allow mixed content on this page");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n      <div class=\"col-md-8\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("It looks like you might be loading your page over HTTP. If you can, try loading it over HTTPS.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( undefined, "app/views/templates/preview-frame-content-warning.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Otherwise, we'll just need you to allow mixed content for this page by clicking on the little badge up in the address bar and choosing ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/preview-frame-content-warning.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("\"Load unsafe scripts\"");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push(". Once you do that, you should be on your way to creating some great Appcues content!");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("\n      <div class=\"col-md-4 img-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/preview-frame-content-warning.jade" ));
buf.push("<img src=\"/images/mixed-content.png\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".appcues-preview-frame-message-container\n    .appcues-preview-frame-message.container\n        .row\n            .col-md-12\n                h2 Allow mixed content on this page\n        .row\n            .col-md-8\n                p It looks like you might be loading your page over HTTP. If you can, try loading it over HTTPS.\n                | Otherwise, we'll just need you to allow mixed content for this page by clicking on the little badge up in the address bar and choosing #[strong \"Load unsafe scripts\"]. Once you do that, you should be on your way to creating some great Appcues content!\n            .col-md-4.img-container\n                img(src=\"/images/mixed-content.png\")\n");
}
};