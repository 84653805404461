module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/step-settings.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (step) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/step-settings.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/step-settings.jade" ));
buf.push("\n<ul>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/step-settings.jade" ));
if ( step.stepType == 'video')
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-settings.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-settings.jade" ));
buf.push("\n  <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-settings.jade" ));
buf.push("<a class=\"show-video-hint\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/step-settings.jade" ));
buf.push("<i class=\"fa fa-fw fa-lightbulb-o\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/step-settings.jade" ));
if ( step.stepType == 'social')
{
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/step-settings.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/step-settings.jade" ));
buf.push("\n  <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/step-settings.jade" ));
buf.push("<a class=\"show-social-settings\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/step-settings.jade" ));
buf.push("<i class=\"fa fa-fw fa-cog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</ul>");
jade_debug.shift();
jade_debug.shift();}.call(this,"step" in locals_for_with?locals_for_with.step:typeof step!=="undefined"?step:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "ul\n    if step.stepType == 'video'\n        li\n            a.show-video-hint\n                i.fa.fa-fw.fa-lightbulb-o\n    if step.stepType == 'social'\n        li\n            a.show-social-settings\n                i.fa.fa-fw.fa-cog\n\n");
}
};