module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/location.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (contentType, previewUrl, useEager) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/location.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/location.jade" ));
buf.push("\n<div id=\"build-location-bar\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/location.jade" ));
buf.push("\n  <div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/location.jade" ));
buf.push("\n    <h5>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/location.jade" ));
buf.push("Enter a URL or navigate to a page for your&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/location.jade" ));
if ( contentType === 'hotspots')
{
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/location.jade" ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/location.jade" ));
buf.push("hotspots");
jade_debug.shift();
jade_debug.shift();
}
else if ( contentType === 'coachmarks')
{
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/location.jade" ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/location.jade" ));
buf.push("coachmarks");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/location.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/location.jade" ));
buf.push("flow");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/location.jade" ));
buf.push("&nbsp;below. Advanced targeting will be done later.");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/build-steps/location.jade" ));
buf.push("<small class=\"pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/location.jade" ));
if ( useEager)
{
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/location.jade" ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/build-steps/location.jade" ));
buf.push("<a data-action=\"switchPreviewMethod\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Is the preview not working with your site? Try this method instead!");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/location.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/location.jade" ));
buf.push("<a data-action=\"switchPreviewMethod\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("Switch back to the standard preview method.");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </h5>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/location.jade" ));
buf.push("\n    <div class=\"input-wrapper\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/location.jade" ));
buf.push("\n      <input name=\"previewUrl\" placeholder=\"Choose a URL to preview your content\"" + (jade.attr("value", previewUrl, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/build-steps/location.jade" ));
buf.push("<span class=\"indicator\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/location.jade" ));
buf.push("<a class=\"loading\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/build-steps/location.jade" ));
buf.push("<i class=\"fa fa-lg fa-circle-o-notch fa-spin text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/build-steps/location.jade" ));
buf.push("<a data-action=\"navigateFrame\" class=\"navigate\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/build-steps/location.jade" ));
buf.push("<i class=\"fa fa-lg fa-arrow-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/build-steps/location.jade" ));
buf.push("\n<div id=\"build-preview-frame\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"contentType" in locals_for_with?locals_for_with.contentType:typeof contentType!=="undefined"?contentType:undefined,"previewUrl" in locals_for_with?locals_for_with.previewUrl:typeof previewUrl!=="undefined"?previewUrl:undefined,"useEager" in locals_for_with?locals_for_with.useEager:typeof useEager!=="undefined"?useEager:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "#build-location-bar\n    .container\n        h5\n            | Enter a URL or navigate to a page for your&nbsp;\n            if contentType === 'hotspots'\n                | hotspots\n            else if contentType === 'coachmarks'\n                | coachmarks\n            else\n                | flow\n            | &nbsp;below. Advanced targeting will be done later.\n            small.pull-right\n                if useEager\n                    a(data-action=\"switchPreviewMethod\") Is the preview not working with your site? Try this method instead!\n                else\n                    a(data-action=\"switchPreviewMethod\") Switch back to the standard preview method.\n        .input-wrapper\n            input(name=\"previewUrl\" placeholder=\"Choose a URL to preview your content\", value=previewUrl)\n            span.indicator\n                a.loading\n                    i.fa.fa-lg.fa-circle-o-notch.fa-spin.text-muted\n                a.navigate(data-action=\"navigateFrame\")\n                    i.fa.fa-lg.fa-arrow-right\n\n#build-preview-frame\n");
}
};