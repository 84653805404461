module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/launch-crx.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/launch-crx.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/launch-crx.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/launch-crx.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/launch-crx.jade" ));
buf.push("\n    <div class=\"col-xs-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/launch-crx.jade" ));
buf.push("\n      <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("Launching Appcues Chrome Extension...");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".container\n    .row\n        .col-xs-12\n            div Launching Appcues Chrome Extension...");
}
};