module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/form-fields/field-type-buttons.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/form-fields/field-type-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n<div class=\"field-type-buttons\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n  <div class=\"field-type-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n    <div data-field-type=\"text\" title=\"Text\" class=\"btn btn-block btn-default choose-field-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<i class=\"fa fa-font\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("&nbsp;Text");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n  <div class=\"field-type-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n    <div data-field-type=\"textarea\" title=\"Textarea\" class=\"btn btn-block btn-default choose-field-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<i class=\"fa fa-align-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("&nbsp;Textarea");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n  <div class=\"field-type-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n    <div data-field-type=\"choice\" title=\"Choice\" class=\"btn btn-block btn-default choose-field-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<i class=\"fa fa-list-ul\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("&nbsp;Choice");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n  <div class=\"field-type-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n    <div data-field-type=\"rating\" title=\"Rating\" class=\"btn btn-block btn-default choose-field-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<i class=\"fa fa-star\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, jade_debug[0].filename ));
buf.push("&nbsp;Rating");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("\n  <div class=\"field-type-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<a data-action=\"removeField\" title=\"Remove\" class=\"btn btn-link control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/form-fields/field-type-buttons.jade" ));
buf.push("<i class=\"fa fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".field-type-buttons\n    .field-type-btn\n        .btn.btn-block.btn-default.choose-field-type(data-field-type='text' title='Text')\n            i.fa.fa-font\n            span &nbsp;Text\n    .field-type-btn\n        .btn.btn-block.btn-default.choose-field-type(data-field-type='textarea' title='Textarea')\n            i.fa.fa-align-left\n            span &nbsp;Textarea\n    .field-type-btn\n        .btn.btn-block.btn-default.choose-field-type(data-field-type='choice' title='Choice')\n            i.fa.fa-list-ul\n            span &nbsp;Choice\n    .field-type-btn\n        .btn.btn-block.btn-default.choose-field-type(data-field-type='rating' title='Rating')\n            i.fa.fa-star\n            span &nbsp;Rating\n    .field-type-btn\n        a.btn.btn-link.control-link(data-action='removeField' title='Remove')\n            i.fa.fa-trash");
}
};