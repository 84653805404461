module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/form-fields/rating.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (id, isEditable, label, ratingType, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n<div class=\"field-label\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/form-fields/rating.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n  <input placeholder=\"Add label...\"" + (jade.attr("value", label, true, true)) + " class=\"label-value\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n  <div class=\"field-actions\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/form-fields/rating.jade" ));
buf.push("<a data-hint=\"Drag and drop to reorder fields\" class=\"field-action field-drag-handle hint--left control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/form-fields/rating.jade" ));
buf.push("<i class=\"fa fa-sort\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n    <div class=\"btn-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/form-fields/rating.jade" ));
buf.push("<a data-hint=\"Field options\" role=\"button\" tabindex=\"-1\" class=\"field-action field-options hint--left control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/form-fields/rating.jade" ));
buf.push("<i class=\"fa fa-cog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/form-fields/rating.jade" ));
buf.push("<a data-action=\"removeField\" data-hint=\"Delete this field\" class=\"field-action hint--left control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/form-fields/rating.jade" ));
buf.push("<i class=\"fa fa-trash-o\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n  <label" + (jade.attr("for", id, true, true)) + " class=\"label-display\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n<div class=\"field-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/form-fields/rating.jade" ));
if ( ratingType == '1-5')
{
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n  <div class=\"rating-options\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/form-fields/rating.jade" ));
// iterate [1, 2, 3, 4, 5]
;(function(){
  var $$obj = [1, 2, 3, 4, 5];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var option = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n    <label class=\"rating-option\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n      <input type=\"radio\"" + (jade.attr("name", id, true, true)) + (jade.attr("value", option, true, true)) + " class=\"response-value\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/form-fields/rating.jade" ));
buf.push("<span>" + (jade.escape(null == (jade_interp = option) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var option = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n    <label class=\"rating-option\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n      <input type=\"radio\"" + (jade.attr("name", id, true, true)) + (jade.attr("value", option, true, true)) + " class=\"response-value\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/form-fields/rating.jade" ));
buf.push("<span>" + (jade.escape(null == (jade_interp = option) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n  <div class=\"rating-options\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/form-fields/rating.jade" ));
// iterate [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
;(function(){
  var $$obj = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var option = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n    <label class=\"rating-option\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n      <input type=\"radio\"" + (jade.attr("name", id, true, true)) + (jade.attr("value", option, true, true)) + " class=\"response-value\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/form-fields/rating.jade" ));
buf.push("<span>" + (jade.escape(null == (jade_interp = option) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var option = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/form-fields/rating.jade" ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n    <label class=\"rating-option\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n      <input type=\"radio\"" + (jade.attr("name", id, true, true)) + (jade.attr("value", option, true, true)) + " class=\"response-value\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/form-fields/rating.jade" ));
buf.push("<span>" + (jade.escape(null == (jade_interp = option) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n  <div class=\"rating-labels row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n    <div class=\"col-md-6 left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/form-fields/rating.jade" ));
buf.push("<small class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, jade_debug[0].filename ));
buf.push("Not likely");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/form-fields/rating.jade" ));
buf.push("\n    <div class=\"col-md-6 right text-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/form-fields/rating.jade" ));
buf.push("<small class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, jade_debug[0].filename ));
buf.push("Very likely");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"ratingType" in locals_for_with?locals_for_with.ratingType:typeof ratingType!=="undefined"?ratingType:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".field-label\n    if isEditable\n        input.label-value(placeholder=\"Add label...\" value=label)\n        .field-actions\n            a.field-action.field-drag-handle.hint--left.control-link(data-hint=\"Drag and drop to reorder fields\")\n                i.fa.fa-sort\n            .btn-group\n                a.field-action.field-options.hint--left.control-link(data-hint=\"Field options\" role=\"button\" tabindex=\"-1\")\n                    i.fa.fa-cog\n            a.field-action.hint--left.control-link(data-action=\"removeField\" data-hint=\"Delete this field\")\n                i.fa.fa-trash-o\n\n    else\n        label.label-display(for=id) #{label}\n\n.field-controls\n    if ratingType == '1-5'\n        .rating-options\n            for option in [1, 2, 3, 4, 5]\n                label.rating-option\n                    input.response-value(type='radio' name=id value=option)\n                    span= option\n    else\n        .rating-options\n            for option in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]\n                label.rating-option\n                    input.response-value(type='radio' name=id value=option)\n                    span= option\n        .rating-labels.row\n            .col-md-6.left\n                small.text-muted Not likely\n            .col-md-6.right.text-right\n                small.text-muted Very likely\n\n");
}
};