module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/step-actions/social.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (JSON, buttons, content, contentWithoutButtons, hasBetaSDK, isEditable, isFirst, isLast, leftButtons, rightButtons, showPoweredByText, stepNumber, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/step-actions/step-action.jade" ));
jade_mixins["stepButtons"] = jade_interp = function(stepButtons, defaultText, step, isEditable, showControls, buttonClasses, alignment, type, visible){
var block = (this && this.block), attributes = (this && this.attributes) || {};
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/step-actions/step-action.jade" ));
if ( isEditable && showControls && (!stepButtons || !stepButtons.length))
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n");
buf.push.apply(buf, jade_indent);
buf.push("<div data-action=\"addButton\" data-hint=\"Add button\"" + (jade.attr("data-alignment", alignment, true, true)) + (jade.attr("data-type", type, true, true)) + (jade.attr("data-default-text", defaultText, true, true)) + " class=\"add-icon hint--right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<i class=\"fa fa-plus\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/step-actions/step-action.jade" ));
// iterate stepButtons
;(function(){
  var $$obj = stepButtons;
  if ('number' == typeof $$obj.length) {

    for (var i = 0, $$l = $$obj.length; i < $$l; i++) {
      var stepButton = $$obj[i];

jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/step-actions/step-action.jade" ));
var stepButtonStyle = stepButton.get('style') ? JSON.parse(stepButton.get('style')) : ""
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/step-actions/step-action.jade" ));
var backgroundColor = stepButtonStyle && stepButtonStyle['background-color'] ? stepButtonStyle['background-color'] : "#FFFFFF"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/step-actions/step-action.jade" ));
var textColor = stepButtonStyle && stepButtonStyle['color'] ? stepButtonStyle['color'] : "#000000"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/step-actions/step-action.jade" ));
if(!visible){ stepButtonStyle += "display:none;"; }
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a" + (jade.attr("data-step", '' + (step) + '', true, true)) + (jade.attr("data-button-cid", stepButton.cid, true, true)) + (jade.attr("style", stepButtonStyle, true, true)) + (jade.cls(['appcues-button step-button ' + (buttonClass) + ''], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/step-actions/step-action.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<div" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + " contenteditable=\"true\"" + (jade.attr("data-default-text", '' + (defaultText) + '', true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/step-actions/step-action.jade" ));
if ( stepButton.get('text'))
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = stepButton.get('text')) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/step-actions/step-action.jade" ));
buf.push("" + (jade.escape((jade_interp = defaultText) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/step-actions/step-action.jade" ));
if ( stepButton.get('text'))
{
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = stepButton.get('text')) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/step-actions/step-action.jade" ));
buf.push("" + (jade.escape((jade_interp = defaultText) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/step-actions/step-action.jade" ));
if ( isEditable && showControls)
{
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n");
buf.push.apply(buf, jade_indent);
buf.push("<div class=\"hover-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + " data-color-attribute=\"background-color\" class=\"color-picker-button-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a title=\"Edit\"" + (jade.attr("data-color", backgroundColor, true, true)) + " class=\"hint--top color-picker-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<i class=\"fa fa-fw fa-square\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/step-actions/step-action.jade" ));
buf.push("&nbsp;Button Color");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span contenteditable class=\"editableValue\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = backgroundColor) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + " data-color-attribute=\"color\" class=\"color-picker-button-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a title=\"Edit\"" + (jade.attr("data-color", textColor, true, true)) + " class=\"hint--top color-picker-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<i class=\"fa fa-fw fa-font\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 37, "app/views/templates/step-actions/step-action.jade" ));
buf.push("&nbsp;Text Color");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span contenteditable class=\"editableValue\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = textColor) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a data-action=\"removeButton\" title=\"Remove\" class=\"hint--top\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 42, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 43, "app/views/templates/step-actions/step-action.jade" ));
buf.push("&nbsp;Remove");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var i in $$obj) {
      $$l++;      var stepButton = $$obj[i];

jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/step-actions/step-action.jade" ));
var stepButtonStyle = stepButton.get('style') ? JSON.parse(stepButton.get('style')) : ""
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/step-actions/step-action.jade" ));
var backgroundColor = stepButtonStyle && stepButtonStyle['background-color'] ? stepButtonStyle['background-color'] : "#FFFFFF"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/step-actions/step-action.jade" ));
var textColor = stepButtonStyle && stepButtonStyle['color'] ? stepButtonStyle['color'] : "#000000"
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/step-actions/step-action.jade" ));
if(!visible){ stepButtonStyle += "display:none;"; }
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a" + (jade.attr("data-step", '' + (step) + '', true, true)) + (jade.attr("data-button-cid", stepButton.cid, true, true)) + (jade.attr("style", stepButtonStyle, true, true)) + (jade.cls(['appcues-button step-button ' + (buttonClass) + ''], [true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/step-actions/step-action.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("<div" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + " contenteditable=\"true\"" + (jade.attr("data-default-text", '' + (defaultText) + '', true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/step-actions/step-action.jade" ));
if ( stepButton.get('text'))
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = stepButton.get('text')) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/step-actions/step-action.jade" ));
buf.push("" + (jade.escape((jade_interp = defaultText) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  ");
buf.push.apply(buf, jade_indent);
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/step-actions/step-action.jade" ));
if ( stepButton.get('text'))
{
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = stepButton.get('text')) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/step-actions/step-action.jade" ));
buf.push("" + (jade.escape((jade_interp = defaultText) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/step-actions/step-action.jade" ));
if ( isEditable && showControls)
{
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n");
buf.push.apply(buf, jade_indent);
buf.push("<div class=\"hover-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + " data-color-attribute=\"background-color\" class=\"color-picker-button-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a title=\"Edit\"" + (jade.attr("data-color", backgroundColor, true, true)) + " class=\"hint--top color-picker-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<i class=\"fa fa-fw fa-square\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/step-actions/step-action.jade" ));
buf.push("&nbsp;Button Color");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span contenteditable class=\"editableValue\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = backgroundColor) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + " data-color-attribute=\"color\" class=\"color-picker-button-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a title=\"Edit\"" + (jade.attr("data-color", textColor, true, true)) + " class=\"hint--top color-picker-button\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<i class=\"fa fa-fw fa-font\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 37, "app/views/templates/step-actions/step-action.jade" ));
buf.push("&nbsp;Text Color");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span contenteditable class=\"editableValue\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 39, "app/views/templates/step-actions/step-action.jade" ));
buf.push(jade.escape(null == (jade_interp = textColor) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 40, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span" + (jade.attr("data-button-cid", stepButton.cid, true, true)) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a data-action=\"removeButton\" title=\"Remove\" class=\"hint--top\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 42, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 43, "app/views/templates/step-actions/step-action.jade" ));
buf.push("&nbsp;Remove");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
};
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 45, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n<section class=\"step-action-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-actions/social.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/step-actions/social.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/step-actions/social.jade" ));
buf.push("\n  <textarea class=\"redactor\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/step-actions/social.jade" ));
if ( contentWithoutButtons)
{
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/step-actions/social.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/step-actions/social.jade" ));
buf.push(null == (jade_interp = contentWithoutButtons) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/step-actions/social.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/step-actions/social.jade" ));
buf.push("<img src=\"/images/placeholders/image-placeholder.jpg\" width=\"100%\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/step-actions/social.jade" ));
buf.push("\n    <hr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/step-actions/social.jade" ));
buf.push("\n    <h4 style=\"text-align: center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("[Insert your call to action]");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/step-actions/social.jade" ));
buf.push("\n    <p style=\"text-align: center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/step-actions/social.jade" ));
buf.push("Write a quick sentence or two about why someone should share your content. Getting someone to share on social media is a big step, so try to convince them it's worthwhile.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </textarea>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/step-actions/social.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/step-actions/social-buttons.jade" ));
if ( buttons)
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/step-actions/social-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/step-actions/social-buttons.jade" ));
buf.push("\n  <div class=\"appcues-social-buttons\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/social-buttons.jade" ));
// iterate buttons
;(function(){
  var $$obj = buttons;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var button = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/social-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-actions/social-buttons.jade" ));
buf.push("<a" + (jade.attr("href", button.link, true, true)) + " target=\"_blank\"" + (jade.cls(['appcues-social-button',button.className], [null,true])) + ">" + (jade.escape(null == (jade_interp = button.text) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var button = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/step-actions/social-buttons.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/step-actions/social-buttons.jade" ));
buf.push("<a" + (jade.attr("href", button.link, true, true)) + " target=\"_blank\"" + (jade.cls(['appcues-social-button',button.className], [null,true])) + ">" + (jade.escape(null == (jade_interp = button.text) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/step-actions/social.jade" ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/step-actions/social.jade" ));
buf.push(null == (jade_interp = content) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n</section>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 51, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 52, "app/views/templates/step-actions/step-action.jade" ));
var editableClass = isEditable ? 'editable-buttons' : ''
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 53, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n<div" + (jade.attr("data-step-number", stepNumber, true, true)) + (jade.cls(['appcues-actions','step-action-buttons',editableClass], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 54, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 55, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n    <div class=\"col-xs-6 text-left appcues-actions-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 57, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 58, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 58, "app/views/templates/step-actions/step-action.jade" ));
var defaultText = 'Back'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 59, "app/views/templates/step-actions/step-action.jade" ));
var step = 'prev'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 60, "app/views/templates/step-actions/step-action.jade" ));
var buttonClass = ''
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 61, "app/views/templates/step-actions/step-action.jade" ));
var alignment = 'LEFT'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 62, "app/views/templates/step-actions/step-action.jade" ));
var type = 'BACK'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 63, "app/views/templates/step-actions/step-action.jade" ));
var visible = !isFirst
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 65, "app/views/templates/step-actions/step-action.jade" ));
if ( leftButtons)
{
jade_debug.unshift(new jade.DebugItem( 66, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 66, "app/views/templates/step-actions/step-action.jade" ));
jade_indent.push('      ');
jade_mixins["stepButtons"](leftButtons, defaultText, step, isEditable, hasBetaSDK, buttonClass, alignment, type, visible);
jade_indent.pop();
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 68, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n    <div class=\"col-xs-6 text-right appcues-actions-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 69, "app/views/templates/step-actions/step-action.jade" ));
var step = isLast ? 'end' : 'next'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 70, "app/views/templates/step-actions/step-action.jade" ));
var defaultText = isLast ? 'OK, got it' : 'Next'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 71, "app/views/templates/step-actions/step-action.jade" ));
var buttonClass = 'appcues-button-success'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 72, "app/views/templates/step-actions/step-action.jade" ));
var alignment = 'RIGHT'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 73, "app/views/templates/step-actions/step-action.jade" ));
var type = 'NEXT'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 74, "app/views/templates/step-actions/step-action.jade" ));
var visible = true
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 76, "app/views/templates/step-actions/step-action.jade" ));
if ( rightButtons)
{
jade_debug.unshift(new jade.DebugItem( 77, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 77, "app/views/templates/step-actions/step-action.jade" ));
jade_indent.push('      ');
jade_mixins["stepButtons"](rightButtons, defaultText, step, isEditable, hasBetaSDK, buttonClass, alignment, type, visible);
jade_indent.pop();
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 78, "app/views/templates/step-actions/step-action.jade" ));
if ( showPoweredByText)
{
jade_debug.unshift(new jade.DebugItem( 79, "app/views/templates/step-actions/step-action.jade" ));
jade_debug.unshift(new jade.DebugItem( 79, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 80, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n    <div class=\"col-xs-12 appcues-powered-by-text\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 81, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<a href=\"https://my.appcues.com/subscription\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 82, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n        <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 35.71 45.567\" width=\"10px\" height=\"10px\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 83, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n          <polygon style=\"fill: #bebebe\" points=\"15.939 25.197 28.904 45.567 35.71 45.567 35.71 0 15.939 25.197\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</polygon>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 84, "app/views/templates/step-actions/step-action.jade" ));
buf.push("\n          <polygon style=\"fill: #bebebe\" points=\"0 45.567 12.516 45.567 12.516 29.466 0 45.567\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</polygon>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </svg>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 85, "app/views/templates/step-actions/step-action.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 85, jade_debug[0].filename ));
buf.push("Powered By Appcues");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();}.call(this,"JSON" in locals_for_with?locals_for_with.JSON:typeof JSON!=="undefined"?JSON:undefined,"buttons" in locals_for_with?locals_for_with.buttons:typeof buttons!=="undefined"?buttons:undefined,"content" in locals_for_with?locals_for_with.content:typeof content!=="undefined"?content:undefined,"contentWithoutButtons" in locals_for_with?locals_for_with.contentWithoutButtons:typeof contentWithoutButtons!=="undefined"?contentWithoutButtons:undefined,"hasBetaSDK" in locals_for_with?locals_for_with.hasBetaSDK:typeof hasBetaSDK!=="undefined"?hasBetaSDK:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"isFirst" in locals_for_with?locals_for_with.isFirst:typeof isFirst!=="undefined"?isFirst:undefined,"isLast" in locals_for_with?locals_for_with.isLast:typeof isLast!=="undefined"?isLast:undefined,"leftButtons" in locals_for_with?locals_for_with.leftButtons:typeof leftButtons!=="undefined"?leftButtons:undefined,"rightButtons" in locals_for_with?locals_for_with.rightButtons:typeof rightButtons!=="undefined"?rightButtons:undefined,"showPoweredByText" in locals_for_with?locals_for_with.showPoweredByText:typeof showPoweredByText!=="undefined"?showPoweredByText:undefined,"stepNumber" in locals_for_with?locals_for_with.stepNumber:typeof stepNumber!=="undefined"?stepNumber:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends step-action\n\nblock content\n    if isEditable\n        textarea.redactor\n            if contentWithoutButtons\n                != contentWithoutButtons\n            else\n                img(src='/images/placeholders/image-placeholder.jpg', width='100%')\n\n                hr\n\n                h4(style='text-align: center') [Insert your call to action]\n                p(style='text-align: center')\n                    | Write a quick sentence or two about why someone should share your content. Getting someone to share on social media is a big step, so try to convince them it's worthwhile.\n\n        include social-buttons\n\n    else\n        != content\n\n");
}
};