module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-frequency.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (rule) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("\n<div class=\"radio\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("\n  <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("\n    <input type=\"radio\" name=\"frequency\" value=\"once\"" + (jade.attr("checked", (rule.frequency=='once'), true, true)) + (jade.attr("disabled", rule.published, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("Show only on the next visit to a matching page");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("\n<div class=\"radio\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("\n  <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("\n    <input type=\"radio\" name=\"frequency\" value=\"every_time\"" + (jade.attr("checked", (rule.frequency=='every_time'), true, true)) + (jade.attr("disabled", rule.published, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/settings/rule-frequency.jade" ));
buf.push("Show on every visit to matching pages");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"rule" in locals_for_with?locals_for_with.rule:typeof rule!=="undefined"?rule:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".radio\n    label\n        input(type=\"radio\" name=\"frequency\" value=\"once\" checked=(rule.frequency=='once') disabled=rule.published)\n        span\n        | Show only on the next visit to a matching page\n\n.radio\n    label\n        input(type=\"radio\" name=\"frequency\" value=\"every_time\" checked=(rule.frequency=='every_time') disabled=rule.published)\n        span\n        | Show on every visit to matching pages\n\n");
}
};