module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/steps.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (sandbox, skippable) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/steps.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/steps.jade" ));
if ( !sandbox)
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/steps.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/steps.jade" ));
buf.push("\n<div class=\"preview-area\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/steps.jade" ));
buf.push("\n  <div class=\"mask\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/steps.jade" ));
buf.push("\n  <div class=\"flow-steps\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/steps.jade" ));
if ( skippable)
{
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/steps.jade" ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/steps.jade" ));
buf.push("\n    <div class=\"appcues-skip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/steps.jade" ));
buf.push("<a data-step=\"skip\" title=\"Skip\" class=\"skip\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/steps.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/steps.jade" ));
buf.push("\n<div class=\"preview-area sandboxed\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"sandbox" in locals_for_with?locals_for_with.sandbox:typeof sandbox!=="undefined"?sandbox:undefined,"skippable" in locals_for_with?locals_for_with.skippable:typeof skippable!=="undefined"?skippable:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "if !sandbox\n    .preview-area\n            .mask\n            .flow-steps\n                if skippable\n                    .appcues-skip\n                        a.skip(data-step=\"skip\" title='Skip') &times;\nelse\n    .preview-area.sandboxed\n");
}
};