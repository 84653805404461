module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/alerts/alert.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (buttonText, content, title, type) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/alerts/alert.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/alerts/alert.jade" ));
buf.push("\n<div" + (jade.cls(['modal-dialog','modal-center',"modal-" + (type || 'default') + ""], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/alerts/alert.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/alerts/alert.jade" ));
buf.push("\n    <div class=\"modal-body text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/alerts/alert.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/alerts/alert.jade" ));
if ( title)
{
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/alerts/alert.jade" ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/alerts/alert.jade" ));
buf.push("\n      <h4>" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/alerts/alert.jade" ));
if ( content)
{
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/alerts/alert.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/alerts/alert.jade" ));
buf.push(null == (jade_interp = content) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/alerts/alert.jade" ));
buf.push("\n    <div class=\"modal-footer text-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/alerts/alert.jade" ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/alerts/alert.jade" ));
var btnText = buttonText || 'Ok, got it'
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/alerts/alert.jade" ));
buf.push("\n      <button data-event=\"confirm\" type=\"button\" class=\"btn btn-link\">" + (jade.escape(null == (jade_interp = btnText) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"buttonText" in locals_for_with?locals_for_with.buttonText:typeof buttonText!=="undefined"?buttonText:undefined,"content" in locals_for_with?locals_for_with.content:typeof content!=="undefined"?content:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".modal-dialog.modal-center(class=\"modal-#{type || 'default'}\")\n    .modal-content\n        .modal-body.text-center\n            block body\n                if title\n                    h4= title\n\n                if content\n                    != content\n\n        .modal-footer.text-right\n            block footer\n                - var btnText = buttonText || 'Ok, got it'\n                button.btn.btn-link(data-event='confirm', type='button')= btnText\n");
}
};