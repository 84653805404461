module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/components/default.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/components/default.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/components/default.jade" ));
buf.push("\n<div class=\"component-choices appcues-style\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/components/default.jade" ));
buf.push("\n  <div class=\"component-choice\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/components/default.jade" ));
buf.push("<a data-action=\"setType\" data-component-type=\"editor\" class=\"control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/components/default.jade" ));
buf.push("<i class=\"icon-pen\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/components/default.jade" ));
buf.push("Text");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/components/default.jade" ));
buf.push("\n  <div class=\"component-choice\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/components/default.jade" ));
buf.push("<a data-action=\"setType\" data-component-type=\"image\" class=\"control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/components/default.jade" ));
buf.push("<i class=\"icon-image-outline\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/components/default.jade" ));
buf.push("Image");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/components/default.jade" ));
buf.push("\n  <div class=\"component-choice\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/components/default.jade" ));
buf.push("<a data-action=\"setType\" data-component-type=\"video\" class=\"control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/components/default.jade" ));
buf.push("<i class=\"icon-film\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/components/default.jade" ));
buf.push("Video");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/components/default.jade" ));
buf.push("\n  <div class=\"component-choice\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/components/default.jade" ));
buf.push("<a data-action=\"setType\" data-component-type=\"form\" class=\"control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/components/default.jade" ));
buf.push("<i class=\"icon-clipboard\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/components/default.jade" ));
buf.push("Form");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/components/default.jade" ));
buf.push("\n  <!--.component-choice");
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("\n  ");
jade_debug.unshift(new jade.DebugItem( 23, jade_debug[0].filename ));
buf.push("//a.control-link(data-action='setType' data-component-type='social')");
jade_debug.shift();
buf.push("\n  ");
jade_debug.unshift(new jade.DebugItem( 23, jade_debug[0].filename ));
buf.push("    //i.icon-message");
jade_debug.shift();
buf.push("\n  ");
jade_debug.unshift(new jade.DebugItem( 23, jade_debug[0].filename ));
buf.push("    //| Social");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  -->");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".component-choices.appcues-style\n    .component-choice\n        a.control-link(data-action='setType' data-component-type='editor')\n            i.icon-pen\n            | Text\n    .component-choice\n        a.control-link(data-action='setType' data-component-type='image')\n            i.icon-image-outline\n            | Image\n    .component-choice\n        a.control-link(data-action='setType' data-component-type='video')\n            i.icon-film\n            | Video\n    .component-choice\n        a.control-link(data-action='setType' data-component-type='form')\n            i.icon-clipboard\n            | Form\n    //.component-choice\n        //a.control-link(data-action='setType' data-component-type='social')\n            //i.icon-message\n            //| Social\n\n");
}
};