module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/components/hero.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (Math, heading, rawImageUrl, rendered, subheading, uploadPercent, uploadedImageUrl, uploading) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/components/hero.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/components/hero.jade" ));
if ( uploading)
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/components/hero.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/components/hero.jade" ));
buf.push("\n<div class=\"uploading\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/components/hero.jade" ));
buf.push("\n  <div class=\"progress\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/components/hero.jade" ));
buf.push("\n    <div" + (jade.attr("style", "width: " + (uploadPercent) + "%", true, true)) + " class=\"progress-bar progress-bar-primary progress-bar-striped active\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/components/hero.jade" ));
buf.push("\n  <div class=\"status\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/components/hero.jade" ));
if ( uploadPercent === 100)
{
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/components/hero.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/components/hero.jade" ));
buf.push("Processing&hellip;");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/components/hero.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/components/hero.jade" ));
buf.push("Uploading " + (jade.escape((jade_interp = Math.round(uploadPercent)) == null ? '' : jade_interp)) + "%");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
else if ( rawImageUrl || uploadedImageUrl)
{
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/components/hero.jade" ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/components/hero.jade" ));
buf.push("\n<div class=\"preview\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/components/hero.jade" ));
buf.push("\n  <div" + (jade.attr("style", 'background-image: url(' + (rawImageUrl || uploadedImageUrl) + ')', true, true)) + " class=\"apc-hero\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/components/hero.jade" ));
buf.push("\n    <h1 name=\"heading\" placeholder=\"Add Your Headline Here\" contenteditable>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/components/hero.jade" ));
buf.push(jade.escape(null == (jade_interp = heading) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </h1>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/components/hero.jade" ));
buf.push("\n    <h2 name=\"subheading\" placeholder=\"Enter a punchy subheadline in this area.\" contenteditable>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/components/hero.jade" ));
buf.push(jade.escape(null == (jade_interp = subheading) ? "" : jade_interp));
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </h2>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/components/hero.jade" ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/components/hero.jade" ));
buf.push("\n<form class=\"drop-area\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/components/hero.jade" ));
buf.push("\n  <div class=\"message\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("Drag an image here");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/components/hero.jade" ));
buf.push("\n  <div class=\"divider\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, jade_debug[0].filename ));
buf.push("or");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/components/hero.jade" ));
buf.push("<a class=\"btn btn-default dropzone-btn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, jade_debug[0].filename ));
buf.push("Choose a file");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</form>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/components/hero.jade" ));
buf.push("\n<div" + (jade.cls(['controls','clearfix',(rendered ? 'no-animation' : '')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/components/hero.jade" ));
buf.push("<a data-action=\"delete\" class=\"appcues-style btn btn-link btn-sm control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/components/hero.jade" ));
buf.push("<a data-action=\"saveAndClose\" class=\"appcues-style btn btn-primary btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, jade_debug[0].filename ));
buf.push("Save");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/components/hero.jade" ));
buf.push("<a data-action=\"close\" class=\"appcues-style btn btn-default btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 27, jade_debug[0].filename ));
buf.push("Cancel");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/components/hero.jade" ));
if ( uploadedImageUrl)
{
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/components/hero.jade" ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/components/hero.jade" ));
buf.push("<a data-action=\"resetForm\" class=\"appcues-style btn btn-link btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("Reset");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"Math" in locals_for_with?locals_for_with.Math:typeof Math!=="undefined"?Math:undefined,"heading" in locals_for_with?locals_for_with.heading:typeof heading!=="undefined"?heading:undefined,"rawImageUrl" in locals_for_with?locals_for_with.rawImageUrl:typeof rawImageUrl!=="undefined"?rawImageUrl:undefined,"rendered" in locals_for_with?locals_for_with.rendered:typeof rendered!=="undefined"?rendered:undefined,"subheading" in locals_for_with?locals_for_with.subheading:typeof subheading!=="undefined"?subheading:undefined,"uploadPercent" in locals_for_with?locals_for_with.uploadPercent:typeof uploadPercent!=="undefined"?uploadPercent:undefined,"uploadedImageUrl" in locals_for_with?locals_for_with.uploadedImageUrl:typeof uploadedImageUrl!=="undefined"?uploadedImageUrl:undefined,"uploading" in locals_for_with?locals_for_with.uploading:typeof uploading!=="undefined"?uploading:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "if uploading\n    .uploading\n        .progress\n            .progress-bar.progress-bar-primary.progress-bar-striped.active(style=\"width: #{uploadPercent}%\")\n        .status\n            if uploadPercent === 100\n                | Processing&hellip;\n            else\n                | Uploading #{Math.round(uploadPercent)}%\n\nelse if rawImageUrl || uploadedImageUrl\n    .preview\n        div.apc-hero(style='background-image: url(#{rawImageUrl || uploadedImageUrl})')\n            h1(name='heading', placeholder='Add Your Headline Here', contenteditable)\n                = heading\n            h2(name='subheading', placeholder='Enter a punchy subheadline in this area.', contenteditable)\n                = subheading\nelse\n    form.drop-area\n        .message Drag an image here\n        .divider or\n        a.btn.btn-default.dropzone-btn Choose a file\n\ndiv.controls.clearfix(class=(rendered ? 'no-animation' : ''))\n    a.appcues-style.btn.btn-link.btn-sm.control-link(data-action='delete'): i.fa.fa-trash\n    a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save\n    a.appcues-style.btn.btn-default.btn-sm.pull-right(data-action='close') Cancel\n    if uploadedImageUrl\n        a.appcues-style.btn.btn-link.btn-sm.pull-right(data-action='resetForm') Reset\n");
}
};