module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/design/flow/steps-summary.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (isEditable, selectedId, steps, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("\n<ul class=\"thumbnails-list thumbnails-list-sequence list-group list-inline\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( steps)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
// iterate steps
;(function(){
  var $$obj = steps;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var step = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
var isSelected = step.id == selectedId
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("\n  <li" + (jade.attr("data-id", step.id, true, true)) + (jade.cls(['thumbnail-item','list-group-item','draggable-list-group-item','sequential','text-center',(isSelected ? 'selected ' : '')], [null,null,null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<img" + (jade.attr("src", "/images/steps/" + (step.stepType || 'default') + ".png", true, true)) + " width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("\n    <div class=\"thumbnail-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<a data-action=\"showStepInfo\"" + (jade.attr("data-step-id", step.id, true, true)) + " class=\"step-info\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-info\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-eye\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( steps.length > 1)
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<a data-action=\"deleteStep\" class=\"delete-step\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var step = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
var isSelected = step.id == selectedId
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("\n  <li" + (jade.attr("data-id", step.id, true, true)) + (jade.cls(['thumbnail-item','list-group-item','draggable-list-group-item','sequential','text-center',(isSelected ? 'selected ' : '')], [null,null,null,null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<img" + (jade.attr("src", "/images/steps/" + (step.stepType || 'default') + ".png", true, true)) + " width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("\n    <div class=\"thumbnail-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<a data-action=\"showStepInfo\"" + (jade.attr("data-step-id", step.id, true, true)) + " class=\"step-info\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-info\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-eye\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( steps.length > 1)
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<a data-action=\"deleteStep\" class=\"delete-step\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<i class=\"fa fa-fw fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("\n  <li class=\"thumbnail-item list-group-item dashed add-step text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/build-steps/design/flow/steps-summary.jade" ));
buf.push("<img src=\"/images/steps/plus.png\" width=\"70\" height=\"70\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</li>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</ul>");
jade_debug.shift();
jade_debug.shift();}.call(this,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"selectedId" in locals_for_with?locals_for_with.selectedId:typeof selectedId!=="undefined"?selectedId:undefined,"steps" in locals_for_with?locals_for_with.steps:typeof steps!=="undefined"?steps:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "ul.thumbnails-list.thumbnails-list-sequence.list-group.list-inline\n    if steps\n        each step in steps\n            - var isSelected = step.id == selectedId\n            li.thumbnail-item.list-group-item.draggable-list-group-item.sequential.text-center(class=(isSelected ? 'selected ' : '') data-id=step.id)\n                img(src=\"/images/steps/#{step.stepType || 'default'}.png\", width='70', height='70')\n\n                .thumbnail-controls\n                    a.step-info(data-action='showStepInfo' data-step-id=step.id)\n                        if isEditable\n                            i.fa.fa-fw.fa-info\n                        else\n                            i.fa.fa-fw.fa-eye\n\n                    if steps.length > 1\n                        if isEditable\n                            a.delete-step(data-action='deleteStep')\n                                i.fa.fa-fw.fa-trash\n\n    if isEditable\n        li.thumbnail-item.list-group-item.dashed.add-step.text-center\n            img(src=\"/images/steps/plus.png\", width='70', height='70')\n");
}
};