module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/form-fields/field-options.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (fieldType, ratingType, required, validationType) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/form-fields/field-options.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n<div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n  <div data-toggle=\"buttons\" class=\"btn-group-vertical btn-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(required ? 'btn-default' : 'btn-primary')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/form-fields/field-options.jade" ));
buf.push("Optional");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"required\" value=\"false\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(required ? 'btn-primary' : 'btn-default')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/form-fields/field-options.jade" ));
buf.push("Required");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"required\" value=\"true\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/form-fields/field-options.jade" ));
if ( fieldType == 'text')
{
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/form-fields/field-options.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n<hr style=\"margin: 5px 0\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n<div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n  <div data-toggle=\"buttons\" class=\"btn-group-vertical btn-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(['email', 'url', 'number'].indexOf(validationType) == -1 ? 'btn-primary' : 'btn-default')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/form-fields/field-options.jade" ));
buf.push("Text");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"validationType\" value=\"text\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(validationType == 'email' ? 'btn-primary' : 'btn-default')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/form-fields/field-options.jade" ));
buf.push("Email");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"validationType\" value=\"email\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(validationType == 'url' ? 'btn-primary' : 'btn-default')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/form-fields/field-options.jade" ));
buf.push("URL");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"validationType\" value=\"url\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(validationType == 'number' ? 'btn-primary' : 'btn-default')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/form-fields/field-options.jade" ));
buf.push("Number");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"validationType\" value=\"number\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
else if ( fieldType == 'rating')
{
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/form-fields/field-options.jade" ));
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n<hr style=\"margin: 5px 0\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n<div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n  <div data-toggle=\"buttons\" class=\"btn-group-vertical btn-block\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(ratingType == '1-5' ? 'btn-default' : 'btn-primary')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/form-fields/field-options.jade" ));
buf.push("0-10");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"ratingType\" value=\"0-10\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n    <label" + (jade.cls(['btn','btn-block',(ratingType == '1-5' ? 'btn-primary' : 'btn-default')], [null,null,true])) + ">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/form-fields/field-options.jade" ));
buf.push("1-5");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/form-fields/field-options.jade" ));
buf.push("\n      <input type=\"radio\" name=\"ratingType\" value=\"1-5\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </label>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"fieldType" in locals_for_with?locals_for_with.fieldType:typeof fieldType!=="undefined"?fieldType:undefined,"ratingType" in locals_for_with?locals_for_with.ratingType:typeof ratingType!=="undefined"?ratingType:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined,"validationType" in locals_for_with?locals_for_with.validationType:typeof validationType!=="undefined"?validationType:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "div\n    .btn-group-vertical.btn-block(data-toggle='buttons')\n        label.btn.btn-block(class=(required ? 'btn-default' : 'btn-primary'))\n            | Optional\n            input(type='radio' name='required' value='false')\n        label.btn.btn-block(class=(required ? 'btn-primary' : 'btn-default'))\n            | Required\n            input(type='radio' name='required' value='true')\nif fieldType == 'text'\n    hr(style=\"margin: 5px 0\")\n    div\n        .btn-group-vertical.btn-block(data-toggle='buttons')\n            label.btn.btn-block(class=(['email', 'url', 'number'].indexOf(validationType) == -1 ? 'btn-primary' : 'btn-default'))\n                | Text\n                input(type='radio' name='validationType' value='text')\n            label.btn.btn-block(class=(validationType == 'email' ? 'btn-primary' : 'btn-default'))\n                | Email\n                input(type='radio' name='validationType' value='email')\n            label.btn.btn-block(class=(validationType == 'url' ? 'btn-primary' : 'btn-default'))\n                | URL\n                input(type='radio' name='validationType' value='url')\n            label.btn.btn-block(class=(validationType == 'number' ? 'btn-primary' : 'btn-default'))\n                | Number\n                input(type='radio' name='validationType' value='number')\nelse if fieldType == 'rating'\n    hr(style=\"margin: 5px 0\")\n    div\n        .btn-group-vertical.btn-block(data-toggle='buttons')\n            label.btn.btn-block(class=(ratingType == '1-5' ? 'btn-default' : 'btn-primary'))\n                | 0-10\n                input(type='radio' name='ratingType' value='0-10')\n            label.btn.btn-block(class=(ratingType == '1-5' ? 'btn-primary' : 'btn-default'))\n                | 1-5\n                input(type='radio' name='ratingType' value='1-5')\n");
}
};