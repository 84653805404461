module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/quickstart.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/quickstart.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/quickstart.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/quickstart.jade" ));
buf.push("\n  <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/quickstart.jade" ));
buf.push("\n    <div class=\"col-md-6 col-md-offset-3\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/quickstart.jade" ));
buf.push("\n      <div class=\"quickstart-page text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/quickstart.jade" ));
buf.push("<a href=\"http://www.appcues.com\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/quickstart.jade" ));
buf.push("<img src=\"/images/logo-white.png\" height=\"40\" class=\"logo\">");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".container\n    .row\n        .col-md-6.col-md-offset-3\n\n            .quickstart-page.text-center\n                a(href='http://www.appcues.com')\n                    img.logo(src='/images/logo-white.png', height='40')\n");
}
};