module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/manage-category.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (categories, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/manage-category.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/manage-category.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/manage-category.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/manage-category.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/manage-category.jade" ));
buf.push("<a data-dismiss=\"modal\" class=\"close\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/manage-category.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Manage Your Categories");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/manage-category.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/manage-category.jade" ));
buf.push("\n      <form class=\"form-horizontal\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/manage-category.jade" ));
// iterate categories
;(function(){
  var $$obj = categories;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var category = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/manage-category.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/manage-category.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/manage-category.jade" ));
buf.push("\n          <div class=\"col-sm-11\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/manage-category.jade" ));
buf.push("\n            <input name=\"categoryName\"" + (jade.attr("value", category.name, true, true)) + (jade.attr("defaultValue", category.name, true, true)) + (jade.attr("id", category.id, true, true)) + " placeholder=\"Enter a name...\" class=\"form-control category-name\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/manage-category.jade" ));
buf.push("\n          <div class=\"col-sm-1\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/manage-category.jade" ));
buf.push("<a data-action=\"removeCategory\"" + (jade.attr("data-id", category.id, true, true)) + " data-hint=\"Remove this category\" style=\"margin-left: -1em\" class=\"btn btn-link remove-category hint--left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/manage-category.jade" ));
buf.push("<i class=\"fa fa-fw fa-lg fa-times\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var category = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/manage-category.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/manage-category.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/manage-category.jade" ));
buf.push("\n          <div class=\"col-sm-11\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/manage-category.jade" ));
buf.push("\n            <input name=\"categoryName\"" + (jade.attr("value", category.name, true, true)) + (jade.attr("defaultValue", category.name, true, true)) + (jade.attr("id", category.id, true, true)) + " placeholder=\"Enter a name...\" class=\"form-control category-name\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/manage-category.jade" ));
buf.push("\n          <div class=\"col-sm-1\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/manage-category.jade" ));
buf.push("<a data-action=\"removeCategory\"" + (jade.attr("data-id", category.id, true, true)) + " data-hint=\"Remove this category\" style=\"margin-left: -1em\" class=\"btn btn-link remove-category hint--left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/manage-category.jade" ));
buf.push("<i class=\"fa fa-fw fa-lg fa-times\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n      </form>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/manage-category.jade" ));
buf.push("\n      <div>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/manage-category.jade" ));
buf.push("<a data-action=\"newCategory\" class=\"btn btn-success newCategory\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, jade_debug[0].filename ));
buf.push("Add Category");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/manage-category.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/manage-category.jade" ));
buf.push("<a data-action=\"done\" class=\"btn btn-primary pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("Done");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"categories" in locals_for_with?locals_for_with.categories:typeof categories!=="undefined"?categories:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".modal-dialog\n    .modal-content\n        .modal-header\n            a.close(data-dismiss=\"modal\") &times;\n            h4.modal-title Manage Your Categories\n\n        .modal-body\n            form.form-horizontal\n                for category in categories\n                    .form-group\n                        .col-sm-11\n                            input.form-control.category-name(name='categoryName' value=category.name defaultValue=category.name id=category.id placeholder='Enter a name...')\n                        .col-sm-1\n                            a.btn.btn-link.remove-category.hint--left(data-action=\"removeCategory\" data-id=category.id data-hint='Remove this category' style=\"margin-left: -1em\")\n                                i.fa.fa-fw.fa-lg.fa-times\n            div\n                a.btn.btn-success.newCategory(data-action=\"newCategory\") Add Category\n\n        .modal-footer\n            a.btn.btn-primary.pull-right(data-action=\"done\") Done\n");
}
};