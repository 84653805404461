module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/property-filters.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (accountId, hasProperties, hasValidFilters, isPublished, operator, operatorDisplay, properties, undefined, value, valueDisplay) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/property-filters.jade" ));
buf.push("\n<h4>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Which users should see this?");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/property-filters.jade" ));
if ( isPublished)
{
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/property-filters.jade" ));
if ( hasValidFilters)
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/property-filters.jade" ));
buf.push("\n<div class=\"properties-summary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/property-filters.jade" ));
// iterate properties
;(function(){
  var $$obj = properties;
  if ('number' == typeof $$obj.length) {

    for (var k = 0, $$l = $$obj.length; k < $$l; k++) {
      var v = $$obj[k];

jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/property-filters.jade" ));
operator = operatorDisplay(k, v)
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/property-filters.jade" ));
value = valueDisplay(k, v)
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/property-filters.jade" ));
buf.push("\n  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/property-filters.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = k) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/property-filters.jade" ));
buf.push("&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/property-filters.jade" ));
buf.push("<span>" + (jade.escape(null == (jade_interp = operator) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/property-filters.jade" ));
buf.push("&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/property-filters.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var k in $$obj) {
      $$l++;      var v = $$obj[k];

jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/property-filters.jade" ));
operator = operatorDisplay(k, v)
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/property-filters.jade" ));
value = valueDisplay(k, v)
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/property-filters.jade" ));
buf.push("\n  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/property-filters.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = k) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/property-filters.jade" ));
buf.push("&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/property-filters.jade" ));
buf.push("<span>" + (jade.escape(null == (jade_interp = operator) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/property-filters.jade" ));
buf.push("&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/property-filters.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = value) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/property-filters.jade" ));
buf.push("\n<p class=\"no-filters\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/property-filters.jade" ));
buf.push("All users");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/property-filters.jade" ));
if (!( isPublished))
{
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/property-filters.jade" ));
buf.push("or&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/property-filters.jade" ));
buf.push("<a class=\"add-rule-property\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("add a filter");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/property-filters.jade" ));
if ( hasProperties || hasValidFilters)
{
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/property-filters.jade" ));
buf.push("\n<form class=\"properties\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</form>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/property-filters.jade" ));
if (!( hasValidFilters))
{
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/property-filters.jade" ));
buf.push("\n<p class=\"no-filters\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/property-filters.jade" ));
buf.push("All users or&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/property-filters.jade" ));
buf.push("<a class=\"add-rule-property\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("add a filter");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/property-filters.jade" ));
if (!( isPublished))
{
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/property-filters.jade" ));
buf.push("<a class=\"add-rule-property btn btn-primary hidden\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, jade_debug[0].filename ));
buf.push("Add a filter");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/property-filters.jade" ));
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/property-filters.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/property-filters.jade" ));
buf.push("You haven't installed the Appcues embed script yet. Once you do, all of your user properties will automatically appear here for you to create segments. View your personalized installation instructions&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 37, "app/views/templates/property-filters.jade" ));
buf.push("<a" + (jade.attr("href", "/install/a/" + (accountId) + "", true, true)) + " target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 37, jade_debug[0].filename ));
buf.push("here");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 38, "app/views/templates/property-filters.jade" ));
buf.push(".");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"accountId" in locals_for_with?locals_for_with.accountId:typeof accountId!=="undefined"?accountId:undefined,"hasProperties" in locals_for_with?locals_for_with.hasProperties:typeof hasProperties!=="undefined"?hasProperties:undefined,"hasValidFilters" in locals_for_with?locals_for_with.hasValidFilters:typeof hasValidFilters!=="undefined"?hasValidFilters:undefined,"isPublished" in locals_for_with?locals_for_with.isPublished:typeof isPublished!=="undefined"?isPublished:undefined,"operator" in locals_for_with?locals_for_with.operator:typeof operator!=="undefined"?operator:undefined,"operatorDisplay" in locals_for_with?locals_for_with.operatorDisplay:typeof operatorDisplay!=="undefined"?operatorDisplay:undefined,"properties" in locals_for_with?locals_for_with.properties:typeof properties!=="undefined"?properties:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined,"value" in locals_for_with?locals_for_with.value:typeof value!=="undefined"?value:undefined,"valueDisplay" in locals_for_with?locals_for_with.valueDisplay:typeof valueDisplay!=="undefined"?valueDisplay:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "h4 Which users should see this?\n\nif isPublished\n    if hasValidFilters\n        .properties-summary\n            each v, k in properties\n                - operator = operatorDisplay(k, v)\n                - value = valueDisplay(k, v)\n                p\n                    strong= k\n                    | &nbsp;\n                    span= operator\n                    | &nbsp;\n                    strong= value\n    else\n        p.no-filters\n            | All users\n            unless isPublished\n                | or&nbsp;\n                a.add-rule-property add a filter\n\nelse\n    if hasProperties || hasValidFilters\n        form.properties\n\n        unless hasValidFilters\n            p.no-filters\n                | All users or&nbsp;\n                a.add-rule-property add a filter\n\n        unless isPublished\n            a.add-rule-property.btn.btn-primary.hidden Add a filter\n\n    else\n        p\n            | You haven't installed the Appcues embed script yet. Once you do, all of your user properties will automatically appear here for you to create segments. View your personalized installation instructions&nbsp;\n            a(href=\"/install/a/#{accountId}\", target='_blank') here\n            | .\n");
}
};