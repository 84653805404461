module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/content-list/content-list.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (hasContent, isFiltered, loading) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/content-list/content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/content-list/content-list.jade" ));
if ( loading)
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/content-list/content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/content-list/content-list.jade" ));
buf.push("\n<div class=\"spinner\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/content-list/content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/content-list/content-list.jade" ));
if (!( hasContent))
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/content-list/content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/content-list/content-list.jade" ));
buf.push("\n<div class=\"animated fadeIn\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/content-list/content-list.jade" ));
if ( isFiltered)
{
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/content-list/content-list.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/content-list/content-list.jade" ));
buf.push("\n  <h4>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("No content matches these filters.");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/content-list/content-list.jade" ));
buf.push("\n  <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/content-list/content-list.jade" ));
buf.push("<a data-action=\"clearFilterState\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Clear filters");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/content-list/content-list.jade" ));
buf.push("&nbsp;to see more content or&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/content-list/content-list.jade" ));
buf.push("<a data-action=\"createContentWithDomain\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("create something new");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/content-list/content-list.jade" ));
buf.push(".");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"hasContent" in locals_for_with?locals_for_with.hasContent:typeof hasContent!=="undefined"?hasContent:undefined,"isFiltered" in locals_for_with?locals_for_with.isFiltered:typeof isFiltered!=="undefined"?isFiltered:undefined,"loading" in locals_for_with?locals_for_with.loading:typeof loading!=="undefined"?loading:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "if loading\n    .spinner\nelse\n    unless hasContent\n        .animated.fadeIn\n            if isFiltered\n                h4 No content matches these filters.\n                p\n                    a(data-action=\"clearFilterState\") Clear filters\n                    | &nbsp;to see more content or&nbsp;\n                    a(data-action=\"createContentWithDomain\") create something new\n                    | .\n");
}
};