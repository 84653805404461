module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/hint-modal.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (content, title) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/modal.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/modal.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/modal.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/modal.jade" ));
buf.push("\n      <button type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\" class=\"close\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/hint-modal.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/modal.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/hint-modal.jade" ));
buf.push(null == (jade_interp = content) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/modal.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/hint-modal.jade" ));
buf.push("<a data-dismiss=\"modal\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("OK, I've got it!");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"content" in locals_for_with?locals_for_with.content:typeof content!=="undefined"?content:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends modal\n\nblock header\n    h4.modal-title #{title}\n\nblock body\n    != content\n\nblock footer\n    a(data-dismiss='modal') OK, I've got it!\n");
}
};