module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/components/social.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (facebookContent, facebookEnabled, twitterContent, twitterEnabled) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/components/social.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/components/social.jade" ));
buf.push("\n<div class=\"channel-settings appcues-style\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/components/social.jade" ));
buf.push("\n  <label class=\"checkbox pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/components/social.jade" ));
buf.push("\n    <input type=\"checkbox\" name=\"facebookEnabled\"" + (jade.attr("checked", facebookEnabled, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/components/social.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("Enabled");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/components/social.jade" ));
buf.push("\n  <h3>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Facebook");
jade_debug.shift();
jade_debug.shift();
buf.push("</h3>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/components/social.jade" ));
buf.push("\n  <textarea name=\"facebookContent\">" + (jade.escape(null == (jade_interp = facebookContent) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</textarea>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/components/social.jade" ));
buf.push("\n<hr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/components/social.jade" ));
buf.push("\n<div class=\"channel-settings appcues-style\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/components/social.jade" ));
buf.push("\n  <label class=\"checkbox pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/components/social.jade" ));
buf.push("\n    <input type=\"checkbox\" name=\"twitterEnabled\"" + (jade.attr("checked", twitterEnabled, true, true)) + ">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/components/social.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("Enabled");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/components/social.jade" ));
buf.push("\n  <h3>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Twitter");
jade_debug.shift();
jade_debug.shift();
buf.push("</h3>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/components/social.jade" ));
buf.push("\n  <textarea name=\"twitterContent\">" + (jade.escape(null == (jade_interp = twitterContent) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</textarea>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/components/social.jade" ));
buf.push("\n<div class=\"controls clearfix\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/components/social.jade" ));
buf.push("<a data-action=\"delete\" class=\"appcues-style btn btn-link btn-link-danger btn-sm\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 18, jade_debug[0].filename ));
buf.push("Delete");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/components/social.jade" ));
buf.push("<a data-action=\"saveAndClose\" class=\"appcues-style btn btn-primary btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Save");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/components/social.jade" ));
buf.push("<a data-action=\"close\" class=\"appcues-style btn btn-default btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, jade_debug[0].filename ));
buf.push("Cancel");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"facebookContent" in locals_for_with?locals_for_with.facebookContent:typeof facebookContent!=="undefined"?facebookContent:undefined,"facebookEnabled" in locals_for_with?locals_for_with.facebookEnabled:typeof facebookEnabled!=="undefined"?facebookEnabled:undefined,"twitterContent" in locals_for_with?locals_for_with.twitterContent:typeof twitterContent!=="undefined"?twitterContent:undefined,"twitterEnabled" in locals_for_with?locals_for_with.twitterEnabled:typeof twitterEnabled!=="undefined"?twitterEnabled:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".channel-settings.appcues-style\n    label.checkbox.pull-right\n        input(type='checkbox' name='facebookEnabled' checked=facebookEnabled)\n        span Enabled\n    h3 Facebook\n    textarea(name='facebookContent')= facebookContent\n\nhr\n\n.channel-settings.appcues-style\n    label.checkbox.pull-right\n        input(type='checkbox' name='twitterEnabled' checked=twitterEnabled)\n        span Enabled\n    h3 Twitter\n    textarea(name='twitterContent')= twitterContent\n\n.controls.clearfix\n    a.appcues-style.btn.btn-link.btn-link-danger.btn-sm(data-action='delete') Delete\n    a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save\n    a.appcues-style.btn.btn-default.btn-sm.pull-right(data-action='close') Cancel\n\n");
}
};