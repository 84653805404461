module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/form-fields/text.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (id, isEditable, label, placeholder, required) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/form-fields/text.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/form-fields/text.jade" ));
buf.push("\n<div class=\"field-label\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/form-fields/text.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields/text.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/form-fields/text.jade" ));
buf.push("\n  <input placeholder=\"Add label...\"" + (jade.attr("value", label, true, true)) + " class=\"label-value\">");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/form-fields/text.jade" ));
buf.push("\n  <div class=\"field-actions\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/form-fields/text.jade" ));
buf.push("<a data-hint=\"Drag and drop to reorder fields\" class=\"field-action field-drag-handle hint--left control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/form-fields/text.jade" ));
buf.push("<i class=\"fa fa-sort\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/form-fields/text.jade" ));
buf.push("\n    <div class=\"btn-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/form-fields/text.jade" ));
buf.push("<a data-hint=\"Field options\" role=\"button\" tabindex=\"-1\" class=\"field-action field-options hint--left control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/form-fields/text.jade" ));
buf.push("<i class=\"fa fa-cog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/form-fields/text.jade" ));
buf.push("<a data-action=\"removeField\" data-hint=\"Delete this field\" class=\"field-action hint--left control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/form-fields/text.jade" ));
buf.push("<i class=\"fa fa-trash-o\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/form-fields/text.jade" ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/form-fields/text.jade" ));
buf.push("\n  <label" + (jade.attr("for", id, true, true)) + " class=\"label-display\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/form-fields/text.jade" ));
buf.push("\n<div class=\"field-controls\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/form-fields/text.jade" ));
if ( isEditable)
{
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/form-fields/text.jade" ));
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/form-fields/text.jade" ));
buf.push("\n  <input type=\"text\"" + (jade.attr("value", placeholder, true, true)) + " placeholder=\"Click to add placeholder text\" class=\"placeholder-value\">");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/form-fields/text.jade" ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/form-fields/text.jade" ));
buf.push("\n  <input" + (jade.attr("name", id, true, true)) + (jade.attr("placeholder", placeholder, true, true)) + " type=\"text\"" + (jade.attr("required", required, true, true)) + " class=\"placeholder-value\">");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"isEditable" in locals_for_with?locals_for_with.isEditable:typeof isEditable!=="undefined"?isEditable:undefined,"label" in locals_for_with?locals_for_with.label:typeof label!=="undefined"?label:undefined,"placeholder" in locals_for_with?locals_for_with.placeholder:typeof placeholder!=="undefined"?placeholder:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".field-label\n    if isEditable\n        input.label-value(placeholder=\"Add label...\" value=label)\n        .field-actions\n            a.field-action.field-drag-handle.hint--left.control-link(data-hint=\"Drag and drop to reorder fields\")\n                i.fa.fa-sort\n            .btn-group\n                a.field-action.field-options.hint--left.control-link(data-hint=\"Field options\" role=\"button\" tabindex=\"-1\")\n                    i.fa.fa-cog\n            a.field-action.hint--left.control-link(data-action=\"removeField\" data-hint=\"Delete this field\")\n                i.fa.fa-trash-o\n    else\n        label.label-display(for=id) #{label}\n\n.field-controls\n    if isEditable\n        input.placeholder-value(type='text' value=placeholder placeholder=\"Click to add placeholder text\")\n    else\n        input.placeholder-value(name=id placeholder=placeholder type='text' required=required)\n");
}
};