module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/auth-modal.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/modal.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/modal.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/modal.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/modal.jade" ));
buf.push("\n      <button type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\" class=\"close\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/auth-modal.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("Claim your content by creating an account.");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/modal.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/auth-modal.jade" ));
buf.push("\n      <form role=\"form\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/auth-modal.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/auth-modal.jade" ));
buf.push("\n          <label for=\"email-address\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Email address");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/auth-modal.jade" ));
buf.push("\n          <input id=\"email-address\" name=\"email\" type=\"email\" placeholder=\"Enter email\" class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/auth-modal.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/auth-modal.jade" ));
buf.push("\n          <label for=\"password\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, jade_debug[0].filename ));
buf.push("Password");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/auth-modal.jade" ));
buf.push("\n          <input id=\"password\" name=\"password\" type=\"password\" placeholder=\"Password\" class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </form>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 41, "app/views/templates/modal.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/auth-modal.jade" ));
buf.push("\n      <button type=\"button\" class=\"btn btn-primary modal-save\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("Claim my content");
jade_debug.shift();
jade_debug.shift();
buf.push("</button>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends modal\n\nblock header\n    h4.modal-title Claim your content by creating an account.\n\nblock body\n    form(role='form')\n        .form-group\n            label(for='email-address') Email address\n            input#email-address.form-control(name='email', type='email', placeholder='Enter email')\n        .form-group\n            label(for='password') Password\n            input#password.form-control(name='password', type='password', placeholder='Password')\n\nblock footer\n    button.btn.btn-primary.modal-save(type='button') Claim my content\n");
}
};