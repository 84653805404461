module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/social-modal.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (shareText, shareUrl) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/social-modal.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/social-modal.jade" ));
buf.push("\n<form>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/social-modal.jade" ));
buf.push("\n  <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/social-modal.jade" ));
var val = shareUrl || ''
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/social-modal.jade" ));
buf.push("\n    <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("URL to share");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/social-modal.jade" ));
buf.push("\n    <input type=\"url\" name=\"shareUrl\" placeholder=\"http://www.appcues.com/\"" + (jade.attr("value", val, true, true)) + " class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/social-modal.jade" ));
buf.push("\n  <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/social-modal.jade" ));
var val = shareText || ''
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/social-modal.jade" ));
buf.push("\n    <label>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Short description (optional)");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/social-modal.jade" ));
buf.push("\n    <textarea type=\"text\" name=\"shareText\" rows=\"3\" placeholder=\"Wow, using this product makes me so happy. It's the bacon to my eggs.\" class=\"form-control\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = val) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</textarea>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</form>");
jade_debug.shift();
jade_debug.shift();}.call(this,"shareText" in locals_for_with?locals_for_with.shareText:typeof shareText!=="undefined"?shareText:undefined,"shareUrl" in locals_for_with?locals_for_with.shareUrl:typeof shareUrl!=="undefined"?shareUrl:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "form\n    .form-group\n        - var val = shareUrl || ''\n        label URL to share\n        input.form-control(type='url', name='shareUrl', placeholder='http://www.appcues.com/', value=val)\n\n    .form-group\n        - var val = shareText || ''\n        label Short description (optional)\n        textarea.form-control(type='text', name='shareText', rows='3', placeholder=\"Wow, using this product makes me so happy. It's the bacon to my eggs.\") #{val}\n");
}
};