module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/build-steps/settings/flow-prehook.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (model) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/build-steps/settings/flow-prehook.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/build-steps/settings/flow-prehook.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Javascript added here will be run once the flow is added to the document and before any steps are shown.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/build-steps/settings/flow-prehook.jade" ));
buf.push("\n<div class=\"prehook-editor\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/build-steps/settings/flow-prehook.jade" ));
buf.push(null == (jade_interp = model.prehook) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/build-steps/settings/flow-prehook.jade" ));
buf.push("\n<textarea name=\"prehook\" class=\"hidden\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/build-steps/settings/flow-prehook.jade" ));
buf.push(null == (jade_interp = model.prehook) ? "" : jade_interp);
jade_debug.shift();
jade_debug.shift();
buf.push("\n</textarea>");
jade_debug.shift();
jade_debug.shift();}.call(this,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "p Javascript added here will be run once the flow is added to the document and before any steps are shown.\ndiv.prehook-editor\n    != model.prehook\n\ntextarea.hidden(name='prehook')\n    != model.prehook\n");
}
};