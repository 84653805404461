module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/hint-video.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;

var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/hint-video.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/hint-video.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Creating a video can be daunting, but it doesn't have to be so hard. Here are a few things to help you succeed with video.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/hint-video.jade" ));
buf.push("\n<h4>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, jade_debug[0].filename ));
buf.push("Video creation tools:");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/hint-video.jade" ));
buf.push("\n<ul>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/hint-video.jade" ));
buf.push("\n  <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/hint-video.jade" ));
buf.push("\n    <h5>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/hint-video.jade" ));
buf.push("<a href=\"http://quickcast.io/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Quickcast");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/hint-video.jade" ));
buf.push("<span class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push(" - Free");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/hint-video.jade" ));
buf.push("<small>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push(" (Mac only)");
jade_debug.shift();
jade_debug.shift();
buf.push("</small>");
jade_debug.shift();
jade_debug.shift();
buf.push("</h5>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/hint-video.jade" ));
buf.push("\n    <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 11, jade_debug[0].filename ));
buf.push("Quickcast is a free, light-weight screencasting tool for Mac OS X. They even host the videos for you.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/hint-video.jade" ));
buf.push("\n  <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/hint-video.jade" ));
buf.push("\n    <h5>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/hint-video.jade" ));
buf.push("<a href=\"http://www.telestream.net/screenflow/overview.htm\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, jade_debug[0].filename ));
buf.push("ScreenFlow");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/hint-video.jade" ));
buf.push("<span class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push(" - $99");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</h5>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 17, "app/views/templates/hint-video.jade" ));
buf.push("\n    <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 17, jade_debug[0].filename ));
buf.push("ScreenFlow does both screen recording and editing.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</ul>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/hint-video.jade" ));
buf.push("\n<h4>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Video hosting tools:");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/hint-video.jade" ));
buf.push("\n<ul>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/hint-video.jade" ));
buf.push("\n  <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/hint-video.jade" ));
buf.push("\n    <h5>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/hint-video.jade" ));
buf.push("<a href=\"http://wistia.com/\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, jade_debug[0].filename ));
buf.push("Wistia");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/hint-video.jade" ));
buf.push("<span class=\"text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push(" - Free");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</h5>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/hint-video.jade" ));
buf.push("\n    <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, jade_debug[0].filename ));
buf.push("Wistia specializes in business video hosting. They're free and have a ton of great features.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</ul>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/hint-video.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, jade_debug[0].filename ));
buf.push("When creating your video, be comfortable. Create a script beforehand and read it aloud several times. When doing a screencast, record the audio first, then listen to it while you record your screen. Don't do both things at once.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/hint-video.jade" ));
buf.push("\n<p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/hint-video.jade" ));
buf.push("For more on-camera tips, check out Wistia's free&nbsp;");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/hint-video.jade" ));
buf.push("<a href=\"http://wistia.com/learning\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, jade_debug[0].filename ));
buf.push("Learning Center");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/hint-video.jade" ));
buf.push(".");
jade_debug.shift();
buf.push("\n  ");
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/hint-video.jade" ));
buf.push(" Those guys rock.");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</p>");
jade_debug.shift();
jade_debug.shift();;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "p Creating a video can be daunting, but it doesn't have to be so hard. Here are a few things to help you succeed with video.\n\nh4 Video creation tools:\n\nul\n    li\n        h5\n            a(href='http://quickcast.io/', target='_blank') Quickcast\n            span.text-muted  - Free\n            small  (Mac only)\n        p Quickcast is a free, light-weight screencasting tool for Mac OS X. They even host the videos for you.\n\n    li\n        h5\n            a(href='http://www.telestream.net/screenflow/overview.htm', target='_blank') ScreenFlow\n            span.text-muted  - $99\n        p ScreenFlow does both screen recording and editing.\n\nh4 Video hosting tools:\n\nul\n    li\n        h5\n            a(href='http://wistia.com/', target='_blank') Wistia\n            span.text-muted  - Free\n        p Wistia specializes in business video hosting. They're free and have a ton of great features.\n\np When creating your video, be comfortable. Create a script beforehand and read it aloud several times. When doing a screencast, record the audio first, then listen to it while you record your screen. Don't do both things at once.\n\np\n    | For more on-camera tips, check out Wistia's free&nbsp;\n    a(href='http://wistia.com/learning', target='_blank') Learning Center\n    | .\n    |  Those guys rock.\n");
}
};