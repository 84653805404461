module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/content-list/category-areas.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (categories, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/content-list/category-areas.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/content-list/category-areas.jade" ));
// iterate categories
;(function(){
  var $$obj = categories;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var category = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/content-list/category-areas.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/content-list/category-areas.jade" ));
buf.push("\n<div" + (jade.attr("data-category", category.id, true, true)) + (jade.attr("data-label", ("Move to " + category.name), true, true)) + (jade.attr("data-empty-label", category.name, true, true)) + " class=\"category-area content-table\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var category = $$obj[$index];

jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/content-list/category-areas.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/content-list/category-areas.jade" ));
buf.push("\n<div" + (jade.attr("data-category", category.id, true, true)) + (jade.attr("data-label", ("Move to " + category.name), true, true)) + (jade.attr("data-empty-label", category.name, true, true)) + " class=\"category-area content-table\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
    }

  }
}).call(this);

jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/content-list/category-areas.jade" ));
buf.push("\n<div data-label=\"Move to Uncategorized\" data-empty-label=\"Uncategorized\" class=\"category-area content-table\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"categories" in locals_for_with?locals_for_with.categories:typeof categories!=="undefined"?categories:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "for category in categories\n    .category-area.content-table(data-category=category.id data-label=(\"Move to \" + category.name) data-empty-label=category.name)\n.category-area.content-table(data-category=null data-label='Move to Uncategorized' data-empty-label='Uncategorized')\n");
}
};