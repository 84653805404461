module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/components/editor.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (html) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/components/editor.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/components/editor.jade" ));
buf.push("\n<div class=\"content-editor\">" + (null == (jade_interp = html) ? "" : jade_interp));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/components/editor.jade" ));
buf.push("\n<div class=\"controls clearfix\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/components/editor.jade" ));
buf.push("<a data-action=\"delete\" class=\"appcues-style btn btn-link btn-sm control-link\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("<i class=\"fa fa-trash\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</i>");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/components/editor.jade" ));
buf.push("\n  <div class=\"warning-message\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push(" ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/components/editor.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, jade_debug[0].filename ));
buf.push("We found some invalid HTML. Please check and try again.");
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/components/editor.jade" ));
buf.push("<a data-action=\"saveAndClose\" class=\"appcues-style btn btn-primary btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, jade_debug[0].filename ));
buf.push("Save");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/components/editor.jade" ));
buf.push("<a data-action=\"close\" class=\"appcues-style btn btn-default btn-sm pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Cancel");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"html" in locals_for_with?locals_for_with.html:typeof html!=="undefined"?html:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "div.content-editor!= html\n\ndiv.controls.clearfix\n    a.appcues-style.btn.btn-link.btn-sm.control-link(data-action='delete'): i.fa.fa-trash\n    div.warning-message \n        span We found some invalid HTML. Please check and try again.\n    a.appcues-style.btn.btn-primary.btn-sm.pull-right(data-action='saveAndClose') Save\n    a.appcues-style.btn.btn-default.btn-sm.pull-right(data-action='close') Cancel\n");
}
};