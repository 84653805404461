module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/preview-header.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (account) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/preview-header.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/preview-header.jade" ));
if ( account)
{
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/preview-header.jade" ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/preview-header.jade" ));
if ( account.logoUrl)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/preview-header.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/preview-header.jade" ));
buf.push("<img" + (jade.attr("src", account.logoUrl, true, true)) + " height=\"35\">");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/preview-header.jade" ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/preview-header.jade" ));
buf.push("<a class=\"btn dashed add-logo\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Upload your logo");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();}.call(this,"account" in locals_for_with?locals_for_with.account:typeof account!=="undefined"?account:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "if account\n    if account.logoUrl\n        img(src=account.logoUrl, height='35')\nelse\n    a.btn.dashed.add-logo Upload your logo\n");
}
};