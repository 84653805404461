module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/new-bookmark.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (attrs) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/new-bookmark.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/new-bookmark.jade" ));
buf.push("\n<div class=\"modal-dialog\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/new-bookmark.jade" ));
buf.push("\n  <div class=\"modal-content\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/new-bookmark.jade" ));
buf.push("\n    <div class=\"modal-header\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/new-bookmark.jade" ));
buf.push("<a data-dismiss=\"modal\" class=\"close\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, jade_debug[0].filename ));
buf.push("&times;");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/new-bookmark.jade" ));
buf.push("\n      <h4 class=\"modal-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Create a new bookmark");
jade_debug.shift();
jade_debug.shift();
buf.push("</h4>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/new-bookmark.jade" ));
buf.push("\n    <div class=\"modal-body\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/new-bookmark.jade" ));
buf.push("\n      <p class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Save this URL target as a bookmark that can be reused for targeting other content.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/new-bookmark.jade" ));
buf.push("\n      <hr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/new-bookmark.jade" ));
buf.push("\n      <form class=\"form-horizontal\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/new-bookmark.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/new-bookmark.jade" ));
buf.push("\n          <label class=\"control-label col-sm-2\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, jade_debug[0].filename ));
buf.push("Name");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/new-bookmark.jade" ));
buf.push("\n          <div class=\"col-sm-10\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/new-bookmark.jade" ));
buf.push("\n            <input name=\"name\" placeholder=\"e.g. &quot;Account page&quot; or &quot;Dashboard&quot;\" class=\"form-control\">");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/new-bookmark.jade" ));
buf.push("\n        <div class=\"form-group\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/new-bookmark.jade" ));
buf.push("\n          <label class=\"control-label col-sm-2\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 19, jade_debug[0].filename ));
buf.push("Target");
jade_debug.shift();
jade_debug.shift();
buf.push("</label>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/new-bookmark.jade" ));
buf.push("\n          <div class=\"col-sm-10\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/new-bookmark.jade" ));
buf.push("\n            <div class=\"form-control-static\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/new-bookmark.jade" ));
if ( attrs.isRegex)
{
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/new-bookmark.jade" ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/new-bookmark.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, jade_debug[0].filename ));
buf.push("Pages that match ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/new-bookmark.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/new-bookmark.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = attrs.where) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 23, jade_debug[0].filename ));
buf.push(" as a regular expression");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/new-bookmark.jade" ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/new-bookmark.jade" ));
buf.push("<span>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push("Pages that match ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/new-bookmark.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/new-bookmark.jade" ));
buf.push("<strong>" + (jade.escape(null == (jade_interp = attrs.where) ? "" : jade_interp)));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push(" exactly");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</span>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </form>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/new-bookmark.jade" ));
buf.push("\n    <div class=\"modal-footer\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/new-bookmark.jade" ));
buf.push("<a data-dismiss=\"modal\" class=\"btn btn-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, jade_debug[0].filename ));
buf.push("Cancel");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/new-bookmark.jade" ));
buf.push("<a data-action=\"save\" class=\"btn btn-primary\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("Save");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"attrs" in locals_for_with?locals_for_with.attrs:typeof attrs!=="undefined"?attrs:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".modal-dialog\n    .modal-content\n        .modal-header\n            a.close(data-dismiss=\"modal\") &times;\n            h4.modal-title Create a new bookmark\n\n        .modal-body\n            p.text-center Save this URL target as a bookmark that can be reused for targeting other content.\n\n            hr\n\n            form.form-horizontal\n                .form-group\n                    label.control-label.col-sm-2 Name\n                    .col-sm-10\n                        input.form-control(name='name' placeholder='e.g. \"Account page\" or \"Dashboard\"')\n\n                .form-group\n                    label.control-label.col-sm-2 Target\n                    .col-sm-10\n                        .form-control-static\n                            if attrs.isRegex\n                                span Pages that match #[strong= attrs.where] as a regular expression\n                            else\n                                span Pages that match #[strong= attrs.where] exactly\n\n        .modal-footer\n            a.btn.btn-default(data-dismiss=\"modal\") Cancel\n            a.btn.btn-primary(data-action=\"save\") Save\n\n");
}
};