module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/install-guide-spa.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (accountId, hasAuth, hideTitle, isInstalled, undefined) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("\n<div class=\"container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/install-guide.jade" ));
if ( !hideTitle)
{
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/install-guide.jade" ));
if ( hasAuth && !isInstalled)
{
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/install-guide.jade" ));
buf.push("\n  <div class=\"notice alert-warning text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/install-guide.jade" ));
buf.push("\n    <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/install-guide.jade" ));
buf.push("<b>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 6, jade_debug[0].filename ));
buf.push("Looks like you're not installed!");
jade_debug.shift();
jade_debug.shift();
buf.push("</b>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/install-guide.jade" ));
buf.push("&nbsp;Before you can view your flows live on your site, you'll need to install Appcues on your site.&nbsp;");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/install-guide.jade" ));
buf.push("\n  <div class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/install-guide.jade" ));
buf.push("\n    <h2 style=\"padding-bottom: 10px;\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Installation Guide");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/install-guide.jade" ));
buf.push("\n    <p style=\"padding-bottom: 10px;\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, jade_debug[0].filename ));
buf.push("Installing Appcues in your app is quick, commitment-free and can be removed at any time.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/install-guide.jade" ));
buf.push("\n  <div class=\"panel panel-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 13, "app/views/templates/install-guide.jade" ));
buf.push("\n    <div data-toggle=\"collapse\" data-target=\"#installChoice\" aria-expanded=\"false\" aria-controls=\"installChoice\" class=\"panel-heading panel-choose-install panel-heading-active panel-heading-instructions\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 14, "app/views/templates/install-guide.jade" ));
buf.push("\n      <h3 class=\"panel-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 15, "app/views/templates/install-guide.jade" ));
if ( this.installType)
{
jade_debug.unshift(new jade.DebugItem( 16, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( undefined, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push("1. Install method: ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<strong class=\"text-success\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("" + (jade.escape((jade_interp = this.installType) == null ? '' : jade_interp)) + "");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 16, jade_debug[0].filename ));
buf.push(" (Click to change)");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 18, "app/views/templates/install-guide.jade" ));
buf.push("1. Choose an install option");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </h3>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 19, "app/views/templates/install-guide.jade" ));
buf.push("\n    <div id=\"installChoice\" class=\"panel-body collapse in\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 20, "app/views/templates/install-guide.jade" ));
buf.push("\n      <div class=\"text-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 21, "app/views/templates/install-guide.jade" ));
buf.push("\n        <table class=\"table-padded\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 22, "app/views/templates/install-guide.jade" ));
buf.push("\n          <tbody>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 23, "app/views/templates/install-guide.jade" ));
buf.push("\n            <tr>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 24, "app/views/templates/install-guide.jade" ));
buf.push("\n              <td style=\"width:50%;\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, "app/views/templates/install-guide.jade" ));
buf.push("<a" + (jade.attr("href", '/install/traditional' + (accountId ? "/" + accountId : "") + '', true, true)) + " class=\"btn btn-outline btn-outline-primary btn-block choose-install-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 25, jade_debug[0].filename ));
buf.push("Basic Javascript");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 26, "app/views/templates/install-guide.jade" ));
buf.push("\n              <td>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 26, jade_debug[0].filename ));
buf.push("Our traditional way of installing");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </tr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 27, "app/views/templates/install-guide.jade" ));
buf.push("\n            <tr>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 28, "app/views/templates/install-guide.jade" ));
buf.push("\n              <td>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, "app/views/templates/install-guide.jade" ));
buf.push("<a" + (jade.attr("href", '/install/spa' + (accountId ? "/" + accountId : "") + '', true, true)) + " class=\"btn btn-outline btn-outline-primary btn-block choose-install-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 29, jade_debug[0].filename ));
buf.push("Single-page web app");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 30, "app/views/templates/install-guide.jade" ));
buf.push("\n              <td>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 30, jade_debug[0].filename ));
buf.push("Installing with a front-end framework such as Angular, Ember, or React");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </tr>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 31, "app/views/templates/install-guide.jade" ));
buf.push("\n            <tr>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 32, "app/views/templates/install-guide.jade" ));
buf.push("\n              <td>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, "app/views/templates/install-guide.jade" ));
buf.push("<a" + (jade.attr("href", '/install/segment' + (accountId ? "/" + accountId : "") + '', true, true)) + " class=\"btn btn-outline btn-outline-primary btn-block choose-install-type\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 33, jade_debug[0].filename ));
buf.push("Segment");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 34, "app/views/templates/install-guide.jade" ));
buf.push("\n              <td>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 34, jade_debug[0].filename ));
buf.push("One-click installation with Segment");
jade_debug.shift();
jade_debug.shift();
buf.push("</td>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n            </tr>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </tbody>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </table>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 35, "app/views/templates/install-guide.jade" ));
buf.push("\n      <div class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 36, "app/views/templates/install-guide.jade" ));
buf.push("\n        <p style=\"margin-bottom: 10px;\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 36, jade_debug[0].filename ));
buf.push("Can't find what you're looking for? Check out our ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<a href=\"http://docs.appcues.com/category/12-installation\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("installation docs");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 36, jade_debug[0].filename ));
buf.push(".");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 37, "app/views/templates/install-guide.jade" ));
buf.push("\n  <div class=\"panel panel-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n    <div data-toggle=\"collapse\" data-target=\"#instructionsBody\" aria-expanded=\"false\" aria-controls=\"instructionsBody\" class=\"panel-heading panel-heading-active panel-heading-instructions\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n      <h3 class=\"panel-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/install-guide-spa.jade" ));
buf.push("2. Installing in a single-page web app");
jade_debug.shift();
jade_debug.shift();
buf.push("</h3>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n    <div id=\"instructionsBody\" class=\"panel-body collapse in\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n      <div class=\"text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("Choose your framework:");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n      <div class=\"text-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 10, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n        <div class=\"code-snippet-ig\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.shift();
buf.push("</div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 11, "app/views/templates/install-guide-spa.jade" ));
buf.push("<br>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 12, "app/views/templates/install-guide-spa.jade" ));
buf.push("\n      <p id=\"next\" class=\"btn btn-success pull-right\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 12, jade_debug[0].filename ));
buf.push("Okay got it!");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 44, "app/views/templates/install-guide.jade" ));
buf.push("\n  <div id=\"verify\" class=\"panel panel-default\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 45, "app/views/templates/install-guide.jade" ));
buf.push("\n    <div data-toggle=\"collapse\" data-target=\"#verifyInstall\" aria-expanded=\"false\" aria-controls=\"#verifyInstall\" class=\"panel-heading panel-heading-verify\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 46, "app/views/templates/install-guide.jade" ));
buf.push("\n      <h3 class=\"panel-title\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 47, "app/views/templates/install-guide.jade" ));
buf.push("3. Verify your installation");
jade_debug.shift();
jade_debug.shift();
buf.push("</h3>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 48, "app/views/templates/install-guide.jade" ));
buf.push("\n    <div id=\"verifyInstall\" class=\"panel-body collapse in text-center\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 49, "app/views/templates/install-guide.jade" ));
if ( hasAuth)
{
jade_debug.unshift(new jade.DebugItem( 50, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 50, "app/views/templates/install-guide.jade" ));
if ( isInstalled)
{
jade_debug.unshift(new jade.DebugItem( 51, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 51, "app/views/templates/install-guide.jade" ));
buf.push("\n      <div style=\"margin-bottom: 15px; margin-top: 0;\" class=\"notice alert-success\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 52, "app/views/templates/install-guide.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 53, "app/views/templates/install-guide.jade" ));
buf.push("<b>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 53, jade_debug[0].filename ));
buf.push("Great! We can detect Appcues on your app.");
jade_debug.shift();
jade_debug.shift();
buf.push("</b>");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
}
else
{
jade_debug.unshift(new jade.DebugItem( 56, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 56, "app/views/templates/install-guide.jade" ));
buf.push("\n      <div style=\"margin-bottom: 15px; margin-top: 0;\" class=\"notice alert-danger\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 57, "app/views/templates/install-guide.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 58, "app/views/templates/install-guide.jade" ));
buf.push("<b>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 58, jade_debug[0].filename ));
buf.push("Looks like you're not installed!");
jade_debug.shift();
jade_debug.shift();
buf.push("</b>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( undefined, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 59, jade_debug[0].filename ));
buf.push("&nbsp; If your need help with installation, feel free to ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<a href=\"mailto:support@appcues.com?subject=Can you help me get installed?\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("reach out");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 59, jade_debug[0].filename ));
buf.push(" to us.&nbsp;");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.shift();
}
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 60, "app/views/templates/install-guide.jade" ));
buf.push("\n      <div class=\"div text-left\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 61, "app/views/templates/install-guide.jade" ));
buf.push("\n        <ul>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 62, "app/views/templates/install-guide.jade" ));
buf.push("\n          <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 63, "app/views/templates/install-guide.jade" ));
buf.push("\n            <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 63, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Review");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 63, jade_debug[0].filename ));
buf.push(" your installation by appending ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<code>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("?hey_appcues");
jade_debug.shift();
jade_debug.shift();
buf.push("</code>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 63, jade_debug[0].filename ));
buf.push(" to the end of any page that has your script installed.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 64, "app/views/templates/install-guide.jade" ));
buf.push("\n          <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 65, "app/views/templates/install-guide.jade" ));
buf.push("\n            <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 65, jade_debug[0].filename ));
buf.push("You should see a panel on the bottom left with information about your installation status. ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Click around your site");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 65, jade_debug[0].filename ));
buf.push(" to verify Appcues is picking up your navigation and user properties correctly.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 66, "app/views/templates/install-guide.jade" ));
buf.push("\n          <li>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 67, "app/views/templates/install-guide.jade" ));
buf.push("\n            <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 67, jade_debug[0].filename ));
buf.push("");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<strong>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Test");
jade_debug.shift();
jade_debug.shift();
buf.push("</strong>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 67, jade_debug[0].filename ));
buf.push(" your data by publishing a sample flow, targeted to just you. Check for ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<a href=\"http://docs.appcues.com/article/47-how-to-send-user-properties-to-appcues\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("user properties");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 67, jade_debug[0].filename ));
buf.push(" and ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<a href=\"http://docs.appcues.com/article/156-event-targeting\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("custom events");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 67, jade_debug[0].filename ));
buf.push(" that you are sending with the Appcues install.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n          </li>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n        </ul>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 68, "app/views/templates/install-guide.jade" ));
buf.push("\n        <p style=\"margin-bottom:0;\" class=\"alert alert-warning\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 69, jade_debug[0].filename ));
buf.push("You can find more information in our ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<a href=\"http://docs.appcues.com/article/34-debugging-your-appcues-installation\" target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("install verification guide");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 69, jade_debug[0].filename ));
buf.push(".");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( undefined, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 70, jade_debug[0].filename ));
buf.push("&nbsp; Questions about your install? ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<a href=\"mailto:support@appcues.com?subject=Can you check my installation?\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Contact us");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 70, jade_debug[0].filename ));
buf.push(".");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 72, "app/views/templates/install-guide.jade" ));
buf.push("\n  <p style=\"margin-top: 25px;margin-bottom: 25px;\" class=\"text-center text-muted\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 72, jade_debug[0].filename ));
buf.push("We are here to help! ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/install-guide.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/install-guide.jade" ));
buf.push("<a href=\"mailto:support@appcues.com?subject=I want help with installation\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("Send us an email");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 72, jade_debug[0].filename ));
buf.push(" with any questions.");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"accountId" in locals_for_with?locals_for_with.accountId:typeof accountId!=="undefined"?accountId:undefined,"hasAuth" in locals_for_with?locals_for_with.hasAuth:typeof hasAuth!=="undefined"?hasAuth:undefined,"hideTitle" in locals_for_with?locals_for_with.hideTitle:typeof hideTitle!=="undefined"?hideTitle:undefined,"isInstalled" in locals_for_with?locals_for_with.isInstalled:typeof isInstalled!=="undefined"?isInstalled:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, "extends install-guide\nblock stepContent\n    .panel-heading.panel-heading-active.panel-heading-instructions(data-toggle='collapse', data-target='#instructionsBody', aria-expanded='false', aria-controls='instructionsBody')\n        h3.panel-title\n            | 2. Installing in a single-page web app\n    .panel-body#instructionsBody.collapse.in\n        .text-center\n            p Choose your framework:\n        .text-left\n            .code-snippet-ig\n        br\n        p.btn.btn-success.pull-right#next Okay got it!\n");
}
};