module.exports = function template(locals) {
var jade_debug = [ new jade.DebugItem( 1, "app/views/templates/preview-frame-install-extension.jade" ) ];
try {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (link) {
var jade_indent = [];
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/preview-frame-install-extension.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n<div class=\"appcues-preview-frame-message-container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 2, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n  <div class=\"appcues-preview-frame-message container\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 3, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 4, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n      <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n        <h2>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 5, jade_debug[0].filename ));
buf.push("Install the Appcues Chrome Extension");
jade_debug.shift();
jade_debug.shift();
buf.push("</h2>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 6, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n    <div class=\"row\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 7, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n      <div class=\"col-md-12\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("\n        <p>");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 8, jade_debug[0].filename ));
buf.push("It looks like our normal preview frame wasn't working for you. This alternate method requires that you be using the Chrome/Chromium web browser and have installed the Appcues chrome extension.");
jade_debug.shift();
jade_debug.shift();
buf.push("</p>");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( undefined, "app/views/templates/preview-frame-install-extension.jade" ));
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push("Use ");
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 0, "app/views/templates/preview-frame-install-extension.jade" ));
jade_debug.unshift(new jade.DebugItem( 1, "app/views/templates/preview-frame-install-extension.jade" ));
buf.push("<a" + (jade.attr("href", "" + (link) + "", true, true)) + " target=\"_blank\">");
jade_debug.unshift(new jade.DebugItem( undefined, jade_debug[0].filename ));
jade_debug.unshift(new jade.DebugItem( 1, jade_debug[0].filename ));
buf.push("this link");
jade_debug.shift();
jade_debug.shift();
buf.push("</a>");
jade_debug.shift();
jade_debug.shift();
jade_debug.unshift(new jade.DebugItem( 9, jade_debug[0].filename ));
buf.push(" to install the extension in your browser and then reload this page to get started!");
jade_debug.shift();
jade_debug.shift();
jade_debug.shift();
buf.push("\n      </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n    </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n  </div>");
jade_debug.shift();
jade_debug.shift();
buf.push("\n</div>");
jade_debug.shift();
jade_debug.shift();}.call(this,"link" in locals_for_with?locals_for_with.link:typeof link!=="undefined"?link:undefined));;return buf.join("");
} catch (err) {
  jade.rethrow(err, jade_debug[0].filename, jade_debug[0].lineno, ".appcues-preview-frame-message-container\n    .appcues-preview-frame-message.container\n        .row\n            .col-md-12\n                h2 Install the Appcues Chrome Extension\n        .row\n            .col-md-12\n                p It looks like our normal preview frame wasn't working for you. This alternate method requires that you be using the Chrome/Chromium web browser and have installed the Appcues chrome extension.\n                | Use #[a(href=\"#{link}\", target=\"_blank\") this link] to install the extension in your browser and then reload this page to get started!\n");
}
};